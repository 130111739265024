define("shared/components/select-cards/vertical", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cp-select-cards-vertical flex flex-row md:flex-col bg-bp-secondary-main {{if @description 'p-2' 'p-4'}} border hover:border-black transition duration-200 rounded-md shadow-bp-md hover:shadow-bp-lg items-center cursor-pointer md:w-50 lg:w-50 lg:h-70 h-29 md:h-70 {{if @selected 'border-2 border-bp-primary-main hover:border-bp-primary-main' 'border-transparent'}}"
       data-testing-id={{@dataTestingId}} role="button" {{on 'click' @clickAction}}>
    <img src={{@imageSrc}} alt={{@imageAlt}} class="h-25 w-25 md:h-42 md:w-42 lg:h-32 lg:w-32 lg:mt-8">
    <div class="flex flex-row md:flex-col items-center md:mt-auto w-full">
      <div class="flex flex-col w-full">
        <span class="text-bp-text-icons {{if @description 'md:h-full lg:h-full' 'md:h-12 lg:h-12'}} font-bold md:text-center lg:text-center">{{@title}}</span>
        {{#if @description}}
          <span class="text-bp-text-icons-subdued text-sm md:text-center">{{@description}}</span>
        {{/if}}
      </div>
      <i class="icon-check-circle m-2 text-sm text-bp-primary-main font-bold transition duration-200 {{if @selected 'opacity-1' 'opacity-0'}}"></i>
    </div>
  </div>
  
  */
  {
    "id": "9111QZao",
    "block": "[[[11,0],[16,0,[29,[\"cp-select-cards-vertical flex flex-row md:flex-col bg-bp-secondary-main \",[52,[30,1],\"p-2\",\"p-4\"],\" border hover:border-black transition duration-200 rounded-md shadow-bp-md hover:shadow-bp-lg items-center cursor-pointer md:w-50 lg:w-50 lg:h-70 h-29 md:h-70 \",[52,[30,2],\"border-2 border-bp-primary-main hover:border-bp-primary-main\",\"border-transparent\"]]]],[16,\"data-testing-id\",[30,3]],[24,\"role\",\"button\"],[4,[38,1],[\"click\",[30,4]],null],[12],[1,\"\\n  \"],[10,\"img\"],[15,\"src\",[30,5]],[15,\"alt\",[30,6]],[14,0,\"h-25 w-25 md:h-42 md:w-42 lg:h-32 lg:w-32 lg:mt-8\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-row md:flex-col items-center md:mt-auto w-full\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-col w-full\"],[12],[1,\"\\n      \"],[10,1],[15,0,[29,[\"text-bp-text-icons \",[52,[30,1],\"md:h-full lg:h-full\",\"md:h-12 lg:h-12\"],\" font-bold md:text-center lg:text-center\"]]],[12],[1,[30,7]],[13],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[10,1],[14,0,\"text-bp-text-icons-subdued text-sm md:text-center\"],[12],[1,[30,1]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,\"i\"],[15,0,[29,[\"icon-check-circle m-2 text-sm text-bp-primary-main font-bold transition duration-200 \",[52,[30,2],\"opacity-1\",\"opacity-0\"]]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@description\",\"@selected\",\"@dataTestingId\",\"@clickAction\",\"@imageSrc\",\"@imageAlt\",\"@title\"],false,[\"if\",\"on\"]]",
    "moduleName": "shared/components/select-cards/vertical.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});