define("shared/components/business-guide-shared/scratch-off-list/scratch-off-list", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex flex-col w-full md:w-136 bg-bp-secondary-main rounded border border-bp-base-secondary mb-4 mx-auto">
    <div class="flex gap-2 items-center border-b-2 border-bp-surface-hovered pt-4 pb-2 px-4.5">
      <i class="{{concat 'icon-' @icon}} text-bp-text-icons-action text-sm"></i>
      <div class="font-bold">
        {{@title}}
      </div>
    </div>
    {{#each this.restOfList as |item|}}
      <BusinessGuideShared::ScratchOffItem @item={{item}}
                                           @checkedProducts={{@checkedProducts}}
                                           @isNonOfTheAboveChecked={{@isNonOfTheAboveChecked}}
                                           @onCheck={{@onCheck}}/>
    {{/each}}
    <BusinessGuideShared::ScratchOffItem @item={{this.lastItem}}
                                         @checkedProducts={{@checkedProducts}}
                                         @isNonOfTheAboveChecked={{@isNonOfTheAboveChecked}}
                                         @onCheck={{@onCheck}}
                                         @isLast={{true}}/>
  </div>
  
  */
  {
    "id": "Z8zMwAqW",
    "block": "[[[10,0],[14,0,\"flex flex-col w-full md:w-136 bg-bp-secondary-main rounded border border-bp-base-secondary mb-4 mx-auto\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex gap-2 items-center border-b-2 border-bp-surface-hovered pt-4 pb-2 px-4.5\"],[12],[1,\"\\n    \"],[10,\"i\"],[15,0,[29,[[28,[37,0],[\"icon-\",[30,1]],null],\" text-bp-text-icons-action text-sm\"]]],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"font-bold\"],[12],[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"restOfList\"]]],null]],null],null,[[[1,\"    \"],[8,[39,3],null,[[\"@item\",\"@checkedProducts\",\"@isNonOfTheAboveChecked\",\"@onCheck\"],[[30,3],[30,4],[30,5],[30,6]]],null],[1,\"\\n\"]],[3]],null],[1,\"  \"],[8,[39,3],null,[[\"@item\",\"@checkedProducts\",\"@isNonOfTheAboveChecked\",\"@onCheck\",\"@isLast\"],[[30,0,[\"lastItem\"]],[30,4],[30,5],[30,6],true]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@icon\",\"@title\",\"item\",\"@checkedProducts\",\"@isNonOfTheAboveChecked\",\"@onCheck\"],false,[\"concat\",\"each\",\"-track-array\",\"business-guide-shared/scratch-off-item\"]]",
    "moduleName": "shared/components/business-guide-shared/scratch-off-list/scratch-off-list.hbs",
    "isStrictMode": false
  });
  class BusinessGuideScratchOffListComponent extends _component2.default {
    get lastItem() {
      return this.args.list.slice(-1)[0];
    }
    get restOfList() {
      return this.args.list.slice(0, -1);
    }
  }
  _exports.default = BusinessGuideScratchOffListComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BusinessGuideScratchOffListComponent);
});