define("shared/components/info-cards/medium", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cp-info-cards-medium bg-bp-surface-main flex p-4 mr-3 mb-4 rounded-md shadow-bp-md w-full md:max-w-124 lg:w-86 lg:relative">
    <img src={{@imageSrc}} alt={{@title}} class="w-16 mr-4 lg:mr-0 lg:absolute lg:right-0 lg:-top-8">
    <div class="flex flex-col justify-center">
      <span class="text-bp-text-icons font-bold">
        {{@title}}
      </span>
      <span class="text-bp-text-icons-subdued text-sm w-full">
        {{@description}}
      </span>
    </div>
  </div>
  
  */
  {
    "id": "XIiqQ2oU",
    "block": "[[[10,0],[14,0,\"cp-info-cards-medium bg-bp-surface-main flex p-4 mr-3 mb-4 rounded-md shadow-bp-md w-full md:max-w-124 lg:w-86 lg:relative\"],[12],[1,\"\\n  \"],[10,\"img\"],[15,\"src\",[30,1]],[15,\"alt\",[30,2]],[14,0,\"w-16 mr-4 lg:mr-0 lg:absolute lg:right-0 lg:-top-8\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-col justify-center\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"text-bp-text-icons font-bold\"],[12],[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"text-bp-text-icons-subdued text-sm w-full\"],[12],[1,\"\\n      \"],[1,[30,3]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@imageSrc\",\"@title\",\"@description\"],false,[]]",
    "moduleName": "shared/components/info-cards/medium.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});