define("shared/llc/naics-code-for-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    238210: 'Electrical Contractors and Other Wiring Installation Contractors',
    323111: 'Commercial Printing (except Screen and Books)',
    323117: 'Books Printing',
    323120: 'Support Activities for Printing',
    445132: 'Vending Machine Operators',
    459920: 'Art Dealers',
    512110: 'Motion Picture and Video Production',
    512191: 'Teleproduction and Other Postproduction Services',
    512199: 'Other Motion Picture and Video Industries',
    512230: 'Music Publishers',
    512240: 'Sound Recording Studios',
    512250: 'Record Production and Distribution',
    512290: 'Other Sound Recording Industries',
    513210: 'Software Publishers',
    517121: 'Telecommunications Resellers',
    517122: 'Wireless Telecommunications Carriers (except Satellite)',
    522310: 'Mortgage and Nonmortgage Loan Brokers',
    522390: 'Other Activities Related to Credit Intermediation',
    523940: 'Investment Advice',
    524210: 'Insurance Agencies and Brokerages',
    524291: 'Claims Adjusting',
    531210: 'Offices of Real Estate Agents and Brokers',
    531320: 'Offices of Real Estate Appraisers',
    531390: 'Other Activities Related to Real Estate',
    541110: 'Offices of Lawyers',
    541120: 'Offices of Notaries',
    541191: 'Title Abstract and Settlement Offices',
    541199: 'All Other Legal Services',
    541211: 'Offices of Certified Public Accountants',
    541213: 'Tax Preparation Services',
    541219: 'Other Accounting Services',
    541350: 'Building Inspection Services',
    541430: 'Graphic Design Services',
    541490: 'Other Specialized Design Services',
    541511: 'Custom Computer Programming Services',
    541512: 'Computer Systems Design Services',
    541513: 'Computer Facilities Management Services',
    541519: 'Other Computer Related Services',
    541611: 'Administrative Management and General Management Consulting Services',
    541613: 'Marketing Consulting Services',
    541618: 'Other Management Consulting Services',
    541690: 'Other Scientific and Technical Consulting Services',
    541910: 'Marketing Research and Public Opinion Polling',
    541921: 'Photography Studios, Portrait',
    541922: 'Commercial Photography',
    541930: 'Translation and Interpretation Services',
    561311: 'Employment Placement Agencies',
    561320: 'Temporary Help Services',
    561330: 'Professional Employer Organizations',
    561410: 'Document Preparation Services',
    561439: 'Other Business Service Centers (including Copy Shops)',
    561440: 'Collection Agencies',
    561499: 'All Other Business Support Services',
    561510: 'Travel Agencies',
    561599: 'All Other Travel Arrangement and Reservation Services',
    561611: 'Investigation Services',
    561622: 'Locksmiths',
    561910: 'Packaging and Labeling Services',
    561920: 'Convention and Trade Show Organizers',
    611410: 'Business and Secretarial Schools',
    611420: 'Computer Training',
    611430: 'Professional and Management Development Training',
    611511: 'Cosmetology and Barber Schools',
    611691: 'Exam Preparation and Tutoring',
    611699: 'All Other Miscellaneous Schools and Instruction',
    611710: 'Educational Support Services',
    621399: 'Offices of All Other Miscellaneous Health Practitioners',
    624190: 'Other Individual and Family Services',
    711510: 'Independent Artists, Writers, and Performers',
    712110: 'Museums',
    722330: 'Mobile Food Services',
    812191: 'Diet and Weight Reducing Centers',
    812210: 'Funeral Homes and Funeral Services',
    812910: 'Pet Care (except Veterinary) Services',
    812921: 'Photofinishing Laboratories (except One-Hour)',
    812922: 'One-Hour Photofinishing',
    812990: 'All Other Personal Services'
  };
});