define("shared/print-store/shippable-countries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    code: 'US',
    name: 'United States',
    states: [{
      code: 'AL',
      name: 'Alabama'
    }, {
      code: 'AK',
      name: 'Alaska'
    }, {
      code: 'AZ',
      name: 'Arizona'
    }, {
      code: 'AR',
      name: 'Arkansas'
    }, {
      code: 'CA',
      name: 'California'
    }, {
      code: 'CO',
      name: 'Colorado'
    }, {
      code: 'CT',
      name: 'Connecticut'
    }, {
      code: 'DE',
      name: 'Delaware'
    }, {
      code: 'DC',
      name: 'District of Columbia'
    }, {
      code: 'FL',
      name: 'Florida'
    }, {
      code: 'GA',
      name: 'Georgia'
    }, {
      code: 'HI',
      name: 'Hawaii'
    }, {
      code: 'ID',
      name: 'Idaho'
    }, {
      code: 'IL',
      name: 'Illinois'
    }, {
      code: 'IN',
      name: 'Indiana'
    }, {
      code: 'IA',
      name: 'Iowa'
    }, {
      code: 'KS',
      name: 'Kansas'
    }, {
      code: 'KY',
      name: 'Kentucky'
    }, {
      code: 'LA',
      name: 'Louisiana'
    }, {
      code: 'ME',
      name: 'Maine'
    }, {
      code: 'MT',
      name: 'Montana'
    }, {
      code: 'NE',
      name: 'Nebraska'
    }, {
      code: 'NV',
      name: 'Nevada'
    }, {
      code: 'NH',
      name: 'New Hampshire'
    }, {
      code: 'NJ',
      name: 'New Jersey'
    }, {
      code: 'NM',
      name: 'New Mexico'
    }, {
      code: 'NY',
      name: 'New York'
    }, {
      code: 'NC',
      name: 'North Carolina'
    }, {
      code: 'ND',
      name: 'North Dakota'
    }, {
      code: 'OH',
      name: 'Ohio'
    }, {
      code: 'OK',
      name: 'Oklahoma'
    }, {
      code: 'OR',
      name: 'Oregon'
    }, {
      code: 'MD',
      name: 'Maryland'
    }, {
      code: 'MA',
      name: 'Massachusetts'
    }, {
      code: 'MI',
      name: 'Michigan'
    }, {
      code: 'MN',
      name: 'Minnesota'
    }, {
      code: 'MS',
      name: 'Mississippi'
    }, {
      code: 'MO',
      name: 'Missouri'
    }, {
      code: 'PA',
      name: 'Pennsylvania'
    }, {
      code: 'RI',
      name: 'Rhode Island'
    }, {
      code: 'SC',
      name: 'South Carolina'
    }, {
      code: 'SD',
      name: 'South Dakota'
    }, {
      code: 'TN',
      name: 'Tennessee'
    }, {
      code: 'TX',
      name: 'Texas'
    }, {
      code: 'UT',
      name: 'Utah'
    }, {
      code: 'VT',
      name: 'Vermont'
    }, {
      code: 'VA',
      name: 'Virginia'
    }, {
      code: 'WA',
      name: 'Washington'
    }, {
      code: 'WV',
      name: 'West Virginia'
    }, {
      code: 'WI',
      name: 'Wisconsin'
    }, {
      code: 'WY',
      name: 'Wyoming'
    }]
  }, {
    code: 'DE',
    name: 'Germany',
    states: []
  }, {
    code: 'AU',
    name: 'Australia',
    states: [],
    maxQuantity: 200
  }, {
    code: 'AT',
    name: 'Austria',
    states: []
  }, {
    code: 'BE',
    name: 'Belgium',
    states: []
  },
  // {
  //   code: 'BR',
  //   name: 'Brazil',
  //   states: []
  // },
  {
    code: 'CA',
    name: 'Canada',
    states: [],
    maxQuantity: 250
  }, {
    code: 'CL',
    name: 'Chile',
    states: [],
    maxQuantity: 150,
    excludeType: 'silk'
  },
  // {
  //   code: 'CO',
  //   name: 'Colombia',
  //   states: [],
  //   excludeType: 'silk'
  // },
  {
    code: 'NL',
    name: 'Netherlands',
    states: []
  }, {
    code: 'FR',
    name: 'France',
    states: []
  }, {
    code: 'LU',
    name: 'Luxembourg',
    states: []
  }, {
    code: 'PL',
    name: 'Poland',
    states: []
  }, {
    code: 'CZ',
    name: 'Czech Rep',
    states: []
  }, {
    code: 'BG',
    name: 'Bulgaria',
    states: []
  }, {
    code: 'DK',
    name: 'Denmark',
    states: []
  }, {
    code: 'ZA',
    name: 'South Africa',
    states: []
  }, {
    code: 'ES',
    name: 'Spain',
    states: []
  }, {
    code: 'FI',
    name: 'Finland',
    states: []
  }, {
    code: 'IT',
    name: 'Italy',
    states: []
  }, {
    code: 'SE',
    name: 'Sweden',
    states: []
  }, {
    code: 'PT',
    name: 'Portugal',
    states: []
  }, {
    code: 'GB',
    name: 'United Kingdom',
    states: []
  }, {
    code: 'IE',
    name: 'Ireland',
    states: []
  }, {
    code: 'MX',
    name: 'Mexico',
    states: [],
    maxQuantity: 250
  }, {
    code: 'AD',
    name: 'Andorra',
    states: []
  }, {
    code: 'SM',
    name: 'San Marino',
    states: []
  }, {
    code: 'SK',
    name: 'Slovakia',
    states: []
  }, {
    code: 'SI',
    name: 'Slovenia',
    states: []
  }, {
    code: 'HU',
    name: 'Hungary',
    states: []
  }, {
    code: 'VA',
    name: 'Vatican city',
    states: []
  }, {
    code: 'EE',
    name: 'Estonia',
    states: []
  }, {
    code: 'GR',
    name: 'Greece',
    states: []
  }, {
    code: 'HR',
    name: 'Croatia',
    states: []
  }, {
    code: 'LV',
    name: 'Latvia',
    states: []
  }, {
    code: 'LT',
    name: 'Lithuania',
    states: []
  }, {
    code: 'MT',
    name: 'Malta',
    states: []
  }, {
    code: 'RO',
    name: 'Romania',
    states: []
  }, {
    code: 'CY',
    name: 'Cyprus',
    states: []
  }];
});