define("shared/components/global/blue-icon", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    
  <div class="flex items-baseline {{@class}}">
    {{#if @showDisabledColor}}
      <i class="icon-{{@iconName}} text-bp-text-icons-subdued bg-bp-secondary-disabled rounded md:text-[24px] w-8 h-8 md:w-11 md:h-11 flex justify-center items-center"/>
    {{else}}
      <i class="icon-{{@iconName}} text-bp-border-action bg-bp-primary-main/[.15] rounded md:text-[24px] w-8 h-8 md:w-11 md:h-11 flex justify-center items-center"/>
    {{/if}}
  
  </div>
  
  */
  {
    "id": "D217GVGO",
    "block": "[[[1,\"\\n\"],[10,0],[15,0,[29,[\"flex items-baseline \",[30,1]]]],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,\"i\"],[15,0,[29,[\"icon-\",[30,3],\" text-bp-text-icons-subdued bg-bp-secondary-disabled rounded md:text-[24px] w-8 h-8 md:w-11 md:h-11 flex justify-center items-center\"]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"i\"],[15,0,[29,[\"icon-\",[30,3],\" text-bp-border-action bg-bp-primary-main/[.15] rounded md:text-[24px] w-8 h-8 md:w-11 md:h-11 flex justify-center items-center\"]]],[12],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"@showDisabledColor\",\"@iconName\"],false,[\"if\"]]",
    "moduleName": "shared/components/global/blue-icon.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});