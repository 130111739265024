define("shared/llc/llc-order-statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.WAITING_UPSELL_PAYMENT = _exports.STATE_QUESTIONS_DONE = _exports.STATE_DONE = _exports.REMOTE_STATUS_ON_HOLD = _exports.REMOTE_STATUS_EXCEPTION = _exports.REMOTE_STATUS_COMPLETED = _exports.REMOTE_STATUS_CANCELED = _exports.REMOTE_STATUS_AT_STATE = _exports.REMOTE_STATUS_APPLICATION_SENT = _exports.REMOTE_BACK_FROM_STATE = _exports.REGISTERED_AGENT_DONE = _exports.QUESTIONS_STARTED = _exports.QUESTIONS_DONE = _exports.PAYMENT_STARTED = _exports.PAYMENT_FAILED_POST_FORMATION = _exports.PAYMENT_FAILED_ITEMS = _exports.PAYMENT_FAILED_AGENT = _exports.PAYMENT_DONE_POST_FORMATION = _exports.PAYMENT_DONE_INCOMPLETE_REGISTERED_AGENT = _exports.PAYMENT_DONE = _exports.ORDER_OVERVIEW_DONE = _exports.LLC_NAME_DONE = _exports.EIN_DONE = _exports.DELETED = _exports.CONTACT_INFO_DONE = _exports.CHECKOUT_PAGE = _exports.AT_STATE = _exports.APPLICATION_SENT = void 0;
  const QUESTIONS_STARTED = _exports.QUESTIONS_STARTED = 'questions_started';
  const STATE_DONE = _exports.STATE_DONE = 'state_done';
  const AT_STATE = _exports.AT_STATE = 'at_state';
  const CONTACT_INFO_DONE = _exports.CONTACT_INFO_DONE = 'contact_info_done';
  const CHECKOUT_PAGE = _exports.CHECKOUT_PAGE = 'checkout_page';
  const LLC_NAME_DONE = _exports.LLC_NAME_DONE = 'llc_name_done';
  const PAYMENT_STARTED = _exports.PAYMENT_STARTED = 'payment_started';
  const PAYMENT_FAILED_ITEMS = _exports.PAYMENT_FAILED_ITEMS = 'payment_failed_items';
  const PAYMENT_FAILED_AGENT = _exports.PAYMENT_FAILED_AGENT = 'payment_failed_agent';
  const PAYMENT_DONE_INCOMPLETE_REGISTERED_AGENT = _exports.PAYMENT_DONE_INCOMPLETE_REGISTERED_AGENT = 'payment_done_incomplete_registered_agent';
  const PAYMENT_DONE = _exports.PAYMENT_DONE = 'payment_done';
  const REGISTERED_AGENT_DONE = _exports.REGISTERED_AGENT_DONE = 'registered_agent_done';
  const ORDER_OVERVIEW_DONE = _exports.ORDER_OVERVIEW_DONE = 'order_overview_done';
  const EIN_DONE = _exports.EIN_DONE = 'ein_done';
  const STATE_QUESTIONS_DONE = _exports.STATE_QUESTIONS_DONE = 'state_questions_done';
  const QUESTIONS_DONE = _exports.QUESTIONS_DONE = 'questions_done';
  const PAYMENT_DONE_POST_FORMATION = _exports.PAYMENT_DONE_POST_FORMATION = 'payment_done_post_formation';
  const APPLICATION_SENT = _exports.APPLICATION_SENT = 'application_sent';
  const WAITING_UPSELL_PAYMENT = _exports.WAITING_UPSELL_PAYMENT = 'waiting_upsell_payment';
  const DELETED = _exports.DELETED = 'deleted';
  const PAYMENT_FAILED_POST_FORMATION = _exports.PAYMENT_FAILED_POST_FORMATION = 'payment_failed_post_formation';
  const REMOTE_STATUS_AT_STATE = _exports.REMOTE_STATUS_AT_STATE = 'at_state';
  const REMOTE_BACK_FROM_STATE = _exports.REMOTE_BACK_FROM_STATE = 'back_from_state';
  const REMOTE_STATUS_COMPLETED = _exports.REMOTE_STATUS_COMPLETED = 'completed';
  const REMOTE_STATUS_CANCELED = _exports.REMOTE_STATUS_CANCELED = 'canceled';
  const REMOTE_STATUS_EXCEPTION = _exports.REMOTE_STATUS_EXCEPTION = 'exception';
  const REMOTE_STATUS_ON_HOLD = _exports.REMOTE_STATUS_ON_HOLD = 'hold';
  const REMOTE_STATUS_APPLICATION_SENT = _exports.REMOTE_STATUS_APPLICATION_SENT = 'application_sent';
  var _default = _exports.default = {
    CHECKOUT_PAGE,
    QUESTIONS_STARTED,
    STATE_DONE,
    AT_STATE,
    CONTACT_INFO_DONE,
    LLC_NAME_DONE,
    PAYMENT_STARTED,
    PAYMENT_FAILED_ITEMS,
    PAYMENT_FAILED_AGENT,
    PAYMENT_DONE_INCOMPLETE_REGISTERED_AGENT,
    PAYMENT_DONE,
    REGISTERED_AGENT_DONE,
    EIN_DONE,
    ORDER_OVERVIEW_DONE,
    STATE_QUESTIONS_DONE,
    QUESTIONS_DONE,
    PAYMENT_DONE_POST_FORMATION,
    APPLICATION_SENT,
    WAITING_UPSELL_PAYMENT,
    PAYMENT_FAILED_POST_FORMATION,
    DELETED,
    REMOTE_STATUS_APPLICATION_SENT,
    REMOTE_STATUS_ON_HOLD,
    REMOTE_STATUS_EXCEPTION,
    REMOTE_STATUS_CANCELED,
    REMOTE_STATUS_COMPLETED,
    REMOTE_STATUS_AT_STATE,
    REMOTE_BACK_FROM_STATE
  };
});