define("shared/sites/website-order-statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SUBMITTED_BRIEF = _exports.SITE_DELIVERED = _exports.QUESTIONS_STARTED = _exports.PAYMENT_STARTED = _exports.PAYMENT_FAILED = _exports.PAYMENT_DONE = _exports.ORDER_CANCELLED = _exports.NEEDS_MANAGER_APPROVAL = _exports.FEEDBACK_SENT = _exports.DRAFT_READY_FOR_USER_NO_EMAIL = _exports.DRAFT_READY_FOR_USER = _exports.DESIGN_NOT_STARTED = _exports.DESIGN_IN_PROGRESS = _exports.COMMENTS_FOR_CS = _exports.CHECKOUT_PAGE = void 0;
  const QUESTIONS_STARTED = _exports.QUESTIONS_STARTED = 'questions_started';
  const CHECKOUT_PAGE = _exports.CHECKOUT_PAGE = 'checkout_page';
  const PAYMENT_STARTED = _exports.PAYMENT_STARTED = 'payment_started';
  const PAYMENT_FAILED = _exports.PAYMENT_FAILED = 'payment_failed';
  const PAYMENT_DONE = _exports.PAYMENT_DONE = 'payment_done';
  const SUBMITTED_BRIEF = _exports.SUBMITTED_BRIEF = 'submitted_brief';
  const DESIGN_NOT_STARTED = _exports.DESIGN_NOT_STARTED = 'design_not_started';
  const DESIGN_IN_PROGRESS = _exports.DESIGN_IN_PROGRESS = 'design_in_progress';
  const NEEDS_MANAGER_APPROVAL = _exports.NEEDS_MANAGER_APPROVAL = 'needs_manager_approval';
  const COMMENTS_FOR_CS = _exports.COMMENTS_FOR_CS = 'comments_for_cs';
  const DRAFT_READY_FOR_USER = _exports.DRAFT_READY_FOR_USER = 'draft_ready_for_user';
  const DRAFT_READY_FOR_USER_NO_EMAIL = _exports.DRAFT_READY_FOR_USER_NO_EMAIL = 'draft_ready_for_user_no_email';
  const FEEDBACK_SENT = _exports.FEEDBACK_SENT = 'feedback_sent';
  const SITE_DELIVERED = _exports.SITE_DELIVERED = 'site_delivered';
  const ORDER_CANCELLED = _exports.ORDER_CANCELLED = 'order_cancelled';
  var _default = _exports.default = {
    QUESTIONS_STARTED,
    CHECKOUT_PAGE,
    PAYMENT_STARTED,
    PAYMENT_FAILED,
    PAYMENT_DONE,
    SUBMITTED_BRIEF,
    DESIGN_NOT_STARTED,
    DESIGN_IN_PROGRESS,
    NEEDS_MANAGER_APPROVAL,
    COMMENTS_FOR_CS,
    DRAFT_READY_FOR_USER,
    DRAFT_READY_FOR_USER_NO_EMAIL,
    FEEDBACK_SENT,
    SITE_DELIVERED,
    ORDER_CANCELLED
  };
});