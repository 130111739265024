define("shared/components/business-guide-shared/industry-search", ["exports", "@ember/component", "@ember/test-waiters", "@glimmer/component", "@ember/object", "@ember/service", "ember-concurrency", "@ember/utils", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _testWaiters, _component2, _object, _service, _emberConcurrency, _utils, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PowerSelect class="cp-power-select text-bp-text-icons-subdued w-full"
               data-testing-id="Segmentation_industry_dropdown" @allowClear={{false}} @initiallyOpened={{false}}
               @onChange={{@onChange}} @onClose={{@onClose}} @onOpen={{this.searchIndustry}}
               @placeholder={{t 'business_guide.industry.industry_placeholder' }} @renderInPlace={{true}}
               @searchEnabled={{true}} @search={{this.search}}
               @searchPlaceholder={{t 'business_guide.industry.industry_placeholder' }}
               @selected={{@selected}} @searchMessage="" as |name|>
    {{name}}
  </PowerSelect>
  
  */
  {
    "id": "dZ4gUuAj",
    "block": "[[[8,[39,0],[[24,0,\"cp-power-select text-bp-text-icons-subdued w-full\"],[24,\"data-testing-id\",\"Segmentation_industry_dropdown\"]],[[\"@allowClear\",\"@initiallyOpened\",\"@onChange\",\"@onClose\",\"@onOpen\",\"@placeholder\",\"@renderInPlace\",\"@searchEnabled\",\"@search\",\"@searchPlaceholder\",\"@selected\",\"@searchMessage\"],[false,false,[30,1],[30,2],[30,0,[\"searchIndustry\"]],[28,[37,1],[\"business_guide.industry.industry_placeholder\"],null],true,true,[30,0,[\"search\"]],[28,[37,1],[\"business_guide.industry.industry_placeholder\"],null],[30,3],\"\"]],[[\"default\"],[[[[1,\"\\n  \"],[1,[30,4]],[1,\"\\n\"]],[4]]]]],[1,\"\\n\"]],[\"@onChange\",\"@onClose\",\"@selected\",\"name\"],false,[\"power-select\",\"t\"]]",
    "moduleName": "shared/components/business-guide-shared/industry-search.hbs",
    "isStrictMode": false
  });
  let IndustrySearchComponent = _exports.default = (_class = class IndustrySearchComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "localStorage", _descriptor, this);
      _initializerDefineProperty(this, "ajax", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "deviceTypes", _descriptor4, this);
      _initializerDefineProperty(this, "intl", _descriptor5, this);
      _initializerDefineProperty(this, "heap", _descriptor6, this);
      _initializerDefineProperty(this, "industries", _descriptor7, this);
      _initializerDefineProperty(this, "selectedAnswer", _descriptor8, this);
      this.setIndustries();
    }
    setIndustries() {
      this.industries = this.args.industries;
      if ((0, _utils.isBlank)(this.industries)) {
        this.fetchIndustries.perform();
      }
    }
    *fetchIndustries() {
      this.industries = yield this.ajax.request(`/api/v1/industries?lang=${this.intl.locale}`);
    }
    searchIndustry(powerSelectObj) {
      powerSelectObj?.actions.search(this.args.selected || '');
    }
    search(term) {
      const termLength = 1;
      if (typeof term !== 'string' || term.length < termLength) {
        return [];
      }

      // Escape the string for use in regex:
      term = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const narrowMatcher = new RegExp(`^${term}`, 'ig');
      const widerMatcher = new RegExp(term, 'ig');
      let terms;
      terms = Array.isArray(this.industries) ? this.industries.filter(industry => industry.match(narrowMatcher)) : [];
      if ((0, _utils.isBlank)(terms)) {
        terms = Array.isArray(this.industries) ? this.industries.filter(industry => industry.match(widerMatcher)) : [];
      }

      // Eliminate Duplicates:
      terms = terms.filter((industry, index) => terms.indexOf(industry) === index);
      return terms.length === 0 ? [term] : terms;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "localStorage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "deviceTypes", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "heap", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "industries", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "selectedAnswer", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchIndustries", [_emberConcurrency.dropTask, _testWaiters.waitFor], Object.getOwnPropertyDescriptor(_class.prototype, "fetchIndustries"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchIndustry", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "searchIndustry"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "search", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, IndustrySearchComponent);
});