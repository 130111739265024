define("shared/components/info-cards/horizontal", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cp-info-cards-horizontal bg-bp-surface-main flex items-center lg:items-start p-4 rounded-md shadow-bp-md w-full lg:w-86 gap-2">
    <img src={{@imageSrc}} alt={{@title}} class="max-w-25 min-w-25 md:max-w-12 md:min-w-12 lg:max-w-13 lg:min-w-13">
    <div class="flex flex-col justify-center gap-1">
      <span class="text-bp-text-icons font-bold">
        {{@title}}
      </span>
      <span class="text-bp-text-icons-subdued text-sm w-full">
        {{@description}}
      </span>
    </div>
  </div>
  
  */
  {
    "id": "7phQWVEn",
    "block": "[[[10,0],[14,0,\"cp-info-cards-horizontal bg-bp-surface-main flex items-center lg:items-start p-4 rounded-md shadow-bp-md w-full lg:w-86 gap-2\"],[12],[1,\"\\n  \"],[10,\"img\"],[15,\"src\",[30,1]],[15,\"alt\",[30,2]],[14,0,\"max-w-25 min-w-25 md:max-w-12 md:min-w-12 lg:max-w-13 lg:min-w-13\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-col justify-center gap-1\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"text-bp-text-icons font-bold\"],[12],[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"text-bp-text-icons-subdued text-sm w-full\"],[12],[1,\"\\n      \"],[1,[30,3]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@imageSrc\",\"@title\",\"@description\"],false,[]]",
    "moduleName": "shared/components/info-cards/horizontal.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});