define("shared/components/global/promotion-badge", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="promotion-badge {{@customClass}}">{{@badgeText}}</div>
  
  */
  {
    "id": "faQ2FfZR",
    "block": "[[[10,0],[15,0,[29,[\"promotion-badge \",[30,1]]]],[12],[1,[30,2]],[13],[1,\"\\n\"]],[\"@customClass\",\"@badgeText\"],false,[]]",
    "moduleName": "shared/components/global/promotion-badge.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});