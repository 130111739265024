define("shared/components/business-guide-shared/launch-question", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cp-segmentation-funnel-launch flex-col md-flex-center md:justify-center h-full md:mx-auto my-5 md:my-0 pb-24 md:pb-16 md:max-w-148 {{if @llcFlow '' 'mx-5'}}">
    <div class="flex flex-col md:justify-center md:items-center md:justify-items-center md:text-center">
      <img src="/images/business-guide/launch.svg" class="w-27 h-27" alt={{t 'business_guide.launch.title' name=@name}}>
      <div class="text-3xl md:text-4xl text-bp-text-icons-main leading-7 md:leading-8 mt-1 md:mt-0 font-gazpacho">{{t 'business_guide.launch.title' name=@name}}</div>
      <div class="text-sm md:text-xl text-bp-text-icons-subdued leading-5 md:leading-7 mt-2">{{t 'business_guide.launch.subtitle'}}</div>
      <div class="flex flex-col w-full mt-6 md:mt-8">
        <div class="grid grid-cols-1 grid-rows-2 gap-3 md:gap-4 w-full mx-auto">
          {{#each @answers as |answer|}}
            <Inputs::RadioInput @inputLabel={{t (concat 'business_guide.launch.options.' answer)}} @radioVal={{answer}} @radioGroupVal={{@selectedAnswer}} @dataTesting={{concat 'business-guide-launch-answer-' answer '-checkbox'}}/>
          {{/each}}
        </div>
      </div>
    </div>
  </div>
  
  */
  {
    "id": "sfez89IT",
    "block": "[[[10,0],[15,0,[29,[\"cp-segmentation-funnel-launch flex-col md-flex-center md:justify-center h-full md:mx-auto my-5 md:my-0 pb-24 md:pb-16 md:max-w-148 \",[52,[30,1],\"\",\"mx-5\"]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-col md:justify-center md:items-center md:justify-items-center md:text-center\"],[12],[1,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/images/business-guide/launch.svg\"],[14,0,\"w-27 h-27\"],[15,\"alt\",[28,[37,1],[\"business_guide.launch.title\"],[[\"name\"],[[30,2]]]]],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"text-3xl md:text-4xl text-bp-text-icons-main leading-7 md:leading-8 mt-1 md:mt-0 font-gazpacho\"],[12],[1,[28,[35,1],[\"business_guide.launch.title\"],[[\"name\"],[[30,2]]]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"text-sm md:text-xl text-bp-text-icons-subdued leading-5 md:leading-7 mt-2\"],[12],[1,[28,[35,1],[\"business_guide.launch.subtitle\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-col w-full mt-6 md:mt-8\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"grid grid-cols-1 grid-rows-2 gap-3 md:gap-4 w-full mx-auto\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,3]],null]],null],null,[[[1,\"          \"],[8,[39,4],null,[[\"@inputLabel\",\"@radioVal\",\"@radioGroupVal\",\"@dataTesting\"],[[28,[37,1],[[28,[37,5],[\"business_guide.launch.options.\",[30,4]],null]],null],[30,4],[30,5],[28,[37,5],[\"business-guide-launch-answer-\",[30,4],\"-checkbox\"],null]]],null],[1,\"\\n\"]],[4]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@llcFlow\",\"@name\",\"@answers\",\"answer\",\"@selectedAnswer\"],false,[\"if\",\"t\",\"each\",\"-track-array\",\"inputs/radio-input\",\"concat\"]]",
    "moduleName": "shared/components/business-guide-shared/launch-question.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});