define("shared/components/global/logo-image", ["exports", "@ember/component", "@ember/test-waiters", "@glimmer/component", "@ember/service", "@ember/object", "@glimmer/tracking", "@ember/string", "ember-concurrency", "@datadog/browser-rum", "@ember/template-factory"], function (_exports, _component, _testWaiters, _component2, _service, _object, _tracking, _string, _emberConcurrency, _browserRum, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @embedSvg}}
    {{#if this.isSvg}}
      {{this.svgContent}}
    {{else}}
      {{#if @embedSvgLeft}}
        <div class="h-full w-full">
          <img src={{this.mockupUrl}} class="max-h-full max-w-full" alt="" role="none">
        </div>
      {{else}}
        <div class="flex-center h-full w-full">
          <img src={{this.mockupUrl}} class="m-auto max-h-full max-w-full" alt="" role="none">
        </div>
      {{/if}}
    {{/if}}
  {{else}}
    <div {{did-update this.setup this.mockupUrl @brandVersion}}
      class="logo-image relative flex flex-col {{@customClass}} {{if @customWidth @customWidth 'w-full'}} {{if @customHeight @customHeight 'h-full'}}"
      data-testing-id={{@dataTesting}}>
      {{#if this.loading}}
        <Global::BouncingLoader @color="bp-base-onSurface"/>
      {{else}}
        <div class="logo-mockup flex max-w-full max-h-full m-auto {{if this.isSvg 'w-full h-full'}} {{if this.loading 'hidden'}}"
             aria-label={{this.alt}}>
          {{#if this.isSvg}}
            {{this.svgContent}}
          {{else if this.mockupUrl}}
            <img class="logo-img" src={{this.mockupUrl}} alt="" role="none">
          {{/if}}
        </div>
      {{/if}}
      <div class="absolute bg-transparent h-full w-full"></div>
    </div>
  {{/if}}
  
  */
  {
    "id": "sWwQKCYv",
    "block": "[[[41,[30,1],[[[41,[30,0,[\"isSvg\"]],[[[1,\"    \"],[1,[30,0,[\"svgContent\"]]],[1,\"\\n\"]],[]],[[[41,[30,2],[[[1,\"      \"],[10,0],[14,0,\"h-full w-full\"],[12],[1,\"\\n        \"],[10,\"img\"],[15,\"src\",[30,0,[\"mockupUrl\"]]],[14,0,\"max-h-full max-w-full\"],[14,\"alt\",\"\"],[14,\"role\",\"none\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"flex-center h-full w-full\"],[12],[1,\"\\n        \"],[10,\"img\"],[15,\"src\",[30,0,[\"mockupUrl\"]]],[14,0,\"m-auto max-h-full max-w-full\"],[14,\"alt\",\"\"],[14,\"role\",\"none\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]]],[]]]],[]],[[[1,\"  \"],[11,0],[16,0,[29,[\"logo-image relative flex flex-col \",[30,3],\" \",[52,[30,4],[30,4],\"w-full\"],\" \",[52,[30,5],[30,5],\"h-full\"]]]],[16,\"data-testing-id\",[30,6]],[4,[38,1],[[30,0,[\"setup\"]],[30,0,[\"mockupUrl\"]],[30,7]],null],[12],[1,\"\\n\"],[41,[30,0,[\"loading\"]],[[[1,\"      \"],[8,[39,2],null,[[\"@color\"],[\"bp-base-onSurface\"]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[15,0,[29,[\"logo-mockup flex max-w-full max-h-full m-auto \",[52,[30,0,[\"isSvg\"]],\"w-full h-full\"],\" \",[52,[30,0,[\"loading\"]],\"hidden\"]]]],[15,\"aria-label\",[30,0,[\"alt\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"isSvg\"]],[[[1,\"          \"],[1,[30,0,[\"svgContent\"]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"mockupUrl\"]],[[[1,\"          \"],[10,\"img\"],[14,0,\"logo-img\"],[15,\"src\",[30,0,[\"mockupUrl\"]]],[14,\"alt\",\"\"],[14,\"role\",\"none\"],[12],[13],[1,\"\\n        \"]],[]],null]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[10,0],[14,0,\"absolute bg-transparent h-full w-full\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@embedSvg\",\"@embedSvgLeft\",\"@customClass\",\"@customWidth\",\"@customHeight\",\"@dataTesting\",\"@brandVersion\"],false,[\"if\",\"did-update\",\"global/bouncing-loader\"]]",
    "moduleName": "shared/components/global/logo-image.hbs",
    "isStrictMode": false
  });
  let LogoImageComponent = _exports.default = (_class = class LogoImageComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "ajax", _descriptor2, this);
      _initializerDefineProperty(this, "fontLoading", _descriptor3, this);
      _initializerDefineProperty(this, "loading", _descriptor4, this);
      _initializerDefineProperty(this, "svgContent", _descriptor5, this);
      _defineProperty(this, "retries", 0);
      _defineProperty(this, "maxRetries", 3);
      this.setup();
    }
    setup() {
      this.loading = true;
      if (this.mockupUrl) {
        this.setupTask.perform();
      }
    }
    *setupTask() {
      return yield Promise.all([this.loadFonts(), this.loadSvgContent()]).then(() => {
        this.loading = false;
      }).catch(err => {
        if (this.retries < this.maxRetries) {
          this.retries++;
          return setTimeout(() => this.setupTask.perform(), this.retries * 500);
        }
        this.loading = false;
        _browserRum.datadogRum.addError(err);
      });
    }
    loadSvgContent() {
      return new Promise((resolve, reject) => {
        if (!this.mockupUrl?.includes('.svg')) {
          return resolve();
        }
        this.ajax.get(this.mockupUrl).then(resolve).catch(xhr => reject(new Error(`${xhr.status}: ${xhr.statusText}`)));
      }, 'Logo component load svg content').then(response => this.svgContent = (0, _string.htmlSafe)(response));
    }
    loadFonts() {
      const fonts = this.args.brandVersion?.get('pxFonts');
      if ((fonts?.length ?? 0) === 0) {
        return Promise.resolve(null, 'Logo component no fonts to load');
      }
      return Promise.all(fonts.map(font => this.fontLoading.getFont(font)), 'Logo component fonts loading');
    }
    get mockupUrl() {
      return this.args.imgSrc || this.args.brandVersion?.get('mockupUrl');
    }
    get alt() {
      return this.args.alt ? this.args.alt : this.intl.t('logo');
    }
    get isSvg() {
      return this.mockupUrl?.includes('.svg');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fontLoading", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "svgContent", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupTask", [_emberConcurrency.restartableTask, _testWaiters.waitFor], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LogoImageComponent);
});