define("shared/llc/llc-state-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.OTHER_STATES = _exports.NAICS_STATES = _exports.LLC_SERIES_STATES = void 0;
  const LLC_SERIES_STATES = _exports.LLC_SERIES_STATES = ['de', 'ia', 'al', 'ar', 'dc', 'il', 'in', 'ks', 'mo', 'mt', 'nv', 'ok', 'tx', 'va', 'wy', 'nd', 'ne', 'ut', 'tn'];
  const NAICS_STATES = _exports.NAICS_STATES = ['ak', 'ct', 'ga', 'ms', 'nh', 'vt', 'wv', 'ut', 'la', 'az', 'mn', 'tx'];
  const OTHER_STATES = _exports.OTHER_STATES = ['tn', 'ut', 'ca'];
  var _default = _exports.default = {
    LLC_SERIES_STATES,
    NAICS_STATES,
    OTHER_STATES
  };
});