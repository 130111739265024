define("ember-link-action/mixins/link-action", ["exports", "@ember/object/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mixin.default.create({
    init() {
      this._super(...arguments);
      if (this.get('invokeAction')) {
        this._attachActionEvent();
      }
    },
    willDestroyElement() {
      if (this.get('invokeAction')) {
        this._detachActionEvent();
      }
    },
    _sendInvokeAction() {
      this.invokeAction();
    },
    _attachActionEvent() {
      this.on(this.get('eventName'), this, this._sendInvokeAction);
    },
    _detachActionEvent() {
      this.off(this.get('eventName'), this, this._sendInvokeAction);
    }
  });
});