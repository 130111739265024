define("ember-cli-string-helpers/helpers/humanize", ["exports", "@ember/component/helper", "@ember/string"], function (_exports, _helper, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.humanize = humanize;
  const regex = /_+|-+/g;
  const replacement = ' ';

  // The substituted value will be contained in the result variable
  function humanize(_ref) {
    let [string] = _ref;
    if ((0, _string.isHTMLSafe)(string)) {
      string = string.string;
    }
    if (string === undefined || string === null) {
      return '';
    }
    let result = string.toLowerCase().replace(regex, replacement);
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  var _default = _exports.default = (0, _helper.helper)(humanize);
});