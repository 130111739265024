define("shared/print-store/structures-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    id: 6
  }, {
    id: 17
  }, {
    id: 21
  }, {
    id: 2
  }, {
    id: 22
  }, {
    id: 13
  }, {
    id: 4
  }, {
    id: 19
  }, {
    id: 25
  }, {
    id: 10
  }, {
    id: 24
  }, {
    id: 1
  }, {
    id: 26
  }, {
    id: 14
  }, {
    id: 23
  }, {
    id: 9
  }, {
    id: 7
  }, {
    id: 8
  }, {
    id: 16
  }, {
    id: 11
  }, {
    id: 20
  }, {
    id: 15
  }, {
    id: 3
  }, {
    id: 12
  }];
});