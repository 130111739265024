define("shared/components/form-elements/country-selection/country-selection", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/utils", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _service, _utils, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.freeTextWidget}}
    <FormElements::TextInput @inputLabel={{@inputLabel}} @text={{@country}} @validType="country" @customClass={{@customClass}}/>
  {{else}}
    <FormElements::SelectionInput @selectAction={{this.select}}
                                  @clearText={{@clearText}}
                                  @inputLabel={{@inputLabel}}
                                  @disabled={{@disabled}}
                                  @initialValue={{this.countryName}}
                                  @list={{this.countriesList}}
                                  @withErrorCheck={{@withErrorCheck}}
                                  @validType={{@validType}}
                                  @prefilled={{@prefilled}}
                                  @customClass={{@customClass}}
                                  @errorNotAbsolute={{@errorNotAbsolute}}
    />
  {{/if}}
  
  */
  {
    "id": "FTfAQghp",
    "block": "[[[41,[30,0,[\"freeTextWidget\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@inputLabel\",\"@text\",\"@validType\",\"@customClass\"],[[30,1],[30,2],\"country\",[30,3]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],null,[[\"@selectAction\",\"@clearText\",\"@inputLabel\",\"@disabled\",\"@initialValue\",\"@list\",\"@withErrorCheck\",\"@validType\",\"@prefilled\",\"@customClass\",\"@errorNotAbsolute\"],[[30,0,[\"select\"]],[30,4],[30,1],[30,5],[30,0,[\"countryName\"]],[30,0,[\"countriesList\"]],[30,6],[30,7],[30,8],[30,3],[30,9]]],null],[1,\"\\n\"]],[]]]],[\"@inputLabel\",\"@country\",\"@customClass\",\"@clearText\",\"@disabled\",\"@withErrorCheck\",\"@validType\",\"@prefilled\",\"@errorNotAbsolute\"],false,[\"if\",\"form-elements/text-input\",\"form-elements/selection-input\"]]",
    "moduleName": "shared/components/form-elements/country-selection/country-selection.hbs",
    "isStrictMode": false
  });
  let CountrySelectionComponent = _exports.default = (_class = class CountrySelectionComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "countries", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _defineProperty(this, "freeTextWidget", null);
      this.freeTextWidget = (0, _utils.isPresent)(this.args.country) && !this.intl.exists(`countries.${this.args.country}.name`, 'en');
    }
    get countryName() {
      if ((0, _utils.isBlank)(this.args.country)) return null;
      return this.intl.t(`countries.${this.args.country}.name`).toString();
    }
    get list() {
      let countries = this.countries.countries;
      if (this.args.llcCountries) {
        countries = countries.filter(country => this.countries.llcCountriesCodes.includes(country.code));
      }
      if (this.args.boiUSExternalCountries) {
        countries = countries.filter(country => this.countries.boiUSExternalCountriesCodes.includes(country.code));
      }
      if (this.args.boiFormationCountries) {
        countries = countries.filter(country => this.countries.formationCountriesCodes.includes(country.code));
      }
      return countries;
    }
    get countriesList() {
      let list = this.list;
      if (this.args.excludedCountries) {
        list = this.list.filter(country => !this.args.excludedCountries.includes(country.code));
      }
      return list.map(country => this.intl.t(`countries.${country.code}.name`).toString());
    }
    get selectedCountry() {
      return this.list.find(country => this.intl.t(`countries.${country.code}.name`).toString() === this.countryName);
    }
    selectedCountryCodeByName(name) {
      const foundCountry = this.list.find(country => this.intl.t(`countries.${country.code}.name`).toString() === name);
      return foundCountry ? foundCountry.code : null;
    }
    select(value) {
      if ((0, _utils.isBlank)(this.args.index)) {
        return this.args.selectAction(this.selectedCountryCodeByName(value));
      } else {
        this.args.selectAction(this.selectedCountryCodeByName(value), this.args.index);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "countries", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CountrySelectionComponent);
});