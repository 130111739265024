define("shared/components/global/info-message", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="info-message {{@type}} flex flex-col justify-between md:flex-row border border-solid rounded p-4 mb-4">
    <div class="flex flex-col max-w-3xl h-full">
      <div class="flex items-center">
        {{#if (eq @type 'negative')}}
          <span class="flex-center font-bold text-white bg-bp-base-negative rounded-full w-4 h-4 min-w-4 min-h-4 mr-2 text-xs">!</span>
        {{else if (eq @type 'info')}}
          <i class="icon-info-circle text-bp-text-icons-action mr-3"/>
        {{/if}}
        <span class="message-text {{if @description 'font-bold'}}">{{@title}}</span>
      </div>
      {{#if @description}}
        <span class="message-text leading-5">{{@description}}</span>
      {{/if}}
    </div>
    {{#if @ctaText}}
      <div class="mt-2 sm:mt-0">
        <Buttons::Primary @type="primary"
                          @text={{@ctaText}}
                          @action={{@action}}
                          @size="m"
                          @dataTestingId={{@dataTestingId}}/>
      </div>
    {{/if}}
  </div>
  
  */
  {
    "id": "FBZYgYI7",
    "block": "[[[10,0],[15,0,[29,[\"info-message \",[30,1],\" flex flex-col justify-between md:flex-row border border-solid rounded p-4 mb-4\"]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-col max-w-3xl h-full\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex items-center\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,1],\"negative\"],null],[[[1,\"        \"],[10,1],[14,0,\"flex-center font-bold text-white bg-bp-base-negative rounded-full w-4 h-4 min-w-4 min-h-4 mr-2 text-xs\"],[12],[1,\"!\"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],\"info\"],null],[[[1,\"        \"],[10,\"i\"],[14,0,\"icon-info-circle text-bp-text-icons-action mr-3\"],[12],[13],[1,\"\\n      \"]],[]],null]],[]]],[1,\"      \"],[10,1],[15,0,[29,[\"message-text \",[52,[30,2],\"font-bold\"]]]],[12],[1,[30,3]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,2],[[[1,\"      \"],[10,1],[14,0,\"message-text leading-5\"],[12],[1,[30,2]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[41,[30,4],[[[1,\"    \"],[10,0],[14,0,\"mt-2 sm:mt-0\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@type\",\"@text\",\"@action\",\"@size\",\"@dataTestingId\"],[\"primary\",[30,4],[30,5],\"m\",[30,6]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@type\",\"@description\",\"@title\",\"@ctaText\",\"@action\",\"@dataTestingId\"],false,[\"if\",\"eq\",\"buttons/primary\"]]",
    "moduleName": "shared/components/global/info-message.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});