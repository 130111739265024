define("shared/components/buttons/tertiary", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Buttons::Base @buttonName="tertiary"
                 @text={{@text}}
                 @size={{@size}}
                 @fullWidth={{@fullWidth}}
                 @iconLeft={{@iconLeft}}
                 @iconRight={{@iconRight}}
                 @disabled={{@disabled}}
                 @action={{@action}}
                 @task={{@task}}
                 @active={{@active}}
                 @dataTestingId={{@dataTestingId}}
                 @loader={{@loader}}
                 @loaderColor="bp-text-icons-action"/>
  
  */
  {
    "id": "3l3dvhjy",
    "block": "[[[8,[39,0],null,[[\"@buttonName\",\"@text\",\"@size\",\"@fullWidth\",\"@iconLeft\",\"@iconRight\",\"@disabled\",\"@action\",\"@task\",\"@active\",\"@dataTestingId\",\"@loader\",\"@loaderColor\"],[\"tertiary\",[30,1],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],\"bp-text-icons-action\"]],null],[1,\"\\n\"]],[\"@text\",\"@size\",\"@fullWidth\",\"@iconLeft\",\"@iconRight\",\"@disabled\",\"@action\",\"@task\",\"@active\",\"@dataTestingId\",\"@loader\"],false,[\"buttons/base\"]]",
    "moduleName": "shared/components/buttons/tertiary.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});