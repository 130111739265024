define("shared/components/checkout/error", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex items-center error text-2xs text-bp-base-brand mt-1 text-left">
    <i class="icon-exclamation-circle text-sm mr-1"></i>
    <span>{{@text}}</span>
  </div>
  */
  {
    "id": "ZDXTs6QV",
    "block": "[[[10,0],[14,0,\"flex items-center error text-2xs text-bp-base-brand mt-1 text-left\"],[12],[1,\"\\n  \"],[10,\"i\"],[14,0,\"icon-exclamation-circle text-sm mr-1\"],[12],[13],[1,\"\\n  \"],[10,1],[12],[1,[30,1]],[13],[1,\"\\n\"],[13]],[\"@text\"],false,[]]",
    "moduleName": "shared/components/checkout/error.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});