define("ember-gtm/services/gtm", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GtmService extends _service.default {
    _pageHasGTM() {
      return window.dataLayer && typeof window.dataLayer === 'object';
    }
    trackGTM(data) {
      if (this._pageHasGTM()) {
        window.dataLayer.push(data);
      }
    }
  }
  _exports.default = GtmService;
});