define("shared/components/business-documents/preview", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="legal-document-preview w-63 h-80 bg-white {{@class}}">
    <div class="wrapper w-full h-full flex flex-col px-8 py-10">
      <div class="font-bold mb-ms text-bp-text-icons-main">{{t (concat 'business_documents.documents.' @slug '.name')}}</div>
    </div>
  </div>
  
  */
  {
    "id": "EMcjCNHn",
    "block": "[[[10,0],[15,0,[29,[\"legal-document-preview w-63 h-80 bg-white \",[30,1]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"wrapper w-full h-full flex flex-col px-8 py-10\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"font-bold mb-ms text-bp-text-icons-main\"],[12],[1,[28,[35,0],[[28,[37,1],[\"business_documents.documents.\",[30,2],\".name\"],null]],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"@slug\"],false,[\"t\",\"concat\"]]",
    "moduleName": "shared/components/business-documents/preview.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});