define("shared/components/global/claim-badge", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex justify-start pb-4 pt-4">
    <span
      class="{{@customClass}} text-bp-text-icons-positive border-bp-border-positive py-1 px-2 text-2xs text-center bg-white rounded border border-solid shadow"
      data-testing-id={{@dataTesting}}>
      {{@badgeText}}
    </span>
  </div>
  
  
  */
  {
    "id": "FQHzlY46",
    "block": "[[[10,0],[14,0,\"flex justify-start pb-4 pt-4\"],[12],[1,\"\\n  \"],[10,1],[15,0,[29,[[30,1],\" text-bp-text-icons-positive border-bp-border-positive py-1 px-2 text-2xs text-center bg-white rounded border border-solid shadow\"]]],[15,\"data-testing-id\",[30,2]],[12],[1,\"\\n    \"],[1,[30,3]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[\"@customClass\",\"@dataTesting\",\"@badgeText\"],false,[]]",
    "moduleName": "shared/components/global/claim-badge.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});