define("shared/llc/llc-order-remote-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ON_HOLD = _exports.EXCEPTION = _exports.COMPLETED = _exports.CANCELED = _exports.BACK_FROM_STATE = _exports.AT_STATE = _exports.APPLICATION_SENT = void 0;
  const APPLICATION_SENT = _exports.APPLICATION_SENT = 'application_sent';
  const AT_STATE = _exports.AT_STATE = 'at_state';
  const BACK_FROM_STATE = _exports.BACK_FROM_STATE = 'back_from_state';
  const COMPLETED = _exports.COMPLETED = 'completed';
  const CANCELED = _exports.CANCELED = 'canceled';
  const EXCEPTION = _exports.EXCEPTION = 'exception';
  const ON_HOLD = _exports.ON_HOLD = 'hold';
  var _default = _exports.default = {
    APPLICATION_SENT,
    AT_STATE,
    BACK_FROM_STATE,
    COMPLETED,
    CANCELED,
    EXCEPTION,
    ON_HOLD
  };
});