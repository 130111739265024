define("shared/components/form-elements/dropdown/dropdown", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/utils", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _utils, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cp-dropdown relative">
    <div class="relative flex items-center text-center" {{on 'click' this.toggleDropdown}} role="button" data-testing-id={{@dataTestingIdOpenDropdown}}>
      <div class="{{@className}} cursor-pointer border border-solid text-bp-text-icons-subdued shadow-none hover:border-gray-70 focus:border-bp-border-action focus:shadow-none focus:outline-none {{if this.showDropdown 'border-bp-border-action'}}"></div>
      <i class="icon-angle-down text-bp-text-icons-action absolute right-4 transition duration-200 {{if this.showDropdown 'transform rotate-180'}}"></i>
      <span class="absolute pl-4">{{this.currentItem.value}}</span>
    </div>
    {{#if this.showDropdown}}
      <div role="button" class="fixed top-0 left-0 w-full h-full" {{on "click" this.toggleDropdown}}></div>
      <ul class="border border-solid border-bp-border-action shadow-md rounded-md mt-0.5 absolute z-10 w-full bg-white z-50">
        {{#each @items as |item|}}
          <li class="item" {{on 'click' (fn this.chooseItem item)}} role="button" data-testing-id={{concat @dataTestingIdChooseItem item.key}}>
            {{item.value}}
          </li>
        {{/each}}
      </ul>
    {{/if}}
  </div>
  
  */
  {
    "id": "0lVi56Om",
    "block": "[[[10,0],[14,0,\"cp-dropdown relative\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"relative flex items-center text-center\"],[24,\"role\",\"button\"],[16,\"data-testing-id\",[30,1]],[4,[38,0],[\"click\",[30,0,[\"toggleDropdown\"]]],null],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[[30,2],\" cursor-pointer border border-solid text-bp-text-icons-subdued shadow-none hover:border-gray-70 focus:border-bp-border-action focus:shadow-none focus:outline-none \",[52,[30,0,[\"showDropdown\"]],\"border-bp-border-action\"]]]],[12],[13],[1,\"\\n    \"],[10,\"i\"],[15,0,[29,[\"icon-angle-down text-bp-text-icons-action absolute right-4 transition duration-200 \",[52,[30,0,[\"showDropdown\"]],\"transform rotate-180\"]]]],[12],[13],[1,\"\\n    \"],[10,1],[14,0,\"absolute pl-4\"],[12],[1,[30,0,[\"currentItem\",\"value\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"showDropdown\"]],[[[1,\"    \"],[11,0],[24,\"role\",\"button\"],[24,0,\"fixed top-0 left-0 w-full h-full\"],[4,[38,0],[\"click\",[30,0,[\"toggleDropdown\"]]],null],[12],[13],[1,\"\\n    \"],[10,\"ul\"],[14,0,\"border border-solid border-bp-border-action shadow-md rounded-md mt-0.5 absolute z-10 w-full bg-white z-50\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,3]],null]],null],null,[[[1,\"        \"],[11,\"li\"],[24,0,\"item\"],[24,\"role\",\"button\"],[16,\"data-testing-id\",[28,[37,4],[[30,5],[30,4,[\"key\"]]],null]],[4,[38,0],[\"click\",[28,[37,5],[[30,0,[\"chooseItem\"]],[30,4]],null]],null],[12],[1,\"\\n          \"],[1,[30,4,[\"value\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[4]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@dataTestingIdOpenDropdown\",\"@className\",\"@items\",\"item\",\"@dataTestingIdChooseItem\"],false,[\"on\",\"if\",\"each\",\"-track-array\",\"concat\",\"fn\"]]",
    "moduleName": "shared/components/form-elements/dropdown/dropdown.hbs",
    "isStrictMode": false
  });
  let Dropdown = _exports.default = (_dec = (0, _object.computed)('args.items.@each.key'), (_class = class Dropdown extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "showDropdown", _descriptor, this);
    }
    get currentItem() {
      const item = this.args.items.find(item => item.selected);
      return (0, _utils.isPresent)(item) ? item : this.args.items.firstObject;
    }
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    }
    chooseItem(item) {
      this.currentItem = item;
      this.toggleDropdown();
      if ((0, _utils.isBlank)(this.args.onClick)) return;
      this.args.onClick(item.key);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showDropdown", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "currentItem", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "currentItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleDropdown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDropdown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "chooseItem", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "chooseItem"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Dropdown);
});