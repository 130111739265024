define("shared/components/global/modal-background", ["exports", "@ember/component", "@ember/test-waiters", "@glimmer/component", "@ember/object", "@ember/utils", "@glimmer/tracking", "ember-concurrency", "@ember/template-factory"], function (_exports, _component, _testWaiters, _component2, _object, _utils, _tracking, _emberConcurrency, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cp-global-modal-background {{@extraClass}}" role="button" {{on 'click' this.backgroundClicked}}></div>
  
  */
  {
    "id": "+IZrBwyp",
    "block": "[[[11,0],[16,0,[29,[\"cp-global-modal-background \",[30,1]]]],[24,\"role\",\"button\"],[4,[38,0],[\"click\",[30,0,[\"backgroundClicked\"]]],null],[12],[13],[1,\"\\n\"]],[\"@extraClass\"],false,[\"on\"]]",
    "moduleName": "shared/components/global/modal-background.hbs",
    "isStrictMode": false
  });
  let ModalBackgroundComponent = _exports.default = (_class = class ModalBackgroundComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "extraClass", _descriptor, this);
      this.showModalBackground.perform();
    }
    *showModalBackground() {
      yield (0, _emberConcurrency.timeout)(50);
      this.extraClass = 'shown';
    }
    backgroundClicked() {
      if ((0, _utils.isBlank)(this.args.onClick)) return;
      this.args.onClick();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "extraClass", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "showModalBackground", [_emberConcurrency.dropTask, _testWaiters.waitFor], Object.getOwnPropertyDescriptor(_class.prototype, "showModalBackground"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "backgroundClicked", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "backgroundClicked"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ModalBackgroundComponent);
});