define("ember-composable-helpers/helpers/group-by", ["exports", "@ember/component/helper", "@ember/object"], function (_exports, _helper, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.groupBy = groupBy;
  function groupBy(_ref) {
    let [byPath, array] = _ref;
    let groups = {};
    array.forEach(item => {
      let groupName = (0, _object.get)(item, byPath);
      let group = groups[groupName];
      if (!Array.isArray(group)) {
        group = [];
        groups[groupName] = group;
      }
      group.push(item);
    });
    return groups;
  }
  var _default = _exports.default = (0, _helper.helper)(groupBy);
});