define("shared/components/business-guide-shared/focus-card", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{unless @selected 'border border-transparent'}}>
    <div class="h-full flex flex-col justify-center bg-bp-surface-main transition duration-200 shadow-bp-sm rounded hover:shadow-bp-md cursor-pointer {{if @selected 'border-2 border-bp-primary-main hover:border-bp-primary-main' 'border border-transparent hover:border-black'}}"
         data-testing-id={{@dataTestingId}} role="button" {{on 'click' @clickAction}}>
      <div class="flex items-center gap-2 md:font-bold px-2 md:px-6 py-4 md:py-3 min-h-20 md:text-center text-left">
        <img src={{@image}} class="w-8 h-8 md:w-16 md:h-16" alt={{@title}}>
        <span>{{@title}}</span>
      </div>
    </div>
  </div>
  
  */
  {
    "id": "5g8xO1lx",
    "block": "[[[10,0],[15,0,[52,[51,[30,1]],\"border border-transparent\"]],[12],[1,\"\\n  \"],[11,0],[16,0,[29,[\"h-full flex flex-col justify-center bg-bp-surface-main transition duration-200 shadow-bp-sm rounded hover:shadow-bp-md cursor-pointer \",[52,[30,1],\"border-2 border-bp-primary-main hover:border-bp-primary-main\",\"border border-transparent hover:border-black\"]]]],[16,\"data-testing-id\",[30,2]],[24,\"role\",\"button\"],[4,[38,2],[\"click\",[30,3]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex items-center gap-2 md:font-bold px-2 md:px-6 py-4 md:py-3 min-h-20 md:text-center text-left\"],[12],[1,\"\\n      \"],[10,\"img\"],[15,\"src\",[30,4]],[14,0,\"w-8 h-8 md:w-16 md:h-16\"],[15,\"alt\",[30,5]],[12],[13],[1,\"\\n      \"],[10,1],[12],[1,[30,5]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@selected\",\"@dataTestingId\",\"@clickAction\",\"@image\",\"@title\"],false,[\"unless\",\"if\",\"on\"]]",
    "moduleName": "shared/components/business-guide-shared/focus-card.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});