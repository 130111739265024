define("shared/components/global/confetti-succesfull", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="confetti-container" {{did-insert this.launchConfetti}}></div>
  
  */
  {
    "id": "lSGPJGtu",
    "block": "[[[11,0],[24,0,\"confetti-container\"],[4,[38,0],[[30,0,[\"launchConfetti\"]]],null],[12],[13],[1,\"\\n\"]],[],false,[\"did-insert\"]]",
    "moduleName": "shared/components/global/confetti-succesfull.hbs",
    "isStrictMode": false
  });
  class ConfettiSuccesfullComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "colors", ['#FC6078', 'rgba(22, 108, 255, 0.3)', '#166CFF', '#FFD7D7', 'rgba(252, 96, 120, 0.5)']);
      _defineProperty(this, "launchConfetti", element => {
        for (let i = 0; i < 100; i++) {
          const confetti = this.createConfettiElement();
          element.appendChild(confetti);
          setTimeout(() => {
            confetti.remove();
          }, 5000);
        }
      });
    }
    createConfettiElement() {
      const confetti = document.createElement('div');
      confetti.className = 'confetti';
      confetti.style.backgroundColor = this.colors[Math.floor(Math.random() * this.colors.length)];
      confetti.style.left = `${Math.random() * 100}%`;
      confetti.style.animationDuration = `${Math.random() * 3 + 2}s`;
      confetti.style.opacity = Math.random();
      return confetti;
    }
  }
  _exports.default = ConfettiSuccesfullComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ConfettiSuccesfullComponent);
});