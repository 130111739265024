define("shared/llc/llc-suffix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    al: ['LLC', 'L.L.C.', 'Limited Liability Company'],
    ak: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Limited Liability Co.', 'Ltd. Liability Company'],
    az: ['LLC', 'L.L.C.', 'Limited Liability Company', 'LC', 'L.C.', 'Limited Company'],
    ar: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Limited Liability Co.', 'Ltd. Liability Company', 'LC', 'L.C.', 'Limited Company', 'Limited Co.', 'Ltd. Company'],
    ca: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Limited Liability Co.', 'Ltd. Liability Company', 'Ltd. Liability Co.'],
    co: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Limited Liability Co.', 'Ltd. Liability Company', 'Ltd. Liability Co.', 'Limited', 'Ltd.'],
    ct: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Limited Liability Co.', 'Ltd. Liability Company', 'Ltd. Liability Co.'],
    de: ['LLC', 'L.L.C.', 'Limited Liability Company'],
    dc: ['LLC', 'L.L.C.', 'Limited Liability Company'],
    fl: ['LLC', 'L.L.C.', 'Limited Liability Company'],
    ga: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Limited Liability Co', 'Ltd. Liability Company', 'Ltd Liability Company', 'Ltd. Liability Co.', 'Ltd Liability Co', 'LC', 'L.C.', 'Limited Company', 'Limited Co.', 'Ltd. Company', 'LLC.', 'Ltd Company', 'Ltd Liability Co.', 'Limited Co', 'Ltd Co.', 'Ltd Co', 'L.L.C'],
    hi: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Limited Liability Law Company', 'LLLC', 'L.L.L.C.'],
    id: ['LLC', 'L.L.C.', 'Limited Liability Company', 'L.C.', 'Limited Company'],
    il: ['LLC', 'L.L.C.', 'Limited Liability Company'],
    in: ['LLC', 'L.L.C.', 'Limited Liability Company'],
    ia: ['LLC', 'L.L.C.', 'Limited Liability Company', 'LC', 'L.C.', 'Limited Company'],
    ks: ['LLC', 'L.L.C.', 'Limited Liability Company', 'LC', 'L.C.', 'Limited Company'],
    ky: ['LLC', 'Limited Liability Company', 'Limited Company', 'LC.', 'LTD CO.'],
    la: ['LLC', 'Limited Liability Company', 'L.C.'],
    me: ['LLC', 'L.L.C.', 'Limited Liability Company', 'LC', 'L.C.', 'Limited Company'],
    md: ['LLC', 'Limited Liability Company', 'LC', ', Limited Liability Company', ', L.L.C.', ', LLC', ', L.C.', ', LC'],
    ma: ['LLC', 'L.L.C.', 'Limited Liability Company', 'LC', 'L.C.', 'Limited Company'],
    mi: ['LLC', 'L.L.C.', 'Limited Liability Company'],
    mn: ['LLC', 'L.L.C.', 'Limited Liability Company', 'LIMITED LIABILITY COMPANY', 'L. L. C.'],
    ms: ['LLC', 'L.L.C.', 'Limited Liability Company'],
    mo: ['LLC', 'L.L.C.', 'Limited Liability Company', 'LC', 'L.C.', 'Limited Company'],
    mt: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Limited Liability Co', 'Limited Liability Co.', 'LC', 'L.C.', 'Limited Company', 'Ltd Co.', 'Ltd Co', 'Ltd Co.', 'Ltd. Co.', 'L C', 'L L C', 'L. C.', 'L. L. C.'],
    ne: ['LLC', 'Limited Liability Company', 'L.C.'],
    nv: ['LLC', 'L.L.C.', 'Limited Liability Company', 'LC', 'L.C.', 'Limited Company', 'Limited Co.', 'Limited Co', 'L.L.C', 'Limited', 'LTD', 'LTD.', 'Limited-Liability Company'],
    nh: ['LLC', 'L.L.C.', 'Limited Liability Company'],
    nj: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Limited Liability Co.', 'Ltd Liability Company', 'Ltd. Liability Co.', 'Ltd Liability Co'],
    nm: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Limited Liability Co.', 'LC', 'L.C.', 'Limited Company', 'Limited Co.', 'Ltd. Company', 'Ltd Co', 'Ltd. Co.', 'LC.'],
    ny: ['LLC', 'L.L.C.', 'Limited Liability Company'],
    nc: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Limited Liability Co.', 'Ltd. Liability Company'],
    nd: ['LLC', 'L.L.C.', 'Limited Liability Company'],
    oh: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Limited', 'Ltd', 'Ltd.'],
    ok: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Limited Liability Co.', 'Ltd. Liability Company', 'LC', 'L.C.', 'Limited Company'],
    or: ['LLC', 'L.L.C.', 'Limited Liability Company'],
    ri: ['LLC', 'L.L.C.', 'Limited Liability Company'],
    pa: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Ltd Liability Company', 'Ltd. Liability Co.', 'Ltd Liability Co', 'Limited Company', 'Co', 'Co.', 'Company', 'Limited', 'Ltd', 'Ltd.'],
    sc: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Limited Liability Co.', 'Ltd. Liability Company', 'LC', 'L.C.', 'Limited Company', 'Limited Co.'],
    sd: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Limited Liability Co.', 'Ltd. Liability Company', 'LC', 'L.C.', 'Limited Company', 'Limited Co.'],
    tn: ['LLC', 'L.L.C.', 'Limited Liability Company'],
    tx: ['LLC', 'L.L.C.', 'Limited Liability Company', 'LC', 'L.C.', 'Limited Company'],
    ut: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Limited Liability Co.', 'Ltd. Liability Company', 'LC', 'L.C.', 'Limited Company'],
    vt: ['LLC', 'L.L.C.', 'Limited Liability Company', 'LC', 'L.C.', 'Limited Company', 'Ltd. Co.'],
    va: ['LLC', 'L.L.C.', 'Limited Liability Company', 'LC', 'L.C.', 'Limited Company'],
    wa: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Limited Liability Co.'],
    wv: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Limited Liability Co.', 'Ltd. Liability Company', 'LC', 'L.C.', 'Limited Company', 'Limited Co.', 'Ltd. Company'],
    wi: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Limited Liability Co.'],
    wy: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Limited Liability Co.', 'Ltd. Liability Company', 'Ltd. Liability Co.', 'LC', 'L.C.', 'Limited Company', 'LIMITED LIABILITY COMPANY', 'L3C']
  };
});