define("shared/components/global/carousel", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/utils", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _object, _utils, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ol class="w-full flex flex-col items-start justify-start">
    <li class="w-full h-4 flex items-center justify-start mt-4">
      {{#each @items as |item itemIndex|}}
        <button type="button"
                data-testing-id={{item.dataTestingId}}
                class="w-3.5 h-3.5 relative ml-4 first:ml-0" {{on "click" (fn this.changeSelection itemIndex)}}>
          <div class="rounded-full bg-white border border-bp-border-main h-full w-full absolute top-0 left-0">
            {{#if (eq this.selected itemIndex)}}
              <div class="rounded-full bg-bp-base-brand w-2 h-2 m-2px"></div>
            {{/if}}
          </div>
        </button>
      {{/each}}
    </li>
    {{#each @items as |item itemIndex|}}
      {{#if (eq this.selected itemIndex)}}
        <div class="carousel-slide-in">
          {{yield item}}
        </div>
      {{/if}}
    {{/each}}
  </ol>
  
  */
  {
    "id": "C8h6SD4N",
    "block": "[[[10,\"ol\"],[14,0,\"w-full flex flex-col items-start justify-start\"],[12],[1,\"\\n  \"],[10,\"li\"],[14,0,\"w-full h-4 flex items-center justify-start mt-4\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"      \"],[11,\"button\"],[16,\"data-testing-id\",[30,2,[\"dataTestingId\"]]],[24,0,\"w-3.5 h-3.5 relative ml-4 first:ml-0\"],[24,4,\"button\"],[4,[38,2],[\"click\",[28,[37,3],[[30,0,[\"changeSelection\"]],[30,3]],null]],null],[12],[1,\"\\n        \"],[10,0],[14,0,\"rounded-full bg-white border border-bp-border-main h-full w-full absolute top-0 left-0\"],[12],[1,\"\\n\"],[41,[28,[37,5],[[30,0,[\"selected\"]],[30,3]],null],[[[1,\"            \"],[10,0],[14,0,\"rounded-full bg-bp-base-brand w-2 h-2 m-2px\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2,3]],null],[1,\"  \"],[13],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[41,[28,[37,5],[[30,0,[\"selected\"]],[30,5]],null],[[[1,\"      \"],[10,0],[14,0,\"carousel-slide-in\"],[12],[1,\"\\n        \"],[18,6,[[30,4]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[4,5]],null],[13],[1,\"\\n\"]],[\"@items\",\"item\",\"itemIndex\",\"item\",\"itemIndex\",\"&default\"],false,[\"each\",\"-track-array\",\"on\",\"fn\",\"if\",\"eq\",\"yield\"]]",
    "moduleName": "shared/components/global/carousel.hbs",
    "isStrictMode": false
  });
  let CarouselComponent = _exports.default = (_class = class CarouselComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "CYCLE_TIME", 6000);
      _defineProperty(this, "intervalId", null);
      _initializerDefineProperty(this, "selected", _descriptor, this);
      this.initializeInterval();
    }
    initializeInterval() {
      this.intervalId = setInterval(() => {
        const index = this.args.items.length > 0 ? (this.selected + 1) % this.args.items.length : 0;
        this.changeSelectionFromInterval(index);
      }, this.CYCLE_TIME);
    }
    destroyInterval() {
      if ((0, _utils.isPresent)(this.intervalId)) {
        clearInterval(this.intervalId);
      }
    }
    changeSelectionFromInterval(index) {
      if (this.selected === index) {
        return;
      }
      this.selected = index;
    }
    changeSelection(index) {
      if (this.selected === index) {
        return;
      }
      this.destroyInterval();
      this.selected = index;
      this.initializeInterval();
    }
    willDestroy() {
      super.willDestroy();
      this.destroyInterval();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "initializeInterval", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "initializeInterval"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "destroyInterval", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "destroyInterval"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSelectionFromInterval", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeSelectionFromInterval"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSelection", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeSelection"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CarouselComponent);
});