define("shared/components/global/youtube-iframe", ["exports", "@ember/component", "@glimmer/component", "ember-concurrency", "rsvp", "@ember/utils", "@datadog/browser-rum", "@ember/template-factory"], function (_exports, _component, _component2, _emberConcurrency, _rsvp, _utils, _browserRum, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div id={{this.playerId}} class={{@customClass}}></div>
  
  */
  {
    "id": "cej4K3nj",
    "block": "[[[10,0],[15,1,[30,0,[\"playerId\"]]],[15,0,[30,1]],[12],[13],[1,\"\\n\"]],[\"@customClass\"],false,[]]",
    "moduleName": "shared/components/global/youtube-iframe.hbs",
    "isStrictMode": false
  });
  let YoutubeIframeComponent = _exports.default = (_class = class YoutubeIframeComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "YOUTUBE_API", 'https://www.youtube.com/iframe_api');
      this.loadAndCreatePlayer.perform();
    }
    get playerId() {
      return (0, _utils.isBlank)(this.args.playerId) ? 'player' : this.args.playerId;
    }
    *loadAndCreatePlayer() {
      try {
        yield this.loadYouTubeApi();
        this.onYouTubeIframeAPIReady();
      } catch (error) {
        _browserRum.datadogRum.addError(new Error('YoutubeApiSetupFailure'), {
          internal_error: error.message
        });
      }
    }
    loadYouTubeApi() {
      return new _rsvp.Promise(resolve => {
        const previous = window.onYouTubeIframeAPIReady;

        // The API will call this function when page has finished downloading
        // the JavaScript for the player API.
        window.onYouTubeIframeAPIReady = () => {
          if (previous) {
            previous();
          }
          resolve(window.YT);
        };
        if (window.YT && window.YT.loaded) {
          // If already loaded, make sure not to load the script again.
          resolve(window.YT);
        } else {
          let ytScript = document.createElement('script');
          ytScript.src = this.YOUTUBE_API;
          document.head.appendChild(ytScript);
        }
      });
    }
    onYouTubeIframeAPIReady() {
      const playerId = document.querySelector(`#${this.playerId}`);
      return new _rsvp.Promise((resolve, reject) => {
        if ((0, _utils.isBlank)(playerId)) {
          reject('Couldn\'t find the container element to create a YouTube player');
        }
        if ((0, _utils.isBlank)(this.args.videoId)) {
          reject('Couldn\'t find the video id to create a YouTube player');
        }
        const player = new window.YT.Player(playerId, {
          height: (0, _utils.isBlank)(this.args.height) ? '390' : this.args.height,
          width: (0, _utils.isBlank)(this.args.width) ? '640' : this.args.width,
          videoId: this.args.videoId,
          events: {
            onReady() {
              resolve(player);
            },
            'onStateChange': e => {
              this.onPlayerStateChange(e);
            }
          }
        });
      });
    }
    onPlayerStateChange(event) {
      switch (event.target.getPlayerState()) {
        case window.YT.PlayerState.PLAYING:
          if ((0, _utils.isPresent)(this.args.playingVideo)) this.args.playingVideo();
          break;
        case window.YT.PlayerState.PAUSED:
          if ((0, _utils.isPresent)(this.args.pausedVideo)) this.args.pausedVideo();
          break;
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "loadAndCreatePlayer", [_emberConcurrency.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadAndCreatePlayer"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, YoutubeIframeComponent);
});