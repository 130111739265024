define("shared/components/info-cards/base", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cp-info-cards-base relative flex flex-row bg-bp-secondary-main p-4 md:p-6 border border-transparent transition duration-200 rounded-md shadow-bp-md items-center cursor-pointer md:w-100 md:h-33 lg:h-auto lg:w-63 w-full h-auto"
       data-testing-id={{@dataTestingId}}>
    <img src={{@imageSrc}} alt={{@imageAlt}} class="absolute right-0 top-[-4rem] h-25 w-25">
    <div class="flex flex-row items-center w-full">
      <div class="flex flex-col md:ml-2 w-full">
        <span class="text-bp-text-icons font-bold">{{@title}}</span>
        {{#if @description}}
          <span class="text-bp-text-icons-subdued text-xs">{{@description}}</span>
        {{/if}}
      </div>
    </div>
  </div>
  
  */
  {
    "id": "BNI27uN0",
    "block": "[[[10,0],[14,0,\"cp-info-cards-base relative flex flex-row bg-bp-secondary-main p-4 md:p-6 border border-transparent transition duration-200 rounded-md shadow-bp-md items-center cursor-pointer md:w-100 md:h-33 lg:h-auto lg:w-63 w-full h-auto\"],[15,\"data-testing-id\",[30,1]],[12],[1,\"\\n  \"],[10,\"img\"],[15,\"src\",[30,2]],[15,\"alt\",[30,3]],[14,0,\"absolute right-0 top-[-4rem] h-25 w-25\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-row items-center w-full\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-col md:ml-2 w-full\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"text-bp-text-icons font-bold\"],[12],[1,[30,4]],[13],[1,\"\\n\"],[41,[30,5],[[[1,\"        \"],[10,1],[14,0,\"text-bp-text-icons-subdued text-xs\"],[12],[1,[30,5]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@dataTestingId\",\"@imageSrc\",\"@imageAlt\",\"@title\",\"@description\"],false,[\"if\"]]",
    "moduleName": "shared/components/info-cards/base.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});