define("shared/components/winback/banner", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _service, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cp-winback-banner w-full">
    <div class="flex {{if this.isRed 'bg-gradient-l-primary-gradient-red' 'bg-gradient-l-primary-gradient-blue'}} text-white text-left md:text-center w-full p-4">
      <div class="flex-grow">
        <span class="hidden md:inline-flex font-bold mr-sm">{{t (concat 'winback_promo_modal_v3.banner_' this.winbackPromotion.winbackBannerType) discount=this.discountValue}}</span>
        <span class="md:hidden font-bold mr-xs">{{t (concat 'winback_promo_modal_v3.banner_mobile_' this.winbackPromotion.winbackBannerType) discount=this.discountValue}}</span>
        {{#if (eq this.winbackPromotion.winbackBannerType 'red')}}
          <button data-testing-id="winback-banner-claim" class="banner-cta rounded-md border border-solid border-white text-white font-bold mr-xs hover:bg-white hover:text-bp-text-icons-subdued py-1 px-3" {{on "click" this.applyCoupon}} type="button">
            {{t 'winback_promo_modal_v3.banner_red_cta' discount=this.discountValue}}
          </button>
        {{/if}}
      </div>
      <button data-testing-id="winback-banner-close" {{on "click" this.closeBanner}} type="button"><i class="banner-close icon-close1"/></button>
    </div>
  </div>
  
  */
  {
    "id": "gnWBg37y",
    "block": "[[[10,0],[14,0,\"cp-winback-banner w-full\"],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"flex \",[52,[30,0,[\"isRed\"]],\"bg-gradient-l-primary-gradient-red\",\"bg-gradient-l-primary-gradient-blue\"],\" text-white text-left md:text-center w-full p-4\"]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex-grow\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"hidden md:inline-flex font-bold mr-sm\"],[12],[1,[28,[35,1],[[28,[37,2],[\"winback_promo_modal_v3.banner_\",[30,0,[\"winbackPromotion\",\"winbackBannerType\"]]],null]],[[\"discount\"],[[30,0,[\"discountValue\"]]]]]],[13],[1,\"\\n      \"],[10,1],[14,0,\"md:hidden font-bold mr-xs\"],[12],[1,[28,[35,1],[[28,[37,2],[\"winback_promo_modal_v3.banner_mobile_\",[30,0,[\"winbackPromotion\",\"winbackBannerType\"]]],null]],[[\"discount\"],[[30,0,[\"discountValue\"]]]]]],[13],[1,\"\\n\"],[41,[28,[37,3],[[30,0,[\"winbackPromotion\",\"winbackBannerType\"]],\"red\"],null],[[[1,\"        \"],[11,\"button\"],[24,\"data-testing-id\",\"winback-banner-claim\"],[24,0,\"banner-cta rounded-md border border-solid border-white text-white font-bold mr-xs hover:bg-white hover:text-bp-text-icons-subdued py-1 px-3\"],[24,4,\"button\"],[4,[38,4],[\"click\",[30,0,[\"applyCoupon\"]]],null],[12],[1,\"\\n          \"],[1,[28,[35,1],[\"winback_promo_modal_v3.banner_red_cta\"],[[\"discount\"],[[30,0,[\"discountValue\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n    \"],[11,\"button\"],[24,\"data-testing-id\",\"winback-banner-close\"],[24,4,\"button\"],[4,[38,4],[\"click\",[30,0,[\"closeBanner\"]]],null],[12],[10,\"i\"],[14,0,\"banner-close icon-close1\"],[12],[13],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"t\",\"concat\",\"eq\",\"on\"]]",
    "moduleName": "shared/components/winback/banner.hbs",
    "isStrictMode": false
  });
  let WinbackBannerComponent = _exports.default = (_class = class WinbackBannerComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "deviceTypes", _descriptor, this);
      _initializerDefineProperty(this, "localStorage", _descriptor2, this);
      _initializerDefineProperty(this, "winbackPromotion", _descriptor3, this);
      _initializerDefineProperty(this, "uiElements", _descriptor4, this);
      _defineProperty(this, "discountValue", 30);
    }
    get isRed() {
      return this.winbackPromotion.winbackBannerType == 'red';
    }
    closeBanner() {
      this.uiElements.hideHolidayTimer();
      this.localStorage.removeItem('winbackBannerType');
      this.winbackPromotion.winbackBannerType = '';
    }
    applyCoupon() {
      const coupon = 'welcomeback' + this.discountValue;
      let props = {
        'value': coupon,
        'expiration': dayjs().add(5, 'days')
      };
      this.localStorage.setItem('tailorStudioCoupon', JSON.stringify(props));
      this.localStorage.setItem('winbackBannerType', 'blue');
      this.winbackPromotion.winbackBannerType = 'blue';
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "deviceTypes", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "localStorage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "winbackPromotion", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiElements", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "closeBanner", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeBanner"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "applyCoupon", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "applyCoupon"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WinbackBannerComponent);
});