define("shared/llc/ein-order-statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATE_DONE = _exports.QUESTIONS_STARTED = _exports.QUESTIONS_DONE = _exports.PAYMENT_STARTED = _exports.PAYMENT_FAILED = _exports.PAYMENT_DONE = _exports.DELETED = _exports.CHECKOUT_PAGE = _exports.APPLICATION_SENT = void 0;
  const QUESTIONS_STARTED = _exports.QUESTIONS_STARTED = 'questions_started';
  const CHECKOUT_PAGE = _exports.CHECKOUT_PAGE = 'checkout_page';
  const QUESTIONS_DONE = _exports.QUESTIONS_DONE = 'questions_done';
  const PAYMENT_STARTED = _exports.PAYMENT_STARTED = 'payment_started';
  const PAYMENT_FAILED = _exports.PAYMENT_FAILED = 'payment_failed';
  const PAYMENT_DONE = _exports.PAYMENT_DONE = 'payment_done';
  const APPLICATION_SENT = _exports.APPLICATION_SENT = 'application_sent';
  const DELETED = _exports.DELETED = 'deleted';
  const STATE_DONE = _exports.STATE_DONE = 'state_done';
  var _default = _exports.default = {
    QUESTIONS_STARTED,
    QUESTIONS_DONE,
    PAYMENT_STARTED,
    PAYMENT_FAILED,
    PAYMENT_DONE,
    APPLICATION_SENT,
    DELETED,
    CHECKOUT_PAGE,
    STATE_DONE
  };
});