define("shared/components/inputs/address-autocomplete-input/address-autocomplete-input", ["exports", "ember-concurrency/-private/async-arrow-runtime", "@ember/component", "@glimmer/component", "@ember/service", "@ember/object", "@glimmer/tracking", "@ember/utils", "ember-concurrency", "shared/llc/us-states", "@ember/template-factory"], function (_exports, _asyncArrowRuntime, _component, _component2, _service, _object, _tracking, _utils, _emberConcurrency, _usStates, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <FormElements::TextInput @inputLabel={{if this.addressSummary (t 'llc.funnel.labels.address') (t 'llc.funnel.business_address.address_placeholder')}}
                           @validType={{if this.isEmptyInput 'emptyPhysicalAddress' (if this.isOutOfBlockedState 'outOfBlockedState' 'physicalAddress')}}
                           @text={{get @contact 'address'}}
                           @customClass={{@customClass}}
                           @stateForAddressValidation={{get @contact 'state'}}
    {{did-insert this.setupGoogleAutocomplete}}
                           @onKeyup={{this.checkAddressInput}}
                           @id='addressInputId'
                           @elementId="addressID"/>
  
  
  */
  {
    "id": "JWpPR2gf",
    "block": "[[[8,[39,0],[[4,[38,4],[[30,0,[\"setupGoogleAutocomplete\"]]],null]],[[\"@inputLabel\",\"@validType\",\"@text\",\"@customClass\",\"@stateForAddressValidation\",\"@onKeyup\",\"@id\",\"@elementId\"],[[52,[30,0,[\"addressSummary\"]],[28,[37,2],[\"llc.funnel.labels.address\"],null],[28,[37,2],[\"llc.funnel.business_address.address_placeholder\"],null]],[52,[30,0,[\"isEmptyInput\"]],\"emptyPhysicalAddress\",[52,[30,0,[\"isOutOfBlockedState\"]],\"outOfBlockedState\",\"physicalAddress\"]],[28,[37,3],[[30,1],\"address\"],null],[30,2],[28,[37,3],[[30,1],\"state\"],null],[30,0,[\"checkAddressInput\"]],\"addressInputId\",\"addressID\"]],null],[1,\"\\n\\n\"]],[\"@contact\",\"@customClass\"],false,[\"form-elements/text-input\",\"if\",\"t\",\"get\",\"did-insert\"]]",
    "moduleName": "shared/components/inputs/address-autocomplete-input/address-autocomplete-input.hbs",
    "isStrictMode": false
  });
  let AddressAutocompleteInputComponent = _exports.default = (_class = class AddressAutocompleteInputComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "googleAddress", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "heap", _descriptor3, this);
      _initializerDefineProperty(this, "addressSummary", _descriptor4, this);
      _initializerDefineProperty(this, "isEmptyInput", _descriptor5, this);
      _initializerDefineProperty(this, "isOutOfBlockedState", _descriptor6, this);
      _initializerDefineProperty(this, "addressObj", _descriptor7, this);
      _defineProperty(this, "autocomplete", void 0);
      _defineProperty(this, "sendSelectionFromDropdownDataEvent", (0, _asyncArrowRuntime.buildTask)(() => ({
        context: this,
        generator: function* () {
          this.heap.track('google_address_dropodown_clicked', {
            company_id: this.args.companyId
          });
        }
      }), null, "sendSelectionFromDropdownDataEvent", "drop"));
      this.addressObj = {
        ...this.args.contact
      };
    }
    setupGoogleAutocomplete(element) {
      this.updateAddress();
      this.googleAddress.googleApi.then(() => {
        this.autocomplete = new google.maps.places.Autocomplete(element, {
          types: ['address'],
          // just to ensure you get only addresses
          componentRestrictions: {
            country: 'us'
          }
        });
        element.placeholder = '';
        this.autocomplete.addListener('place_changed', this.fillInAddress);
      });
      // append the pac-container to the input's parent, so it doesn't get cut off by page scroll
      setTimeout(() => {
        element.parentNode.style.position = 'relative';
        let pacContainers = document.querySelectorAll('.pac-container');
        for (let pacContainer of pacContainers) {
          let parent = pacContainer.parentNode;
          if (!parent.classList.contains('text-input-container')) {
            element.parentNode.appendChild(pacContainer);
            return;
          }
        }
      }, 300);
    }
    fillInAddress() {
      if (!this.autocomplete) {
        return;
      }
      const place = this.autocomplete.getPlace();
      if (place) {
        this.addressObj = this.googleAddress.parseAddress(place);
        this.checkStateValidity();
        this.args.onAddressChange(this.args.contact, this.addressObj, true);
        this.updateAddress();
        this.sendSelectionFromDropdownDataEvent.perform();
      } else {
        this.addressObj = {
          city: '',
          county: '',
          state: '',
          postalCode: ''
        };
        this.args.onAddressChange(this.args.contact, this.addressObj);
        this.updateAddress();
      }
    }
    checkAddressInput() {
      if ((0, _utils.isBlank)(this.args.contact.address)) {
        this.isEmptyInput = true;
        this.addressObj = {
          city: '',
          county: '',
          state: this.args.showBlockedState ? this.args.contact.state : '',
          postalCode: ''
        };
        this.args.onAddressChange(this.args.contact, this.addressObj);
        this.updateAddress();
      } else {
        this.isEmptyInput = false;
      }
    }
    updateAddress() {
      let summary;
      if ((0, _utils.isPresent)(this.addressObj) && (0, _utils.isPresent)(this.addressObj.address)) {
        summary = this.addressObj.address;
        summary = (0, _utils.isPresent)(this.addressObj.city) ? `${summary}, ${this.addressObj.city}` : summary;
        summary = (0, _utils.isPresent)(this.addressObj.state) ? `${summary}, ${this.addressObj.state}` : summary;
        summary = (0, _utils.isPresent)(this.addressObj.postalCode) ? `${summary}, ${this.addressObj.postalCode}` : summary;
        summary = `${summary}, United States`;
      }
      this.addressSummary = summary;
    }
    get stateKey() {
      return Object.keys(_usStates.default).find(state => _usStates.default[state] === this.args.contact.state).toUpperCase();
    }
    checkStateValidity() {
      if (this.args.showBlockedState) {
        if (this.addressObj.state !== this.args.contact.state) {
          this.addressObj = {
            city: '',
            county: '',
            state: this.args.showBlockedState ? this.args.contact.state : '',
            postalCode: ''
          };
          this.isOutOfBlockedState = true;
        } else {
          this.isOutOfBlockedState = false;
        }
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "googleAddress", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "heap", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "addressSummary", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isEmptyInput", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isOutOfBlockedState", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "addressObj", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        address: '',
        city: '',
        county: '',
        state: '',
        postalCode: ''
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupGoogleAutocomplete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupGoogleAutocomplete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fillInAddress", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "fillInAddress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkAddressInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "checkAddressInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateAddress", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateAddress"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AddressAutocompleteInputComponent);
});