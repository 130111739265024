define("shared/components/attribution-survey/attribution-footer", ["exports", "@ember/component", "@glimmer/component", "@ember/utils", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _utils, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="footer-container cp-attribution-survey-footer bg-white h-20 flex justify-center items-center w-full mt-auto" {{did-insert (fn this.beacon.updateWidgetHeight "WITH_FOOTER") }} {{will-destroy (fn this.beacon.updateWidgetHeight "WITHOUT_FOOTER")}}>
    <div class="mr-4">
      <Buttons::Link
        @type="gray"
        @text={{t 'back'}}
        @size="l"
        @iconLeft="icon-angle-left"
        @action={{@backAction}}
        @dataTestingId="funnel_attribution_step_back"/>
    </div>
    <div class="ml-4">
      {{#if this.shouldInvokeNextAction}}
        <Buttons::Primary
          @text={{t 'next'}}
          @size="l"
          @type="primary"
          @iconRight="icon-angle-right"
          @action={{@nextAction}}
          @dataTestingId="funnel_attribution_step_next"/>
      {{else}}
        <Buttons::Secondary
          @text={{t 'skip'}}
          @size="l"
          @iconRight="icon-angle-right"
          @action={{@skipAction}}
          @dataTestingId="funnel_attribution_step_skip"/>
      {{/if}}
    </div>
  </div>
  
  */
  {
    "id": "TBHY/YRk",
    "block": "[[[11,0],[24,0,\"footer-container cp-attribution-survey-footer bg-white h-20 flex justify-center items-center w-full mt-auto\"],[4,[38,0],[[28,[37,1],[[30,0,[\"beacon\",\"updateWidgetHeight\"]],\"WITH_FOOTER\"],null]],null],[4,[38,2],[[28,[37,1],[[30,0,[\"beacon\",\"updateWidgetHeight\"]],\"WITHOUT_FOOTER\"],null]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"mr-4\"],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@type\",\"@text\",\"@size\",\"@iconLeft\",\"@action\",\"@dataTestingId\"],[\"gray\",[28,[37,4],[\"back\"],null],\"l\",\"icon-angle-left\",[30,1],\"funnel_attribution_step_back\"]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"ml-4\"],[12],[1,\"\\n\"],[41,[30,0,[\"shouldInvokeNextAction\"]],[[[1,\"      \"],[8,[39,6],null,[[\"@text\",\"@size\",\"@type\",\"@iconRight\",\"@action\",\"@dataTestingId\"],[[28,[37,4],[\"next\"],null],\"l\",\"primary\",\"icon-angle-right\",[30,2],\"funnel_attribution_step_next\"]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,7],null,[[\"@text\",\"@size\",\"@iconRight\",\"@action\",\"@dataTestingId\"],[[28,[37,4],[\"skip\"],null],\"l\",\"icon-angle-right\",[30,3],\"funnel_attribution_step_skip\"]],null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@backAction\",\"@nextAction\",\"@skipAction\"],false,[\"did-insert\",\"fn\",\"will-destroy\",\"buttons/link\",\"t\",\"if\",\"buttons/primary\",\"buttons/secondary\"]]",
    "moduleName": "shared/components/attribution-survey/attribution-footer.hbs",
    "isStrictMode": false
  });
  let AttributionFooter = _exports.default = (_class = class AttributionFooter extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "beacon", _descriptor, this);
    }
    get shouldInvokeNextAction() {
      return (0, _utils.isPresent)(this.args.selectedAnswer);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "beacon", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AttributionFooter);
});