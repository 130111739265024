define("shared/components/global/eligible-badge", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex justify-center">
    {{#if  (eq @customClass 'v9-badge') }}
      <i class="icon-star text-bp-text-icons-positive my-1 md:hidden" />
      <span class="text-bp-text-icons-positive font-bold md:font-normal md:border-bp-border-positive py-1 px-2 text-2xs text-center bg-white md:rounded md:border md:border-solid md:shadow" data-testing-id={{@dataTesting}}>
        {{t 'brands.brand_version.home.apollo.eligible_plan'}}
      </span>
    {{else}}
      <span class="{{@customClass}} text-bp-text-icons-positive border-bp-border-positive py-1 px-2 text-2xs text-center bg-white rounded border border-solid shadow" data-testing-id={{@dataTesting}}>
        {{#if @customText}}
          {{@customText}}
        {{else if @addon}}
          {{t 'brands.brand_version.home.apollo.added_to_plan'}}
        {{else}}
          {{t 'brands.brand_version.home.apollo.eligible_plan'}}
        {{/if}}
      </span>
    {{/if}}
  </div>
  
  */
  {
    "id": "4yDN4NW2",
    "block": "[[[10,0],[14,0,\"flex justify-center\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,1],\"v9-badge\"],null],[[[1,\"    \"],[10,\"i\"],[14,0,\"icon-star text-bp-text-icons-positive my-1 md:hidden\"],[12],[13],[1,\"\\n    \"],[10,1],[14,0,\"text-bp-text-icons-positive font-bold md:font-normal md:border-bp-border-positive py-1 px-2 text-2xs text-center bg-white md:rounded md:border md:border-solid md:shadow\"],[15,\"data-testing-id\",[30,2]],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"brands.brand_version.home.apollo.eligible_plan\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[15,0,[29,[[30,1],\" text-bp-text-icons-positive border-bp-border-positive py-1 px-2 text-2xs text-center bg-white rounded border border-solid shadow\"]]],[15,\"data-testing-id\",[30,2]],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"        \"],[1,[30,3]],[1,\"\\n\"]],[]],[[[41,[30,4],[[[1,\"        \"],[1,[28,[35,2],[\"brands.brand_version.home.apollo.added_to_plan\"],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,2],[\"brands.brand_version.home.apollo.eligible_plan\"],null]],[1,\"\\n      \"]],[]]]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"@customClass\",\"@dataTesting\",\"@customText\",\"@addon\"],false,[\"if\",\"eq\",\"t\"]]",
    "moduleName": "shared/components/global/eligible-badge.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});