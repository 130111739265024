define("shared/components/llc/arf-order-display-name", ["exports", "@ember/component", "@glimmer/component", "shared/llc/us-states", "@ember/utils", "@ember/template-factory"], function (_exports, _component, _component2, _usStates, _utils, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.hasFullDetails}}
    {{this.companyName}}, {{this.state}}
  {{else}}
    {{this.displayOrderId}}
  {{/if}}
  
  */
  {
    "id": "1873Q32W",
    "block": "[[[41,[30,0,[\"hasFullDetails\"]],[[[1,\"  \"],[1,[30,0,[\"companyName\"]]],[1,\", \"],[1,[30,0,[\"state\"]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[30,0,[\"displayOrderId\"]]],[1,\"\\n\"]],[]]]],[],false,[\"if\"]]",
    "moduleName": "shared/components/llc/arf-order-display-name.hbs",
    "isStrictMode": false
  });
  class ArfOrderDisplayNameComponent extends _component2.default {
    get state() {
      return _usStates.default[this.args.company.state];
    }
    get companyName() {
      if (this.args.company.type === 'initial-company') {
        return this.args.company.name;
      }
      return this.args.company.businessName;
    }
    get hasFullDetails() {
      return (0, _utils.isPresent)(this.companyName) && (0, _utils.isPresent)(this.args.company.state);
    }
    get displayOrderId() {
      const id = (0, _utils.isPresent)(this.args.annualComplianceProduct) ? this.args.annualComplianceProduct.id : this.args.company.id.replace(/[-,\D]/g, '').slice(0, 5);
      return `ARF-34-${id.padStart(3, '0')}`;
    }
  }
  _exports.default = ArfOrderDisplayNameComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ArfOrderDisplayNameComponent);
});