define("shared/components/select-cards/small", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cp-select-cards-vertical flex flex-row bg-bp-secondary-main p-4 border hover:border-black transition duration-200 rounded-md shadow-bp-md hover:shadow-bp-lg items-center cursor-pointer w-50 h-20 {{if @selected 'border-2 border-bp-primary-main hover:border-bp-primary-main' 'border-transparent'}}"
       data-testing-id={{@dataTestingId}} role="button" {{on 'click' @clickAction}}>
    {{#if @imageSrc}}
      <img src={{@imageSrc}} alt={{@imageAlt}} class="h-8 w-8">
    {{/if}}
    {{#if @iconClass}}
      <i class="icon-{{@iconClass}} text-4xl px-4"></i>
    {{/if}}
    <div class="flex flex-row items-center justify-center w-full">
      <span class="text-bp-text-icons font-bold w-full">{{@title}}</span>
      <i class="icon-check-circle text-sm text-bp-primary-main font-bold transition duration-200 {{if @selected 'opacity-1' 'opacity-0'}}"></i>
    </div>
  </div>
  
  */
  {
    "id": "ZF3IrSpz",
    "block": "[[[11,0],[16,0,[29,[\"cp-select-cards-vertical flex flex-row bg-bp-secondary-main p-4 border hover:border-black transition duration-200 rounded-md shadow-bp-md hover:shadow-bp-lg items-center cursor-pointer w-50 h-20 \",[52,[30,1],\"border-2 border-bp-primary-main hover:border-bp-primary-main\",\"border-transparent\"]]]],[16,\"data-testing-id\",[30,2]],[24,\"role\",\"button\"],[4,[38,1],[\"click\",[30,3]],null],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"    \"],[10,\"img\"],[15,\"src\",[30,4]],[15,\"alt\",[30,5]],[14,0,\"h-8 w-8\"],[12],[13],[1,\"\\n\"]],[]],null],[41,[30,6],[[[1,\"    \"],[10,\"i\"],[15,0,[29,[\"icon-\",[30,6],\" text-4xl px-4\"]]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"flex flex-row items-center justify-center w-full\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"text-bp-text-icons font-bold w-full\"],[12],[1,[30,7]],[13],[1,\"\\n    \"],[10,\"i\"],[15,0,[29,[\"icon-check-circle text-sm text-bp-primary-main font-bold transition duration-200 \",[52,[30,1],\"opacity-1\",\"opacity-0\"]]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@selected\",\"@dataTestingId\",\"@clickAction\",\"@imageSrc\",\"@imageAlt\",\"@iconClass\",\"@title\"],false,[\"if\",\"on\"]]",
    "moduleName": "shared/components/select-cards/small.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});