define("shared/llc/po-box-states", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    az: 'Arizona',
    ca: 'California',
    co: 'Colorado',
    ct: 'Connecticut',
    dc: 'District of Columbia',
    fl: 'Florida',
    id: 'Idaho',
    il: 'Illinois',
    ia: 'Iowa',
    la: 'Louisiana',
    md: 'Maryland',
    ma: 'Massachusetts',
    ne: 'Nebraska',
    nh: 'New Hampshire',
    nm: 'New Mexico',
    ok: 'Oklahoma',
    or: 'Oregon',
    ri: 'Rhode Island',
    sc: 'South Carolina',
    sd: 'South Dakota',
    tn: 'Tennessee',
    ut: 'Utah',
    vt: 'Vermont',
    va: 'Virginia',
    wa: 'Washington'
  };
});