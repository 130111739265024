define("shared/components/business-license/action-bar", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="back pr-4 mr-4">
    <Buttons::Link
      @text={{t 'back'}}
      @type="gray"
      @size="l"
      @iconLeft="icon-angle-left"
      @loader={{@back.isRunning}}
      @action={{this.backAction}}
      @dataTestingId="permits_contact_page_back_button"/>
  </div>
  <div class="next">
    <Buttons::Primary
      @text={{t 'submit'}}
      @type="primary"
      @size="l"
      @iconRight={{this.iconRight}}
      @disabled={{not @valid}}
      @action={{this.nextAction}}
      @loader={{@next.isRunning}}
      @dataTestingId="permits_contact_page_next_button"/>
  </div>
  
  */
  {
    "id": "fLDYwok0",
    "block": "[[[10,0],[14,0,\"back pr-4 mr-4\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@text\",\"@type\",\"@size\",\"@iconLeft\",\"@loader\",\"@action\",\"@dataTestingId\"],[[28,[37,1],[\"back\"],null],\"gray\",\"l\",\"icon-angle-left\",[30,1,[\"isRunning\"]],[30,0,[\"backAction\"]],\"permits_contact_page_back_button\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"next\"],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@text\",\"@type\",\"@size\",\"@iconRight\",\"@disabled\",\"@action\",\"@loader\",\"@dataTestingId\"],[[28,[37,1],[\"submit\"],null],\"primary\",\"l\",[30,0,[\"iconRight\"]],[28,[37,3],[[30,2]],null],[30,0,[\"nextAction\"]],[30,3,[\"isRunning\"]],\"permits_contact_page_next_button\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@back\",\"@valid\",\"@next\"],false,[\"buttons/link\",\"t\",\"buttons/primary\",\"not\"]]",
    "moduleName": "shared/components/business-license/action-bar.hbs",
    "isStrictMode": false
  });
  let ActionBarComponent = _exports.default = (_class = class ActionBarComponent extends _component2.default {
    get iconRight() {
      return this.args.hideIcon ? '' : 'icon-angle-right';
    }
    backAction() {
      this.args.back.perform();
    }
    nextAction() {
      this.args.next.perform();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "backAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "backAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nextAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "nextAction"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ActionBarComponent);
});