define("shared/llc/llc-items-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATE_TAX_REGISTRATION_270 = _exports.STATE_TAX_REGISTRATION = _exports.PERMITS_CODES = _exports.OPERATING_AGREEMENT_CODE = _exports.OPERATING_AGREEMENT_59_CODE = _exports.LLC_TRUIC_FILING_ITEM = _exports.LLC_STATE_CODE = _exports.LLC_GENERIC_STATE_FEE_CODE = _exports.LLC_FREE_FILING_ITEM = _exports.LLC_FILING_ITEM = _exports.LICENSE_FILING_REGULATED_LVL_2 = _exports.LICENSE_FILING_REGULATED_LVL_1 = _exports.LICENSE_FILING_NON_REGULATED = _exports.INITIAL_REPORT = _exports.EXPEDITED_FILING_CODE = _exports.EIN_STANDALONE_CODE = _exports.EIN_STANDALONE_119_CODE = _exports.EIN_ITEM_CODE = _exports.EIN_ITEM_119_CODE = _exports.EIN_FREE_ITEM_CODE = _exports.BUSINESS_LICENSE_CODE = _exports.BUSINESS_LICENSE_119_CODE = _exports.BUSINESS_DOCUMENTS_FREE_CODE = _exports.BUSINESS_DOCUMENTS_CODE = _exports.BUSINESS_DOCUMENTS_119_CODE = _exports.BUDGET_PLAN_ITEM = _exports.BANKING_RESOLUTION_ITEM = void 0;
  const EIN_ITEM_CODE = _exports.EIN_ITEM_CODE = 'us_ein';
  const EIN_ITEM_119_CODE = _exports.EIN_ITEM_119_CODE = 'us_ein_119';
  const EIN_FREE_ITEM_CODE = _exports.EIN_FREE_ITEM_CODE = 'us_ein_free';
  const OPERATING_AGREEMENT_CODE = _exports.OPERATING_AGREEMENT_CODE = 'llc_operating_agreement';
  const OPERATING_AGREEMENT_59_CODE = _exports.OPERATING_AGREEMENT_59_CODE = 'llc_operating_agreement_59';
  const EXPEDITED_FILING_CODE = _exports.EXPEDITED_FILING_CODE = 'llc_expedited_filing';
  const LLC_FILING_ITEM = _exports.LLC_FILING_ITEM = 'llc_filing';
  const LLC_FREE_FILING_ITEM = _exports.LLC_FREE_FILING_ITEM = 'llc_filing_free';
  const LLC_TRUIC_FILING_ITEM = _exports.LLC_TRUIC_FILING_ITEM = 'llc_filing_truic';
  const LLC_STATE_CODE = _exports.LLC_STATE_CODE = 'state_filing';
  const LLC_GENERIC_STATE_FEE_CODE = _exports.LLC_GENERIC_STATE_FEE_CODE = 'llc_state_fee';
  const EIN_STANDALONE_CODE = _exports.EIN_STANDALONE_CODE = 'ein_standalone';
  const EIN_STANDALONE_119_CODE = _exports.EIN_STANDALONE_119_CODE = 'ein_standalone_119';
  const BUSINESS_LICENSE_CODE = _exports.BUSINESS_LICENSE_CODE = 'business_license_report';
  const BUDGET_PLAN_ITEM = _exports.BUDGET_PLAN_ITEM = 'budget_plan';
  const BANKING_RESOLUTION_ITEM = _exports.BANKING_RESOLUTION_ITEM = 'banking_resolution';
  const BUSINESS_DOCUMENTS_CODE = _exports.BUSINESS_DOCUMENTS_CODE = 'business_documents_12';
  const BUSINESS_DOCUMENTS_119_CODE = _exports.BUSINESS_DOCUMENTS_119_CODE = 'business_documents_12_119';
  const BUSINESS_DOCUMENTS_FREE_CODE = _exports.BUSINESS_DOCUMENTS_FREE_CODE = 'business_documents_free';
  const BUSINESS_LICENSE_119_CODE = _exports.BUSINESS_LICENSE_119_CODE = 'business_license_report_119';
  const STATE_TAX_REGISTRATION = _exports.STATE_TAX_REGISTRATION = 'state_tax_registration';
  const STATE_TAX_REGISTRATION_270 = _exports.STATE_TAX_REGISTRATION_270 = 'state_tax_registration_270';
  const LICENSE_FILING_NON_REGULATED = _exports.LICENSE_FILING_NON_REGULATED = 'license_filing_non_regulated';
  const LICENSE_FILING_REGULATED_LVL_1 = _exports.LICENSE_FILING_REGULATED_LVL_1 = 'license_filing_regulated_lvl_1';
  const LICENSE_FILING_REGULATED_LVL_2 = _exports.LICENSE_FILING_REGULATED_LVL_2 = 'license_filing_regulated_lvl_2';
  const INITIAL_REPORT = _exports.INITIAL_REPORT = 'initial_report_state_fee';
  var _default = _exports.default = {
    EIN_ITEM_CODE,
    OPERATING_AGREEMENT_CODE,
    OPERATING_AGREEMENT_59_CODE,
    EXPEDITED_FILING_CODE,
    LLC_FILING_ITEM,
    LLC_FREE_FILING_ITEM,
    LLC_STATE_CODE,
    LLC_TRUIC_FILING_ITEM,
    EIN_STANDALONE_CODE,
    BUSINESS_LICENSE_CODE,
    BUDGET_PLAN_ITEM,
    BANKING_RESOLUTION_ITEM,
    BUSINESS_DOCUMENTS_CODE,
    BUSINESS_DOCUMENTS_119_CODE,
    BUSINESS_LICENSE_119_CODE,
    STATE_TAX_REGISTRATION,
    STATE_TAX_REGISTRATION_270,
    LICENSE_FILING_NON_REGULATED,
    LICENSE_FILING_REGULATED_LVL_1,
    LICENSE_FILING_REGULATED_LVL_2,
    INITIAL_REPORT
  };
  const PERMITS_CODES = _exports.PERMITS_CODES = [BUSINESS_LICENSE_CODE, BUSINESS_LICENSE_119_CODE, STATE_TAX_REGISTRATION, STATE_TAX_REGISTRATION_270, LICENSE_FILING_NON_REGULATED, LICENSE_FILING_REGULATED_LVL_1, LICENSE_FILING_REGULATED_LVL_2];
});