define("shared/lottie-player/lottie", ["exports", "lodash", "@ember/runloop", "@ember/utils"], function (_exports, _lodash, _runloop, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const defaultOptions = {
    id: '1',
    style: '',
    loop: false,
    fadeIn: true,
    fadeOut: true,
    className: '',
    autoplay: true,
    querySelector: '',
    imagesLoop: {
      loop: false,
      timeout: 15
    }
  };
  const setFadeIn = lottieElm => {
    lottieElm.classList.add('cp-lottie-element-fade-in');
  };
  const setFadeOut = lottieElm => {
    lottieElm.classList.add('cp-lottie-element-fade-out');
  };
  const createLottieElement = (jsonFile, options) => {
    (0, _runloop.next)(() => {
      const lottieElm = document.createElement('lottie-player');
      lottieElm.id = `lottie-player-${options.id}`;
      lottieElm.src = jsonFile;
      lottieElm.style = options.style;
      lottieElm.className = options.className;
      lottieElm.autoplay = options.autoplay;
      lottieElm.loop = options.loop;
      if (options.fadeIn) setFadeIn(lottieElm);
      let container = document.body;
      if ((0, _utils.isPresent)(options.querySelector)) {
        container = document.querySelector(options.querySelector);
      }
      const lastLottieElm = document.getElementById(`lottie-player-${options.id}`);
      if ((0, _utils.isPresent)(lastLottieElm)) lastLottieElm.parentNode.removeChild(lastLottieElm);
      if ((0, _utils.isPresent)(container)) container.appendChild(lottieElm);
    });
  };
  const removeLottieElement = id => {
    const lottieElm = document.getElementById(`lottie-player-${id}`);
    if ((0, _utils.isPresent)(lottieElm)) lottieElm.parentNode.removeChild(lottieElm);
  };
  const hideLottieElement = options => {
    const {
      id,
      fadeOut
    } = options;
    const lottieElm = document.getElementById(`lottie-player-${id}`);
    if (fadeOut) setFadeOut(lottieElm);
  };
  var _default = _exports.default = new class {
    constructor() {
      _defineProperty(this, "interval", null);
      _defineProperty(this, "currentTimer", 0);
      _defineProperty(this, "mergedOptions", {});
    }
    getCurrentImage(files, currentFile) {
      const index = files.indexOf(currentFile);
      if (index === files.length - 1) return files[0];
      return files[index + 1];
    }
    setRepeatImage(currentImage, files) {
      this.interval = setInterval(() => {
        this.currentTimer += 1;
        const {
          timeout,
          loop
        } = this.mergedOptions.imagesLoop;
        if (this.currentTimer < timeout && this.currentTimer % (timeout / files.length) === 0) {
          hideLottieElement(this.mergedOptions);
          currentImage = this.getCurrentImage(files, currentImage);
          createLottieElement(currentImage, this.mergedOptions);
        }
        if (this.currentTimer >= timeout) {
          if (loop) {
            this.currentTimer = -1;
          } else {
            this.currentTimer += 1;
            this.stop();
          }
        }
      }, 1000);
    }
    play(jsonFiles, options) {
      this.mergedOptions = (0, _lodash.merge)(defaultOptions, options);
      const files = Array.isArray(jsonFiles) ? jsonFiles : [jsonFiles];
      this.currentTimer = 0;
      let currentImage = files[0];
      createLottieElement(currentImage, this.mergedOptions);
      this.setRepeatImage(currentImage, files);
    }
    stop() {
      if ((0, _utils.isPresent)(this.interval)) {
        clearInterval(this.interval);
      }
      removeLottieElement(this.mergedOptions.id);
    }
  }();
});