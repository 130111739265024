define("shared/components/info-cards/vertical", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cp-info-cards-vertical flex flex-row lg:flex-col bg-bp-secondary-main border border-transparent transition duration-200 rounded-md shadow-bp-md items-center p-1 mx-4 md:mx-8 lg:mx-3 my-2 h-29 lg:h-72 lg:w-63">
    <img src={{@imageSrc}} alt={{@title}} class="w-25 lg:w-37 h-25 lg:h-37">
    <div class="flex flex-row items-center w-full">
      <div class="flex flex-col mx-4 lg:items-center w-full">
        <div class="text-bp-text-icons-main leading-6 lg:text-center font-gazpacho">{{@title}}</div>
        {{#if @description}}
          <div class="text-bp-text-icons-subdued text-sm leading-5 lg:text-center">{{@description}}</div>
        {{/if}}
      </div>
    </div>
  </div>
  
  */
  {
    "id": "KjHzqKQs",
    "block": "[[[10,0],[14,0,\"cp-info-cards-vertical flex flex-row lg:flex-col bg-bp-secondary-main border border-transparent transition duration-200 rounded-md shadow-bp-md items-center p-1 mx-4 md:mx-8 lg:mx-3 my-2 h-29 lg:h-72 lg:w-63\"],[12],[1,\"\\n  \"],[10,\"img\"],[15,\"src\",[30,1]],[15,\"alt\",[30,2]],[14,0,\"w-25 lg:w-37 h-25 lg:h-37\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-row items-center w-full\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-col mx-4 lg:items-center w-full\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"text-bp-text-icons-main leading-6 lg:text-center font-gazpacho\"],[12],[1,[30,2]],[13],[1,\"\\n\"],[41,[30,3],[[[1,\"        \"],[10,0],[14,0,\"text-bp-text-icons-subdued text-sm leading-5 lg:text-center\"],[12],[1,[30,3]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@imageSrc\",\"@title\",\"@description\"],false,[\"if\"]]",
    "moduleName": "shared/components/info-cards/vertical.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});