define("shared/print-store/apparel-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    type: 'tshirt',
    gender: 'men',
    layout: 'center-layout',
    color: 'white'
  }, {
    type: 'tshirt',
    gender: 'women',
    layout: 'center-layout',
    color: 'white'
  }, {
    type: 'tshirt',
    gender: 'men',
    layout: 'center-layout',
    color: 'ash'
  }, {
    type: 'tshirt',
    gender: 'women',
    layout: 'center-layout',
    color: 'ash'
  }, {
    type: 'tshirt',
    gender: 'men',
    layout: 'center-layout',
    color: 'black'
  }, {
    type: 'tshirt',
    gender: 'women',
    layout: 'center-layout',
    color: 'black'
  }, {
    type: 'tshirt',
    gender: 'men',
    layout: 'center-layout',
    color: 'blue'
  }, {
    type: 'tshirt',
    gender: 'women',
    layout: 'center-layout',
    color: 'blue'
  }, {
    type: 'tshirt',
    gender: 'men',
    layout: 'center-layout',
    color: 'grey'
  }, {
    type: 'tshirt',
    gender: 'women',
    layout: 'center-layout',
    color: 'grey'
  }, {
    type: 'tshirt',
    gender: 'men',
    layout: 'center-layout',
    color: 'yellow'
  }, {
    type: 'tshirt',
    gender: 'women',
    layout: 'center-layout',
    color: 'yellow'
  }, {
    type: 'tshirt',
    gender: 'men',
    layout: 'center-layout',
    color: 'green'
  }, {
    type: 'tshirt',
    gender: 'women',
    layout: 'center-layout',
    color: 'green'
  }, {
    type: 'tshirt',
    gender: 'men',
    layout: 'center-layout',
    color: 'red'
  }, {
    type: 'tshirt',
    gender: 'women',
    layout: 'center-layout',
    color: 'red'
  }, {
    type: 'tshirt',
    gender: 'women',
    layout: 'side-layout',
    color: 'white'
  }, {
    type: 'tshirt',
    gender: 'men',
    layout: 'side-layout',
    color: 'white'
  }, {
    type: 'tshirt',
    gender: 'men',
    layout: 'side-layout',
    color: 'ash'
  }, {
    type: 'tshirt',
    gender: 'women',
    layout: 'side-layout',
    color: 'ash'
  }, {
    type: 'tshirt',
    gender: 'men',
    layout: 'side-layout',
    color: 'black'
  }, {
    type: 'tshirt',
    gender: 'women',
    layout: 'side-layout',
    color: 'black'
  }, {
    type: 'tshirt',
    gender: 'men',
    layout: 'side-layout',
    color: 'blue'
  }, {
    type: 'tshirt',
    gender: 'women',
    layout: 'side-layout',
    color: 'blue'
  }, {
    type: 'tshirt',
    gender: 'men',
    layout: 'side-layout',
    color: 'grey'
  }, {
    type: 'tshirt',
    gender: 'women',
    layout: 'side-layout',
    color: 'grey'
  }, {
    type: 'tshirt',
    gender: 'men',
    layout: 'side-layout',
    color: 'yellow'
  }, {
    type: 'tshirt',
    gender: 'women',
    layout: 'side-layout',
    color: 'yellow'
  }, {
    type: 'tshirt',
    gender: 'men',
    layout: 'side-layout',
    color: 'green'
  }, {
    type: 'tshirt',
    gender: 'women',
    layout: 'side-layout',
    color: 'green'
  }, {
    type: 'tshirt',
    gender: 'men',
    layout: 'side-layout',
    color: 'red'
  }, {
    type: 'tshirt',
    gender: 'women',
    layout: 'side-layout',
    color: 'red'
  }];
});