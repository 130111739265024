define("shared/components/form-elements/textarea/textarea", ["exports", "@ember/component", "@glimmer/component", "@ember/utils", "@ember/template-factory"], function (_exports, _component, _component2, _utils, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex flex-col">
    {{#if @title}}
      <label for={{@id}}><span class="text-bp-text-icons-main font-bold">{{@title}}</span></label>
    {{/if}}
    <Textarea id={{@id}} maxlength={{@maxlength}} @value={{@value}} placeholder={{@placeholder}} class="h-20 p-2 resize-none border border-solid border-bp-border-main rounded-md text-bp-text-icons-subdued shadow-none hover:border-bp-border-hover focus:border-bp-border-action focus:shadow-none focus:outline-none" />
    {{#if @maxlength}}
      <div class="self-end text-sm relative md:mt-2">
        <span class="text-bp-text-icons-subdued">{{this.characterCount}}/</span>
        <span class="text-bp-text-icons-subdued">{{@maxlength}}</span>
      </div>
    {{/if}}
  </div>
  
  */
  {
    "id": "SqU91pxC",
    "block": "[[[10,0],[14,0,\"flex flex-col\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[10,\"label\"],[15,\"for\",[30,2]],[12],[10,1],[14,0,\"text-bp-text-icons-main font-bold\"],[12],[1,[30,1]],[13],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[8,[39,1],[[16,1,[30,2]],[16,\"maxlength\",[30,3]],[16,\"placeholder\",[30,4]],[24,0,\"h-20 p-2 resize-none border border-solid border-bp-border-main rounded-md text-bp-text-icons-subdued shadow-none hover:border-bp-border-hover focus:border-bp-border-action focus:shadow-none focus:outline-none\"]],[[\"@value\"],[[30,5]]],null],[1,\"\"],[41,[30,3],[[[1,\"    \"],[10,0],[14,0,\"self-end text-sm relative md:mt-2\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"text-bp-text-icons-subdued\"],[12],[1,[30,0,[\"characterCount\"]]],[1,\"/\"],[13],[1,\"\\n      \"],[10,1],[14,0,\"text-bp-text-icons-subdued\"],[12],[1,[30,3]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@title\",\"@id\",\"@maxlength\",\"@placeholder\",\"@value\"],false,[\"if\",\"textarea\"]]",
    "moduleName": "shared/components/form-elements/textarea/textarea.hbs",
    "isStrictMode": false
  });
  class Textarea extends _component2.default {
    get characterCount() {
      return (0, _utils.isBlank)(this.args.value) ? 0 : this.args.value.length;
    }
  }
  _exports.default = Textarea;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Textarea);
});