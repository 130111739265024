define("shared/components/info-cards/testimonial", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cp-info-cards-vertical flex flex-col bg-bp-secondary-main border border-transparent transition duration-200 rounded-md shadow-bp-md justify-center items-center h-64 md:h-80 w-72 md:w-100 my-8 md:my-4">
    <img src={{@imageSrc}} alt={{@imageAlt}} class="h-16 md:h-22">
    <div class="text-bp-text-icons-main text-center text-xl font-bold leading-7 py-1 md:py-4">{{@businessName}}</div>
    <div class="flex flex-row items-center w-full">
      <div class="flex flex-col mx-2 md:mx-4 lg:items-center w-full">
        <div class="text-bp-text-icons-subdued text-center font-normal leading-5 md:px-2">{{@paragraph}}</div>
        <div class="text-bp-text-icons-main text-center font-bold leading-6 md:mt-3 mb-1">{{@name}}</div>
        <div class="flex justify-center">
          {{#each (range 0 @starsNumber)}}
            <i class="icon-star text-custom-star"></i>
          {{/each}}
        </div>
      </div>
    </div>
  </div>
  
  */
  {
    "id": "QEkU0uFh",
    "block": "[[[10,0],[14,0,\"cp-info-cards-vertical flex flex-col bg-bp-secondary-main border border-transparent transition duration-200 rounded-md shadow-bp-md justify-center items-center h-64 md:h-80 w-72 md:w-100 my-8 md:my-4\"],[12],[1,\"\\n  \"],[10,\"img\"],[15,\"src\",[30,1]],[15,\"alt\",[30,2]],[14,0,\"h-16 md:h-22\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"text-bp-text-icons-main text-center text-xl font-bold leading-7 py-1 md:py-4\"],[12],[1,[30,3]],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-row items-center w-full\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-col mx-2 md:mx-4 lg:items-center w-full\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"text-bp-text-icons-subdued text-center font-normal leading-5 md:px-2\"],[12],[1,[30,4]],[13],[1,\"\\n      \"],[10,0],[14,0,\"text-bp-text-icons-main text-center font-bold leading-6 md:mt-3 mb-1\"],[12],[1,[30,5]],[13],[1,\"\\n      \"],[10,0],[14,0,\"flex justify-center\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[28,[37,2],[0,[30,6]],null]],null]],null],null,[[[1,\"          \"],[10,\"i\"],[14,0,\"icon-star text-custom-star\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@imageSrc\",\"@imageAlt\",\"@businessName\",\"@paragraph\",\"@name\",\"@starsNumber\"],false,[\"each\",\"-track-array\",\"range\"]]",
    "moduleName": "shared/components/info-cards/testimonial.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});