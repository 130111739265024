define("shared/llc/state-restricted-words", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    general: ['LLC', 'L.L.C.', 'Limited Liability Company', 'Limited Liability Co.', 'Ltd. Liability Company', 'LC', 'L.C.', 'Limited Co.', 'Limited Company', 'Ltd. Company', 'Ltd. Liability Co.', 'Limited', 'Ltd', 'Limited Liability Co', 'Ltd Liability Company', 'Ltd Liability Co', 'Ltd Company', 'Ltd Liability Co.', 'Limited Co', 'Ltd Co.', 'Ltd Co', 'L.L.C', 'Limited Liability Law Company', 'LLLC', 'L.L.L.C.', 'L. L. C.', 'Ltd. Co.', 'L C', 'L L C', 'L. C.', 'Limited-Liability Company', 'L3C', 'Corporation', 'Corp', 'Incorporated', 'Inc', 'Other', 'C-corp', 'S-Corp', 'Partnership', 'None/sole proprietorship'],
    ak: [],
    al: ['Architect', 'Engineer', 'Insurance', 'Bank', 'Trust'],
    az: ['Banker', 'Banking', 'Banc', 'Banco', 'Banque', 'Credit Union', 'Deposit', 'Savings Association', 'Building Association', 'Savings and Loan Association', 'Building and Loan Association', 'Thrift', 'Trust', 'Trust Company', 'Association', 'Assoc'],
    ca: ['.', '/', ',', '\\', ';', '-', ':', '_', '\'', 'u02dc', 'u2019', '(', ')', '"', '[', ']', '?', '<', '>', '!', '{', '}', 'Agency', 'Commission', 'Department', 'Bureau', 'Division', 'Municipal', 'Board', 'insurance', 'reinsurance', 'assurance', 'surety', 'agent', 'services', 'broker', 'Professional Corporation', 'Prof Corp', 'P.C.', 'PC'],
    ga: ['insurance', 'assurance', 'surety', 'fidelity', 'reinsurance', 'reassurance', 'indemnity', 'bank', 'banc', 'banque', 'banker', 'banking company', 'banking house', 'bancorp', 'bankruptcy', 'bancshares', 'bankshares', 'credit union', 'savings & loan', 'trust', 'trust company', 'trusted', 'trusting', 'college', 'university'],
    hi: ['Honolulu', 'Olomana', 'Wailuku', 'Maui', 'Kailua', 'Oahu', 'Diamond Head', 'Aloha Tower', 'Pearl Harbor', 'North Kohala', 'King Street', 'Beretania Street', 'San Francisco', 'Paris', 'Belgium', 'Hawaii Foreign Trade Zone', 'financial institution', 'bank', 'banker', 'banking', 'banc', 'savings bank', 'savings and loan', 'savings association', 'financial services loan company', 'credit union trust company', 'intrapacific bank', 'international banking corporation', 'trust', 'Olympic', 'Olympiad', 'Citius Altius Fortius', 'Postal Service', 'Post Office', 'insurance', 'certified public accountant', 'public accountant', 'CPA', 'PA', 'escrow', 'escrow depository'],
    ks: ['Association', 'Chartered', 'Church', 'Club', 'College', 'Company', 'Co', 'Foundation', 'Fund', 'Institute', 'LLC', 'Limited Partnership', 'LP', 'Limited Liability Partnership', 'LLP', 'Professional Association', 'PA', 'PBC', 'Registered Limited Liability Partnership', 'RLLP', 'Society', 'SPBLLC', 'Syndicate', 'Union', 'University'],
    la: ['engineer', 'engineering', 'surveyor', 'surveying', 'insurance', 'bank', 'banker', 'banking', 'savings', 'safe deposit', 'trust', 'trustee', 'building and loan', 'homestead', 'credit union', 'architect', 'architectural', 'architecture'],
    mn: [],
    mo: ['limited partnership', 'limited liability partnership', 'limited liability limited partnership'],
    ms: ['bank', 'banker', 'bankers', 'banking', 'trust company', 'insurance', 'trust', 'limited partnership'],
    nc: ['Bank', 'Banker and Banking', 'Mutual', 'trust', 'Realtor', 'Architect', 'Architecture', 'Architectural', 'Cooperative', 'Co-op', 'engineer', 'Engineering', 'Insurance', 'Pharmacy', 'Prescription Drug', 'Drug', 'Prescription', 'Rx', 'Apothecary', 'Surveyor', 'Survey', 'Surveying', 'Wholesale'],
    nd: ['limited partnership', 'limited liability partnership', 'limited liability limited partnership'],
    nh: ['farmers\' market'],
    nj: ['BANK', 'MAIL', 'BANKER', 'OLYMPIA', 'BANKING', 'OLYMPIAD', 'BLIND', 'OLYMPIAN', 'CEMETERY', 'OLYMPIC', 'CITIUS ALTIUS FORTIUS', 'OLYMPUS', 'CON-CON', 'REALTOR, DBA', 'SAVINGS, E-COM', 'TRUST, EXPRESS MAIL', 'UNITED STATES', 'OLYMPIC COMMITTEE', 'HANDICAPPED', 'URBAN RENEWAL', 'INSURANCE', 'USPS', 'LITTLE LEAGUE', 'ZIP CODE'],
    nv: ['accountant', 'accounting', 'accountancy', 'auditor', 'auditing'],
    ny: ['board of trade', 'chamber of commerce', 'community renewal', 'cooperative', 'cooperation', 'redevelop', 'state police', 'state trooper', 'tenant relocation', 'URBAN DEVELOP', 'urban development', 'urban relocation'],
    oh: ['Government Affiliation', 'Banks and Trusts', 'Insurance Agencies', 'Cooperatives', 'Legal Professional Associations', 'Benefit Corporation'],
    pa: ['Barbers', 'Cosmetology', 'Engineers', 'Medicine Board', 'Nursing', 'Osteopathic Medicine', 'Pharmacy', 'Real Estate', 'Social Workers'],
    sd: ['Trust'],
    tn: ['mortgage,', 'bank', 'banks', 'credit union', 'trust'],
    tx: ['LOTTO', 'LOTTERY', 'veteran', 'legion', 'foreign', 'Spanish', 'disabled', 'war', 'world war'],
    ut: ['association', 'limited partnership', 'L.P.', 'Olympic', 'Olympiad', 'Citius Altius Fortius', '911'],
    wa: ['Cooperative', 'LP', 'L.P.', 'LLP', 'L.L.P.', 'LLLP', 'L.L.L.P'],
    wy: ['Academy', 'College', 'Educate', 'Education', 'Educational', 'Institute', 'Institution', 'School', 'University', 'Bank', 'Banker', 'Banc', 'Bancorp', 'Bancorporation', 'Banque', 'Bankers', 'Banco', 'Banca', 'Trust']
  };
});