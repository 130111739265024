define("shared/components/select-cards/wide", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cp-select-cards-wide flex flex-row bg-bp-secondary-main p-4 md:p-8 border hover:border-black transition duration-200 rounded-md shadow-bp-md hover:shadow-bp-lg items-center cursor-pointer md:w-180 md:h-33 h-auto {{if @selected 'border-2 border-bp-primary-main hover:border-bp-primary-main' 'border-transparent'}}"
       data-testing-id={{@dataTestingId}} role="button" {{on 'click' @clickAction}}>
    <img src={{@imageSrc}} alt={{@imageAlt}} class="h-25 w-25">
    <div class="flex flex-row items-center w-full">
      <div class="flex flex-col md:ml-2 {{unless @other 'w-full'}}">
        <span class="text-bp-text-icons font-bold">{{@title}}</span>
        {{#if (and @description (not @other))}}
          <span class="text-bp-text-icons-subdued text-sm">{{@description}}</span>
        {{/if}}
      </div>
      {{#if @other}}
        <div class="w-full">
          <Input @type="text"
                 @value={{@otherText}}
                 data-testing-id={{@textDataTestingId}}
                 maxlength="30"
                 placeholder={{t 'wizard.survey.categories.other_placeholder'}}
                 class="inline-flex w-32 md:w-50 px-0 ml-2 mb-0 shadow-none border-b border-t-0 border-l-0 border-r-0"/>
        </div>
      {{/if}}
      <i class="icon-check-circle text-sm text-bp-primary-main transition duration-200 {{if @selected 'opacity-1' 'opacity-0'}}"></i>
    </div>
  </div>
  
  */
  {
    "id": "HMmWget1",
    "block": "[[[11,0],[16,0,[29,[\"cp-select-cards-wide flex flex-row bg-bp-secondary-main p-4 md:p-8 border hover:border-black transition duration-200 rounded-md shadow-bp-md hover:shadow-bp-lg items-center cursor-pointer md:w-180 md:h-33 h-auto \",[52,[30,1],\"border-2 border-bp-primary-main hover:border-bp-primary-main\",\"border-transparent\"]]]],[16,\"data-testing-id\",[30,2]],[24,\"role\",\"button\"],[4,[38,1],[\"click\",[30,3]],null],[12],[1,\"\\n  \"],[10,\"img\"],[15,\"src\",[30,4]],[15,\"alt\",[30,5]],[14,0,\"h-25 w-25\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-row items-center w-full\"],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"flex flex-col md:ml-2 \",[52,[51,[30,6]],\"w-full\"]]]],[12],[1,\"\\n      \"],[10,1],[14,0,\"text-bp-text-icons font-bold\"],[12],[1,[30,7]],[13],[1,\"\\n\"],[41,[28,[37,3],[[30,8],[28,[37,4],[[30,6]],null]],null],[[[1,\"        \"],[10,1],[14,0,\"text-bp-text-icons-subdued text-sm\"],[12],[1,[30,8]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"],[41,[30,6],[[[1,\"      \"],[10,0],[14,0,\"w-full\"],[12],[1,\"\\n        \"],[8,[39,5],[[16,\"data-testing-id\",[30,9]],[24,\"maxlength\",\"30\"],[16,\"placeholder\",[28,[37,6],[\"wizard.survey.categories.other_placeholder\"],null]],[24,0,\"inline-flex w-32 md:w-50 px-0 ml-2 mb-0 shadow-none border-b border-t-0 border-l-0 border-r-0\"]],[[\"@type\",\"@value\"],[\"text\",[30,10]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,\"i\"],[15,0,[29,[\"icon-check-circle text-sm text-bp-primary-main transition duration-200 \",[52,[30,1],\"opacity-1\",\"opacity-0\"]]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@selected\",\"@dataTestingId\",\"@clickAction\",\"@imageSrc\",\"@imageAlt\",\"@other\",\"@title\",\"@description\",\"@textDataTestingId\",\"@otherText\"],false,[\"if\",\"on\",\"unless\",\"and\",\"not\",\"input\",\"t\"]]",
    "moduleName": "shared/components/select-cards/wide.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});