define("shared/llc/naics-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    327910: 'Abrasive Product Manufacturing',
    325520: 'Adhesive Manufacturing',
    924110: 'Administration of Air and Water Resource and Solid Waste Management Programs',
    924120: 'Administration of Conservation Programs',
    923110: 'Administration of Education Programs',
    926110: 'Administration of General Economic Programs',
    925110: 'Administration of Housing Programs',
    923130: 'Administration of Human Resource Programs (except Education, Public Health, and Veterans\' Affairs Programs)',
    923120: 'Administration of Public Health Programs',
    925120: 'Administration of Urban Planning and Community and Rural Development',
    923140: 'Administration of Veterans\' Affairs',
    541611: 'Administrative Management and General Management Consulting Services',
    541810: 'Advertising Agencies',
    541870: 'Advertising Material Distribution Services',
    711410: 'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures',
    517122: 'Agents for Wireless Telecommunications Services',
    333912: 'Air and Gas Compressor Manufacturing',
    488111: 'Air Traffic Control',
    333415: 'Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing',
    336412: 'Aircraft Engine and Engine Parts Manufacturing',
    336411: 'Aircraft Manufacturing',
    713990: 'All Other Amusement and Recreation Industries',
    112990: 'All Other Animal Production',
    811198: 'All Other Automotive Repair and Maintenance',
    325199: 'All Other Basic Organic Chemical Manufacturing',
    561499: 'All Other Business Support Services',
    532289: 'All Other Consumer Goods Rental',
    322299: 'All Other Converted Paper Product Manufacturing',
    455219: 'All Other General Merchandise Retailers',
    111199: 'All Other Grain Farming',
    456199: 'All Other Health and Personal Care Retailers',
    449129: 'All Other Home Furnishings Retailers',
    333248: 'All Other Industrial Machinery Manufacturing',
    524298: 'All Other Insurance Related Activities',
    541199: 'All Other Legal Services',
    621999: 'All Other Miscellaneous Ambulatory Health Care Services',
    325998: 'All Other Miscellaneous Chemical Product and Preparation Manufacturing',
    111998: 'All Other Miscellaneous Crop Farming',
    335999: 'All Other Miscellaneous Electrical Equipment and Component Manufacturing',
    332999: 'All Other Miscellaneous Fabricated Metal Product Manufacturing',
    311999: 'All Other Miscellaneous Food Manufacturing',
    333998: 'All Other Miscellaneous General Purpose Machinery Manufacturing',
    339999: 'All Other Miscellaneous Manufacturing',
    327999: 'All Other Miscellaneous Nonmetallic Mineral Product Manufacturing',
    459999: 'All Other Miscellaneous Retailers',
    611699: 'All Other Miscellaneous Schools and Instruction',
    314999: 'All Other Miscellaneous Textile Product Mills',
    562998: 'All Other Miscellaneous Waste Management Services',
    321999: 'All Other Miscellaneous Wood Product Manufacturing',
    621498: 'All Other Outpatient Care Centers',
    812990: 'All Other Personal Services',
    324199: 'All Other Petroleum and Coal Products Manufacturing',
    486990: 'All Other Pipeline Transportation',
    326199: 'All Other Plastics Product Manufacturing',
    541990: 'All Other Professional, Scientific, and Technical Services',
    513199: 'All Other Publishers',
    326299: 'All Other Rubber Product Manufacturing',
    445298: 'All Other Specialty Food Retailers',
    238990: 'All Other Specialty Trade Contractors',
    488999: 'All Other Support Activities for Transportation',
    561990: 'All Other Support Services',
    517810: 'All Other Telecommunications',
    485999: 'All Other Transit and Ground Passenger Transportation',
    336999: 'All Other Transportation Equipment Manufacturing',
    561599: 'All Other Travel Arrangement and Reservation Services',
    721199: 'All Other Traveler Accommodation',
    331313: 'Alumina Refining and Primary Aluminum Production',
    331524: 'Aluminum Foundries (except Die-Casting)',
    331315: 'Aluminum Sheet, Plate, and Foil Manufacturing',
    621910: 'Ambulance Services',
    921150: 'American Indian and Alaska Native Tribal Governments',
    332993: 'Ammunition (except Small Arms) Manufacturing',
    713110: 'Amusement and Theme Parks',
    713120: 'Amusement Arcades',
    334516: 'Analytical Laboratory Instrument Manufacturing',
    311611: 'Animal (except Poultry) Slaughtering',
    112910: 'Apiculture',
    315990: 'Apparel Accessories and Other Apparel Manufacturing',
    315120: 'Apparel Knitting Mills',
    111331: 'Apple Orchards',
    811412: 'Appliance Repair and Maintenance',
    611513: 'Apprenticeship Training',
    541310: 'Architectural Services',
    561613: 'Armored Car Services',
    459920: 'Art Dealers',
    325220: 'Artificial and Synthetic Fibers and Filaments Manufacturing',
    324121: 'Asphalt Paving Mixture and Block Manufacturing',
    324122: 'Asphalt Shingle and Coating Materials Manufacturing',
    623312: 'Assisted Living Facilities for the Elderly',
    334310: 'Audio and Video Equipment Manufacturing',
    334512: 'Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use',
    336110: 'Automobile and Light Duty Motor Vehicle Manufacturing',
    423110: 'Automobile and Other Motor Vehicle Merchant Wholesalers',
    611692: 'Automobile Driving Schools',
    811121: 'Automotive Body, Paint, and Interior Repair and Maintenance',
    811122: 'Automotive Glass Replacement Shops',
    811191: 'Automotive Oil Change and Lubrication Shops',
    441330: 'Automotive Parts and Accessories Retailers',
    445291: 'Baked Goods Retailers',
    332991: 'Ball and Roller Bearing Manufacturing',
    812111: 'Barber Shops',
    334412: 'Bare Printed Circuit Board Manufacturing',
    335910: 'Battery Manufacturing',
    812112: 'Beauty Salons',
    721191: 'Bed-and-Breakfast Inns',
    112111: 'Beef Cattle Ranching and Farming',
    424810: 'Beer and Ale Merchant Wholesalers',
    445320: 'Beer, Wine, and Liquor Retailers',
    311313: 'Beet Sugar Manufacturing',
    111334: 'Berry (except Strawberry) Farming',
    325414: 'Biological Product (except Diagnostic) Manufacturing',
    221117: 'Biomass Electric Power Generation',
    337920: 'Blind and Shade Manufacturing',
    621991: 'Blood and Organ Banks',
    336612: 'Boat Building',
    441222: 'Boat Dealers',
    332722: 'Bolt, Nut, Screw, Rivet, and Washer Manufacturing',
    513130: 'Book Publishers',
    459210: 'Book Retailers and News Dealers',
    424920: 'Book, Periodical, and Newspaper Merchant Wholesalers',
    323117: 'Books Printing',
    312112: 'Bottled Water Manufacturing',
    713950: 'Bowling Centers',
    311230: 'Breakfast Cereal Manufacturing',
    312120: 'Breweries',
    423320: 'Brick, Stone, and Related Construction Material Merchant Wholesalers',
    313210: 'Broadwoven Fabric Mills',
    112320: 'Broilers and Other Meat Type Chicken Production',
    339994: 'Broom, Brush, and Mop Manufacturing',
    541350: 'Building Inspection Services',
    339995: 'Burial Casket Manufacturing',
    485113: 'Bus and Other Motor Vehicle Transit Systems',
    611410: 'Business and Secretarial Schools',
    813910: 'Business Associations',
    722514: 'Cafeterias, Grill Buffets, and Buffets',
    311314: 'Cane Sugar Manufacturing',
    334416: 'Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing',
    811192: 'Car Washes',
    335991: 'Carbon and Graphite Product Manufacturing',
    314110: 'Carpet and Rug Mills',
    561740: 'Carpet and Upholstery Cleaning Services',
    721120: 'Casino Hotels',
    713210: 'Casinos (except Casino Hotels)',
    722320: 'Caterers',
    112112: 'Cattle Feedlots',
    327310: 'Cement Manufacturing',
    812220: 'Cemeteries and Crematories',
    485510: 'Charter Bus Industry',
    311513: 'Cheese Manufacturing',
    112310: 'Chicken Egg Production',
    624110: 'Child and Youth Services',
    624410: 'Child Care Services',
    311351: 'Chocolate and Confectionery Manufacturing from Cacao Beans',
    111320: 'Citrus (except Orange) Groves',
    813410: 'Civic and Social Organizations',
    524291: 'Claims Adjusting',
    327120: 'Clay Building Material and Refractories Manufacturing',
    424350: 'Clothing and Clothing Accessories Merchant Wholesalers',
    458110: 'Clothing and Clothing Accessories Retailers',
    423520: 'Coal and Other Mineral and Ore Merchant Wholesalers',
    483113: 'Coastal and Great Lakes Freight Transportation',
    483114: 'Coastal and Great Lakes Passenger Transportation',
    311920: 'Coffee and Tea Manufacturing',
    812310: 'Coin-Operated Laundries and Drycleaners',
    561440: 'Collection Agencies',
    611310: 'Colleges, Universities, and Professional Schools',
    532411: 'Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing',
    811310: 'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance',
    236220: 'Commercial and Institutional Building Construction',
    333310: 'Commercial and Service Industry Machinery Manufacturing',
    311812: 'Commercial Bakeries',
    522110: 'Commercial Banking',
    541922: 'Commercial Photography',
    323111: 'Commercial Printing (except Screen and Books)',
    323113: 'Commercial Screen Printing',
    335132: 'Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing',
    523160: 'Commodity Contracts Intermediation',
    624210: 'Community Food Services',
    485112: 'Commuter Rail Systems',
    325315: 'Compost Manufacturing',
    423430: 'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers',
    541513: 'Computer Facilities Management Services',
    334112: 'Computer Storage Device Manufacturing',
    541512: 'Computer Systems Design Services',
    334118: 'Computer Terminal and Other Computer Peripheral Equipment Manufacturing',
    611420: 'Computer Training',
    518210: 'Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services',
    327331: 'Concrete Block and Brick Manufacturing',
    327332: 'Concrete Pipe Manufacturing',
    445292: 'Confectionery and Nut Retailers',
    311352: 'Confectionery Manufacturing from Purchased Chocolate',
    424450: 'Confectionery Merchant Wholesalers',
    423810: 'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers',
    333120: 'Construction Machinery Manufacturing',
    212321: 'Construction Sand and Gravel Mining',
    532412: 'Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing',
    532210: 'Consumer Electronics and Appliances Rental',
    522291: 'Consumer Lending',
    623311: 'Continuing Care Retirement Communities',
    445131: 'Convenience Retailers',
    561920: 'Convention and Trade Show Organizers',
    561591: 'Convention and Visitors Bureaus',
    333922: 'Conveyor and Conveying Equipment Manufacturing',
    311821: 'Cookie and Cracker Manufacturing',
    331420: 'Copper Rolling, Drawing, Extruding, and Alloying',
    212230: 'Copper, Nickel, Lead, and Zinc Mining',
    111150: 'Corn Farming',
    551114: 'Corporate, Subsidiary, and Regional Managing Offices',
    922140: 'Correctional Institutions',
    322211: 'Corrugated and Solid Fiber Box Manufacturing',
    456120: 'Cosmetics, Beauty Supplies, and Perfume Retailers',
    611511: 'Cosmetology and Barber Schools',
    111920: 'Cotton Farming',
    115111: 'Cotton Ginning',
    492110: 'Couriers and Express Delivery Services',
    561492: 'Court Reporting and Stenotype Services',
    922110: 'Courts',
    311512: 'Creamery Butter Manufacturing',
    561450: 'Credit Bureaus',
    522210: 'Credit Card Issuing',
    522130: 'Credit Unions',
    115113: 'Crop Harvesting, Primarily by Machine',
    211120: 'Crude Petroleum Extraction',
    212313: 'Crushed and Broken Granite Mining and Quarrying',
    212312: 'Crushed and Broken Limestone Mining and Quarrying',
    335931: 'Current-Carrying Wiring Device Manufacturing',
    314120: 'Curtain and Linen Mills',
    337212: 'Custom Architectural Woodwork and Millwork Manufacturing',
    325991: 'Custom Compounding of Purchased Resins',
    541511: 'Custom Computer Programming Services',
    332114: 'Custom Roll Forming',
    315210: 'Cut and Sew Apparel Contractors',
    315250: 'Cut and Sew Apparel Manufacturing (except Contractors)',
    321912: 'Cut Stock, Resawing Lumber, and Planing',
    327991: 'Cut Stone and Stone Product Manufacturing',
    333515: 'Cutting Tool and Machine Tool Accessory Manufacturing',
    325194: 'Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing',
    112120: 'Dairy Cattle and Milk Production',
    424430: 'Dairy Product (except Dried or Canned) Merchant Wholesalers',
    711120: 'Dance Companies',
    483111: 'Deep Sea Freight Transportation',
    483112: 'Deep Sea Passenger Transportation',
    339114: 'Dental Equipment and Supplies Manufacturing',
    339116: 'Dental Laboratories',
    455110: 'Department Stores',
    621512: 'Diagnostic Imaging Centers',
    812191: 'Diet and Weight Reducing Centers',
    212311: 'Dimension Stone Mining and Quarrying',
    524114: 'Direct Health and Medical Insurance Carriers',
    524113: 'Direct Life Insurance Carriers',
    541860: 'Direct Mail Advertising',
    524126: 'Direct Property and Casualty Insurance Carriers',
    524127: 'Direct Title Insurance Carriers',
    513140: 'Directory and Mailing List Publishers',
    312140: 'Distilleries',
    561410: 'Document Preparation Services',
    311111: 'Dog and Cat Food Manufacturing',
    339930: 'Doll, Toy, and Game Manufacturing',
    541340: 'Drafting Services',
    311423: 'Dried and Dehydrated Food Manufacturing',
    213111: 'Drilling Oil and Gas Wells',
    722410: 'Drinking Places (Alcoholic Beverages)',
    512132: 'Drive-In Motion Picture Theaters',
    424210: 'Drugs and Druggists\' Sundries Merchant Wholesalers',
    311824: 'Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour',
    111130: 'Dry Pea and Bean Farming',
    311514: 'Dry, Condensed, and Evaporated Dairy Product Manufacturing',
    812320: 'Drycleaning and Laundry Services (except Coin-Operated)',
    238310: 'Drywall and Insulation Contractors',
    112130: 'Dual-Purpose Cattle Ranching and Farming',
    611710: 'Educational Support Services',
    221121: 'Electric Bulk Power Transmission and Control',
    335139: 'Electric Lamp Bulb and Other Lighting Equipment Manufacturing',
    221122: 'Electric Power Distribution',
    423610: 'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers',
    238210: 'Electrical Contractors and Other Wiring Installation Contractors',
    334510: 'Electromedical and Electrotherapeutic Apparatus Manufacturing',
    811210: 'Electronic and Precision Equipment Repair and Maintenance',
    334111: 'Electronic Computer Manufacturing',
    334417: 'Electronic Connector Manufacturing',
    449210: 'Electronics and Appliance Retailers',
    332813: 'Electroplating, Plating, Polishing, Anodizing, and Coloring',
    611110: 'Elementary and Secondary Schools',
    333921: 'Elevator and Moving Stairway Manufacturing',
    624230: 'Emergency and Other Relief Services',
    561311: 'Employment Placement Agencies',
    321215: 'Engineered Wood Member Manufacturing',
    541330: 'Engineering Services',
    813312: 'Environment, Conservation and Wildlife Organizations',
    541620: 'Environmental Consulting Services',
    325193: 'Ethyl Alcohol Manufacturing',
    611691: 'Exam Preparation and Tutoring',
    921140: 'Executive and Legislative Offices, Combined',
    921110: 'Executive Offices',
    561312: 'Executive Search Services',
    325920: 'Explosives Manufacturing',
    561710: 'Exterminating and Pest Control Services',
    313320: 'Fabric Coating Mills',
    332996: 'Fabricated Pipe and Pipe Fitting Manufacturing',
    332312: 'Fabricated Structural Metal Manufacturing',
    561210: 'Facilities Support Services',
    621410: 'Family Planning Centers',
    423820: 'Farm and Garden Machinery and Equipment Merchant Wholesalers',
    115115: 'Farm Labor Contractors and Crew Leaders',
    333111: 'Farm Machinery and Equipment Manufacturing',
    115116: 'Farm Management Services',
    493130: 'Farm Product Warehousing and Storage',
    424910: 'Farm Supplies Merchant Wholesalers',
    339993: 'Fastener, Button, Needle, and Pin Manufacturing',
    311225: 'Fats and Oils Refining and Blending',
    325314: 'Fertilizer (Mixing Only) Manufacturing',
    335921: 'Fiber Optic Cable Manufacturing',
    313110: 'Fiber, Yarn, and Thread Mills',
    522320: 'Financial Transactions Processing, Reserve, and Clearinghouse Activities',
    611610: 'Fine Arts Schools',
    112511: 'Finfish Farming and Fish Hatcheries',
    114111: 'Finfish Fishing',
    238350: 'Finish Carpentry Contractors',
    922160: 'Fire Protection',
    424460: 'Fish and Seafood Merchant Wholesalers',
    445250: 'Fish and Seafood Retailers',
    713940: 'Fitness and Recreational Sports Centers',
    327211: 'Flat Glass Manufacturing',
    311930: 'Flavoring Syrup and Concentrate Manufacturing',
    611512: 'Flight Training',
    449121: 'Floor Covering Retailers',
    238330: 'Flooring Contractors',
    111422: 'Floriculture Production',
    459310: 'Florists',
    311211: 'Flour Milling',
    424930: 'Flower, Nursery Stock, and Florists\' Supplies Merchant Wholesalers',
    311511: 'Fluid Milk Manufacturing',
    333995: 'Fluid Power Cylinder and Actuator Manufacturing',
    333996: 'Fluid Power Pump and Motor Manufacturing',
    332912: 'Fluid Power Valve and Hose Fitting Manufacturing',
    322212: 'Folding Paperboard Box Manufacturing',
    456191: 'Food (Health) Supplement Retailers',
    333241: 'Food Product Machinery Manufacturing',
    722310: 'Food Service Contractors',
    811430: 'Footwear and Leather Goods Repair',
    316210: 'Footwear Manufacturing',
    424340: 'Footwear Merchant Wholesalers',
    113210: 'Forest Nurseries and Gathering of Forest Products',
    532281: 'Formal Wear and Costume Rental',
    221112: 'Fossil Fuel Electric Power Generation',
    238130: 'Framing Contractors',
    621493: 'Freestanding Ambulatory Surgical and Emergency Centers',
    488510: 'Freight Transportation Arrangement',
    424480: 'Fresh Fruit and Vegetable Merchant Wholesalers',
    311813: 'Frozen Cakes, Pies, and Other Pastries Manufacturing',
    311411: 'Frozen Fruit, Juice, and Vegetable Manufacturing',
    311412: 'Frozen Specialty Food Manufacturing',
    111336: 'Fruit and Tree Nut Combination Farming',
    311421: 'Fruit and Vegetable Canning',
    445230: 'Fruit and Vegetable Retailers',
    457210: 'Fuel Dealers',
    722511: 'Full-Service Restaurants',
    812210: 'Funeral Homes and Funeral Services',
    112930: 'Fur-Bearing Animal and Rabbit Production',
    423210: 'Furniture Merchant Wholesalers',
    449110: 'Furniture Retailers',
    339991: 'Gasket, Packing, and Sealing Device Manufacturing',
    457110: 'Gasoline Stations with Convenience Stores',
    811111: 'General Automotive Repair',
    484110: 'General Freight Trucking, Local',
    484122: 'General Freight Trucking, Long-Distance, Less Than Truckload',
    484121: 'General Freight Trucking, Long-Distance, Truckload',
    424410: 'General Line Grocery Merchant Wholesalers',
    622110: 'General Medical and Surgical Hospitals',
    532310: 'General Rental Centers',
    493110: 'General Warehousing and Storage',
    541360: 'Geophysical Surveying and Mapping Services',
    221116: 'Geothermal Electric Power Generation',
    459420: 'Gift, Novelty, and Souvenir Retailers',
    238150: 'Glass and Glazing Contractors',
    327213: 'Glass Container Manufacturing',
    327215: 'Glass Product Manufacturing Made of Purchased Glass',
    112420: 'Goat Farming',
    212220: 'Gold Ore and Silver Ore Mining',
    713910: 'Golf Courses and Country Clubs',
    424510: 'Grain and Field Bean Merchant Wholesalers',
    813211: 'Grantmaking Foundations',
    111332: 'Grape Vineyards',
    541430: 'Graphic Design Services',
    513191: 'Greeting Card Publishers',
    327992: 'Ground or Treated Mineral and Earth Manufacturing',
    336414: 'Guided Missile and Space Vehicle Manufacturing',
    336415: 'Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing',
    327420: 'Gypsum Product Manufacturing',
    332510: 'Hardware Manufacturing',
    423710: 'Hardware Merchant Wholesalers',
    444140: 'Hardware Retailers',
    321211: 'Hardwood Veneer and Plywood Manufacturing',
    111940: 'Hay Farming',
    562112: 'Hazardous Waste Collection',
    562211: 'Hazardous Waste Treatment and Disposal',
    525120: 'Health and Welfare Funds',
    333414: 'Heating Equipment (except Warm Air Furnaces) Manufacturing',
    336120: 'Heavy Duty Truck Manufacturing',
    237310: 'Highway, Street, and Bridge Construction',
    712120: 'Historical Sites',
    621491: 'HMO Medical Centers',
    459120: 'Hobby, Toy, and Game Retailers',
    112210: 'Hog and Pig Farming',
    811411: 'Home and Garden Equipment Repair and Maintenance',
    444110: 'Home Centers',
    423220: 'Home Furnishing Merchant Wholesalers',
    621610: 'Home Health Care Services',
    532283: 'Home Health Equipment Rental',
    112920: 'Horses and Other Equine Production',
    721110: 'Hotels (except Casino Hotels) and Motels',
    423620: 'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers',
    337126: 'Household Furniture (except Wood and Upholstered) Manufacturing',
    541612: 'Human Resources Consulting Services',
    813311: 'Human Rights Organizations',
    114210: 'Hunting and Trapping',
    221111: 'Hydroelectric Power Generation',
    311520: 'Ice Cream and Frozen Dessert Manufacturing',
    312113: 'Ice Manufacturing',
    325413: 'In-Vitro Diagnostic Substance Manufacturing',
    711510: 'Independent Artists, Writers, and Performers',
    541850: 'Indoor and Outdoor Display Advertising',
    333413: 'Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing',
    424130: 'Industrial and Personal Service Paper Merchant Wholesalers',
    236210: 'Industrial Building Construction',
    541420: 'Industrial Design Services',
    325120: 'Industrial Gas Manufacturing',
    812332: 'Industrial Launderers',
    423830: 'Industrial Machinery and Equipment Merchant Wholesalers',
    333511: 'Industrial Mold Manufacturing',
    333994: 'Industrial Process Furnace and Oven Manufacturing',
    212322: 'Industrial Sand Mining',
    423840: 'Industrial Supplies Merchant Wholesalers',
    333924: 'Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing',
    332911: 'Industrial Valve Manufacturing',
    483211: 'Inland Water Freight Transportation',
    483212: 'Inland Water Passenger Transportation',
    337127: 'Institutional Furniture Manufacturing',
    334515: 'Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals',
    334513: 'Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables',
    524210: 'Insurance Agencies and Brokerages',
    541410: 'Interior Design Services',
    928120: 'International Affairs',
    522299: 'International, Secondary Market, and All Other Nondepository Credit Intermediation',
    485210: 'Interurban and Rural Bus Transportation',
    561611: 'Investigation and Personal Background Check Services',
    523150: 'Investment Banking and Securities Intermediation',
    332111: 'Iron and Steel Forging',
    331110: 'Iron and Steel Mills and Ferroalloy Manufacturing',
    331210: 'Iron and Steel Pipe and Tube Manufacturing from Purchased Steel',
    331511: 'Iron Foundries',
    212210: 'Iron Ore Mining',
    334517: 'Irradiation Apparatus Manufacturing',
    561720: 'Janitorial Services',
    339910: 'Jewelry and Silverware Manufacturing',
    458310: 'Jewelry Retailers',
    423940: 'Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers',
    611210: 'Junior Colleges',
    212323: 'Kaolin, Clay, and Ceramic and Refractory Minerals Mining',
    621492: 'Kidney Dialysis Centers',
    313240: 'Knit Fabric Mills',
    813930: 'Labor Unions and Similar Labor Organizations',
    326130: 'Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing',
    237210: 'Land Subdivision',
    541320: 'Landscape Architectural Services',
    561730: 'Landscaping Services',
    611630: 'Language Schools',
    333112: 'Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing',
    316110: 'Leather and Hide Tanning and Finishing',
    922130: 'Legal Counsel and Prosecution',
    921120: 'Legislative Bodies',
    531130: 'Lessors of Miniwarehouses and Self-Storage Units',
    533110: 'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
    531120: 'Lessors of Nonresidential Buildings (except Miniwarehouses)',
    531190: 'Lessors of Other Real Estate Property',
    531110: 'Lessors of Residential Buildings and Dwellings',
    519210: 'Libraries and Archives',
    327410: 'Lime Manufacturing',
    722513: 'Limited-Service Restaurants',
    485320: 'Limousine Service',
    482111: 'Line-Haul Railroads',
    812331: 'Linen Supply',
    424520: 'Livestock Merchant Wholesalers',
    492210: 'Local Messengers and Local Delivery',
    561622: 'Locksmiths',
    113310: 'Logging',
    458320: 'Luggage and Leather Goods Retailers',
    423310: 'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers',
    332710: 'Machine Shops',
    333517: 'Machine Tool Manufacturing',
    335220: 'Major Household Appliance Manufacturing',
    311213: 'Malt Manufacturing',
    459930: 'Manufactured (Mobile) Home Dealers',
    321991: 'Manufactured Home (Mobile Home) Manufacturing',
    334610: 'Manufacturing and Reproducing Magnetic and Optical Media',
    713930: 'Marinas',
    488320: 'Marine Cargo Handling',
    541613: 'Marketing Consulting Services',
    541910: 'Marketing Research and Public Opinion Polling',
    238140: 'Masonry Contractors',
    562920: 'Materials Recovery Facilities',
    337910: 'Mattress Manufacturing',
    311941: 'Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing',
    333914: 'Measuring, Dispensing, and Other Pumping Equipment Manufacturing',
    424470: 'Meat and Meat Product Merchant Wholesalers',
    311612: 'Meat Processed from Carcasses',
    445240: 'Meat Retailers',
    333613: 'Mechanical Power Transmission Equipment Manufacturing',
    541830: 'Media Buying Agencies',
    541840: 'Media Representatives',
    516210: 'Media Streaming Distribution Services, Social Networks, and Other Media Networks and Content Providers',
    621511: 'Medical Laboratories',
    423450: 'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers',
    325411: 'Medicinal and Botanical Manufacturing',
    332431: 'Metal Can Manufacturing',
    332812: 'Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers',
    332119: 'Metal Crown, Closure, and Other Metal Stamping (except Automotive)',
    332811: 'Metal Heat Treating',
    332215: 'Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing',
    423510: 'Metal Service Centers and Other Metal Merchant Wholesalers',
    332420: 'Metal Tank (Heavy Gauge) Manufacturing',
    332321: 'Metal Window and Door Manufacturing',
    336992: 'Military Armored Vehicle, Tank, and Tank Component Manufacturing',
    327993: 'Mineral Wool Manufacturing',
    333131: 'Mining Machinery and Equipment Manufacturing',
    523999: 'Miscellaneous Financial Investment Activities',
    523910: 'Miscellaneous Intermediation',
    485111: 'Mixed Mode Transit Systems',
    722330: 'Mobile Food Services',
    521110: 'Monetary Authorities-Central Bank',
    522310: 'Mortgage and Nonmortgage Loan Brokers',
    512120: 'Motion Picture and Video Distribution',
    512110: 'Motion Picture and Video Production',
    512131: 'Motion Picture Theaters (except Drive-Ins)',
    335312: 'Motor and Generator Manufacturing',
    336213: 'Motor Home Manufacturing',
    336211: 'Motor Vehicle Body Manufacturing',
    336340: 'Motor Vehicle Brake System Manufacturing',
    336320: 'Motor Vehicle Electrical and Electronic Equipment Manufacturing',
    336310: 'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing',
    336370: 'Motor Vehicle Metal Stamping',
    423140: 'Motor Vehicle Parts (Used) Merchant Wholesalers',
    336360: 'Motor Vehicle Seating and Interior Trim Manufacturing',
    336330: 'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing',
    423120: 'Motor Vehicle Supplies and New Parts Merchant Wholesalers',
    488410: 'Motor Vehicle Towing',
    336350: 'Motor Vehicle Transmission and Power Train Parts Manufacturing',
    441227: 'Motorcycle, ATV, and All Other Motor Vehicle Dealers',
    336991: 'Motorcycle, Bicycle, and Parts Manufacturing',
    712110: 'Museums',
    111411: 'Mushroom Production',
    512230: 'Music Publishers',
    711130: 'Musical Groups and Artists',
    459140: 'Musical Instrument and Supplies Retailers',
    339992: 'Musical Instrument Manufacturing',
    812113: 'Nail Salons',
    313220: 'Narrow Fabric Mills and Schiffli Machine Embroidery',
    928110: 'National Security',
    221210: 'Natural Gas Distribution',
    211130: 'Natural Gas Extraction',
    712190: 'Nature Parks and Other Similar Institutions',
    488330: 'Navigational Services to Shipping',
    441110: 'New Car Dealers',
    236117: 'New Housing For-Sale Builders',
    236116: 'New Multifamily Housing Construction (except For-Sale Builders)',
    236115: 'New Single-Family Housing Construction (except For-Sale Builders)',
    513110: 'Newspaper Publishers',
    325311: 'Nitrogenous Fertilizer Manufacturing',
    311340: 'Nonchocolate Confectionery Manufacturing',
    335932: 'Noncurrent-Carrying Wiring Device Manufacturing',
    332112: 'Nonferrous Forging',
    331410: 'Nonferrous Metal (except Aluminum) Smelting and Refining',
    331491: 'Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding',
    331523: 'Nonferrous Metal Die-Casting Foundries',
    531312: 'Nonresidential Property Managers',
    481212: 'Nonscheduled Chartered Freight Air Transportation',
    481211: 'Nonscheduled Chartered Passenger Air Transportation',
    337122: 'Nonupholstered Wood Household Furniture Manufacturing',
    313230: 'Nonwoven Fabric Mills',
    221113: 'Nuclear Electric Power Generation',
    111421: 'Nursery and Tree Production',
    444240: 'Nursery, Garden Center, and Farm Supply Retailers',
    623110: 'Nursing Care Facilities (Skilled Nursing Facilities)',
    561110: 'Office Administrative Services',
    423420: 'Office Equipment Merchant Wholesalers',
    337214: 'Office Furniture (except Wood) Manufacturing',
    532420: 'Office Machinery and Equipment Rental and Leasing',
    339940: 'Office Supplies (except Paper) Manufacturing',
    459410: 'Office Supplies and Stationery Retailers',
    621399: 'Offices of All Other Miscellaneous Health Practitioners',
    551111: 'Offices of Bank Holding Companies',
    541211: 'Offices of Certified Public Accountants',
    621310: 'Offices of Chiropractors',
    621210: 'Offices of Dentists',
    541110: 'Offices of Lawyers',
    621330: 'Offices of Mental Health Practitioners (except Physicians)',
    541120: 'Offices of Notaries',
    621320: 'Offices of Optometrists',
    551112: 'Offices of Other Holding Companies',
    621340: 'Offices of Physical, Occupational and Speech Therapists, and Audiologists',
    621111: 'Offices of Physicians (except Mental Health Specialists)',
    621112: 'Offices of Physicians, Mental Health Specialists',
    621391: 'Offices of Podiatrists',
    531210: 'Offices of Real Estate Agents and Brokers',
    531320: 'Offices of Real Estate Appraisers',
    333132: 'Oil and Gas Field Machinery and Equipment Manufacturing',
    237120: 'Oil and Gas Pipeline and Related Structures Construction',
    111120: 'Oilseed (except Soybean) Farming',
    111191: 'Oilseed and Grain Combination Farming',
    812922: 'One-Hour Photofinishing',
    525910: 'Open-End Investment Funds',
    339115: 'Ophthalmic Goods Manufacturing',
    423460: 'Ophthalmic Goods Merchant Wholesalers',
    456130: 'Optical Goods Retailers',
    111310: 'Orange Groves',
    332323: 'Ornamental and Architectural Metal Work Manufacturing',
    541219: 'Other Accounting Services',
    522390: 'Other Activities Related to Credit Intermediation',
    531390: 'Other Activities Related to Real Estate',
    336413: 'Other Aircraft Parts and Auxiliary Equipment Manufacturing',
    488119: 'Other Airport Operations',
    331318: 'Other Aluminum Rolling, Drawing, and Extruding',
    311119: 'Other Animal Food Manufacturing',
    112519: 'Other Aquaculture',
    325180: 'Other Basic Inorganic Chemical Manufacturing',
    238290: 'Other Building Equipment Contractors',
    238390: 'Other Building Finishing Contractors',
    444180: 'Other Building Material Dealers',
    561439: 'Other Business Service Centers (including Copy Shops)',
    424690: 'Other Chemical and Allied Products Merchant Wholesalers',
    532490: 'Other Commercial and Industrial Machinery and Equipment Rental and Leasing',
    423440: 'Other Commercial Equipment Merchant Wholesalers',
    335929: 'Other Communication and Energy Wire Manufacturing',
    334290: 'Other Communications Equipment Manufacturing',
    624229: 'Other Community Housing Services',
    541519: 'Other Computer Related Services',
    327390: 'Other Concrete Product Manufacturing',
    423390: 'Other Construction Material Merchant Wholesalers',
    212319: 'Other Crushed and Broken Stone Mining and Quarrying',
    524128: 'Other Direct Insurance (except Life, Health, and Medical) Carriers',
    221118: 'Other Electric Power Generation',
    334419: 'Other Electronic Component Manufacturing',
    423690: 'Other Electronic Parts and Equipment Merchant Wholesalers',
    333618: 'Other Engine Equipment Manufacturing',
    332618: 'Other Fabricated Wire Product Manufacturing',
    424590: 'Other Farm Product Raw Material Merchant Wholesalers',
    525990: 'Other Financial Vehicles',
    111419: 'Other Food Crops Grown Under Cover',
    238190: 'Other Foundation, Structure, and Building Exterior Contractors',
    713290: 'Other Gambling Industries',
    457120: 'Other Gasoline Stations',
    921190: 'Other General Government Support',
    813219: 'Other Grantmaking and Giving Services',
    424490: 'Other Grocery and Related Products Merchant Wholesalers',
    336419: 'Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing',
    237990: 'Other Heavy and Civil Engineering Construction',
    624190: 'Other Individual and Family Services',
    525190: 'Other Insurance Funds',
    922190: 'Other Justice, Public Order, and Safety Activities',
    316990: 'Other Leather and Allied Product Manufacturing',
    541618: 'Other Management Consulting Services',
    114119: 'Other Marine Fishing',
    334519: 'Other Measuring and Controlling Device Manufacturing',
    332439: 'Other Metal Container Manufacturing',
    212290: 'Other Metal Ore Mining',
    332919: 'Other Metal Valve and Pipe Fitting Manufacturing',
    321918: 'Other Millwork (including Flooring)',
    423990: 'Other Miscellaneous Durable Goods Merchant Wholesalers',
    424990: 'Other Miscellaneous Nondurable Goods Merchant Wholesalers',
    512199: 'Other Motion Picture and Video Industries',
    336390: 'Other Motor Vehicle Parts Manufacturing',
    111339: 'Other Noncitrus Fruit Farming',
    331529: 'Other Nonferrous Metal Foundries (except Die-Casting)',
    562219: 'Other Nonhazardous Waste Treatment and Disposal',
    212390: 'Other Nonmetallic Mineral Mining and Quarrying',
    481219: 'Other Nonscheduled Air Transportation',
    322219: 'Other Paperboard Container Manufacturing',
    711190: 'Other Performing Arts Companies',
    811490: 'Other Personal and Household Goods Repair and Maintenance',
    812199: 'Other Personal Care Services',
    112390: 'Other Poultry Production',
    327212: 'Other Pressed and Blown Glass and Glassware Manufacturing',
    423490: 'Other Professional Equipment and Supplies Merchant Wholesalers',
    623990: 'Other Residential Care Facilities',
    541690: 'Other Scientific and Technical Consulting Services',
    541890: 'Other Services Related to Advertising',
    561790: 'Other Services to Buildings and Dwellings',
    813990: 'Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)',
    311919: 'Other Snack Food Manufacturing',
    813319: 'Other Social Advocacy Organizations',
    512290: 'Other Sound Recording Industries',
    541490: 'Other Specialized Design Services',
    711219: 'Other Spectator Sports',
    488190: 'Other Support Activities for Air Transportation',
    488490: 'Other Support Activities for Road Transportation',
    488390: 'Other Support Activities for Water Transportation',
    611519: 'Other Technical and Trade Schools',
    485119: 'Other Urban Transit Systems',
    111219: 'Other Vegetable (except Potato) and Melon Farming',
    493190: 'Other Warehousing and Storage',
    562119: 'Other Waste Collection',
    444230: 'Outdoor Power Equipment Retailers',
    621420: 'Outpatient Mental Health and Substance Abuse Centers',
    333923: 'Overhead Traveling Crane, Hoist, and Monorail System Manufacturing',
    424420: 'Packaged Frozen Food Merchant Wholesalers',
    561910: 'Packaging and Labeling Services',
    333993: 'Packaging Machinery Manufacturing',
    488991: 'Packing and Crating',
    325510: 'Paint and Coating Manufacturing',
    444120: 'Paint and Wallpaper Retailers',
    424950: 'Paint, Varnish, and Supplies Merchant Wholesalers',
    238320: 'Painting and Wall Covering Contractors',
    322220: 'Paper Bag and Coated and Treated Paper Manufacturing',
    322120: 'Paper Mills',
    322130: 'Paperboard Mills',
    812930: 'Parking Lots and Garages',
    922150: 'Parole Offices and Probation Offices',
    532112: 'Passenger Car Leasing',
    532111: 'Passenger Car Rental',
    541214: 'Payroll Services',
    111992: 'Peanut Farming',
    525110: 'Pension Funds',
    513120: 'Periodical Publishers',
    311991: 'Perishable Prepared Food Manufacturing',
    325320: 'Pesticide and Other Agricultural Chemical Manufacturing',
    459910: 'Pet and Pet Supplies Retailers',
    812910: 'Pet Care (except Veterinary) Services',
    325110: 'Petrochemical Manufacturing',
    424720: 'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)',
    424710: 'Petroleum Bulk Stations and Terminals',
    324191: 'Petroleum Lubricating Oil and Grease Manufacturing',
    324110: 'Petroleum Refineries',
    325412: 'Pharmaceutical Preparation Manufacturing',
    456110: 'Pharmacies and Drug Retailers',
    524292: 'Pharmacy Benefit Management and Other Third Party Administration of Insurance and Pension Funds',
    325312: 'Phosphatic Fertilizer Manufacturing',
    812921: 'Photofinishing Laboratories (except One-Hour)',
    423410: 'Photographic Equipment and Supplies Merchant Wholesalers',
    325992: 'Photographic Film, Paper, Plate, Chemical, and Copy Toner Manufacturing',
    541921: 'Photography Studios, Portrait',
    424310: 'Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers',
    486110: 'Pipeline Transportation of Crude Oil',
    486210: 'Pipeline Transportation of Natural Gas',
    486910: 'Pipeline Transportation of Refined Petroleum Products',
    326111: 'Plastics Bag and Pouch Manufacturing',
    326160: 'Plastics Bottle Manufacturing',
    325211: 'Plastics Material and Resin Manufacturing',
    424610: 'Plastics Materials and Basic Forms and Shapes Merchant Wholesalers',
    326112: 'Plastics Packaging Film and Sheet (including Laminated) Manufacturing',
    326122: 'Plastics Pipe and Pipe Fitting Manufacturing',
    326191: 'Plastics Plumbing Fixture Manufacturing',
    332313: 'Plate Work Manufacturing',
    423720: 'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers',
    332913: 'Plumbing Fixture Fitting and Trim Manufacturing',
    238220: 'Plumbing, Heating, and Air-Conditioning Contractors',
    922120: 'Police Protection',
    325612: 'Polish and Other Sanitation Good Manufacturing',
    813940: 'Political Organizations',
    326140: 'Polystyrene Foam Product Manufacturing',
    488310: 'Port and Harbor Operations',
    523940: 'Portfolio Management and Investment Advice',
    491110: 'Postal Service',
    115114: 'Postharvest Crop Activities (except Cotton Ginning)',
    111211: 'Potato Farming',
    327110: 'Pottery, Ceramics, and Plumbing Fixture Manufacturing',
    424440: 'Poultry and Poultry Product Merchant Wholesalers',
    112340: 'Poultry Hatcheries',
    311615: 'Poultry Processing',
    238110: 'Poured Concrete Foundation and Structure Contractors',
    332117: 'Powder Metallurgy Part Manufacturing',
    237130: 'Power and Communication Line and Related Structures Construction',
    332410: 'Power Boiler and Heat Exchanger Manufacturing',
    333991: 'Power-Driven Handtool Manufacturing',
    335311: 'Power, Distribution, and Specialty Transformer Manufacturing',
    332721: 'Precision Turned Product Manufacturing',
    332311: 'Prefabricated Metal Building and Component Manufacturing',
    321992: 'Prefabricated Wood Building Manufacturing',
    334418: 'Printed Circuit Assembly (Electronic Assembly) Manufacturing',
    424110: 'Printing and Writing Paper Merchant Wholesalers',
    325910: 'Printing Ink Manufacturing',
    814110: 'Private Households',
    561431: 'Private Mail Centers',
    541614: 'Process, Physical Distribution, and Logistics Consulting Services',
    611430: 'Professional and Management Development Training',
    561330: 'Professional Employer Organizations',
    813920: 'Professional Organizations',
    711310: 'Promoters of Performing Arts, Sports, and Similar Events with Facilities',
    711320: 'Promoters of Performing Arts, Sports, and Similar Events without Facilities',
    622210: 'Psychiatric and Substance Abuse Hospitals',
    921130: 'Public Finance Activities',
    541820: 'Public Relations Agencies',
    322110: 'Pulp Mills',
    711212: 'Racetracks',
    334220: 'Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing',
    516110: 'Radio Broadcasting Stations',
    336510: 'Railroad Rolling Stock Manufacturing',
    327320: 'Ready-Mix Concrete Manufacturing',
    522292: 'Real Estate Credit',
    321219: 'Reconstituted Wood Product Manufacturing',
    512250: 'Record Production and Distribution',
    721214: 'Recreational and Vacation Camps (except Campgrounds)',
    532284: 'Recreational Goods Rental',
    441210: 'Recreational Vehicle Dealers',
    423930: 'Recyclable Material Merchant Wholesalers',
    493120: 'Refrigerated Warehousing and Storage',
    423740: 'Refrigeration Equipment and Supplies Merchant Wholesalers',
    926130: 'Regulation and Administration of Communications, Electric, Gas, and Other Utilities',
    926120: 'Regulation and Administration of Transportation Programs',
    926140: 'Regulation of Agricultural Marketing and Commodities',
    926150: 'Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors',
    524130: 'Reinsurance Carriers',
    335314: 'Relay and Industrial Control Manufacturing',
    813110: 'Religious Organizations',
    562910: 'Remediation Services',
    311613: 'Rendering and Meat Byproduct Processing',
    561491: 'Repossession Services',
    541714: 'Research and Development in Biotechnology (except Nanobiotechnology)',
    541713: 'Research and Development in Nanotechnology',
    541715: 'Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)',
    541720: 'Research and Development in the Social Sciences and Humanities',
    335131: 'Residential Electric Lighting Fixture Manufacturing',
    623210: 'Residential Intellectual and Developmental Disability Facilities',
    623220: 'Residential Mental Health and Substance Abuse Facilities',
    531311: 'Residential Property Managers',
    236118: 'Residential Remodelers',
    311811: 'Retail Bakeries',
    811420: 'Reupholstery and Furniture Repair',
    111160: 'Rice Farming',
    311212: 'Rice Milling',
    311911: 'Roasted Nuts and Peanut Butter Manufacturing',
    331221: 'Rolled Steel Shape Manufacturing',
    333519: 'Rolling Mill and Other Metalworking Machinery Manufacturing',
    238160: 'Roofing Contractors',
    423330: 'Roofing, Siding, and Insulation Material Merchant Wholesalers',
    721310: 'Rooming and Boarding Houses, Dormitories, and Workers\' Camps',
    314994: 'Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills',
    326220: 'Rubber and Plastics Hoses and Belting Manufacturing',
    326291: 'Rubber Product Manufacturing for Mechanical Use',
    721211: 'RV (Recreational Vehicle) Parks and Campgrounds',
    522220: 'Sales Financing',
    322291: 'Sanitary Paper Product Manufacturing',
    517410: 'Satellite Telecommunications',
    522180: 'Savings Institutions and Other Depository Credit Intermediation',
    332216: 'Saw Blade and Handtool Manufacturing',
    333243: 'Sawmill, Woodworking, and Paper Machinery Manufacturing',
    321113: 'Sawmills',
    487110: 'Scenic and Sightseeing Transportation, Land',
    487990: 'Scenic and Sightseeing Transportation, Other',
    487210: 'Scenic and Sightseeing Transportation, Water',
    481112: 'Scheduled Freight Air Transportation',
    481111: 'Scheduled Passenger Air Transportation',
    485410: 'School and Employee Bus Transportation',
    311710: 'Seafood Product Preparation and Packaging',
    334511: 'Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing',
    331314: 'Secondary Smelting and Alloying of Aluminum',
    331492: 'Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum)',
    523210: 'Securities and Commodity Exchanges',
    561612: 'Security Guards and Patrol Services',
    561621: 'Security Systems Services (except Locksmiths)',
    334413: 'Semiconductor and Related Device Manufacturing',
    333242: 'Semiconductor Machinery Manufacturing',
    562991: 'Septic Tank and Related Services',
    423850: 'Service Establishment Equipment and Supplies Merchant Wholesalers',
    624120: 'Services for the Elderly and Persons with Disabilities',
    221320: 'Sewage Treatment Facilities',
    459130: 'Sewing, Needlework, and Piece Goods Retailers',
    112410: 'Sheep Farming',
    332322: 'Sheet Metal Work Manufacturing',
    112512: 'Shellfish Farming',
    114112: 'Shellfish Fishing',
    336611: 'Ship Building and Repairing',
    458210: 'Shoe Retailers',
    482112: 'Short Line Railroads',
    337215: 'Showcase, Partition, Shelving, and Locker Manufacturing',
    238170: 'Siding Contractors',
    339950: 'Sign Manufacturing',
    238910: 'Site Preparation Contractors',
    713920: 'Skiing Facilities',
    332992: 'Small Arms Ammunition Manufacturing',
    332994: 'Small Arms, Ordnance, and Ordnance Accessories Manufacturing',
    335210: 'Small Electrical Appliance Manufacturing',
    722515: 'Snack and Nonalcoholic Beverage Bars',
    325611: 'Soap and Other Detergent Manufacturing',
    312111: 'Soft Drink Manufacturing',
    513210: 'Software Publishers',
    321212: 'Softwood Veneer and Plywood Manufacturing',
    115112: 'Soil Preparation, Planting, and Cultivating',
    221114: 'Solar Electric Power Generation',
    562111: 'Solid Waste Collection',
    562213: 'Solid Waste Combustors and Incinerators',
    562212: 'Solid Waste Landfill',
    512240: 'Sound Recording Studios',
    311224: 'Soybean and Other Oilseed Processing',
    111110: 'Soybean Farming',
    927110: 'Space Research and Technology',
    333514: 'Special Die and Tool, Die Set, Jig, and Fixture Manufacturing',
    485991: 'Special Needs Transportation',
    811114: 'Specialized Automotive Repair',
    484220: 'Specialized Freight (except Used Goods) Trucking, Local',
    484230: 'Specialized Freight (except Used Goods) Trucking, Long-Distance',
    622310: 'Specialty (except Psychiatric and Substance Abuse) Hospitals',
    311422: 'Specialty Canning',
    333612: 'Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing',
    311942: 'Spice and Extract Manufacturing',
    339920: 'Sporting and Athletic Goods Manufacturing',
    423910: 'Sporting and Recreational Goods and Supplies Merchant Wholesalers',
    459110: 'Sporting Goods Retailers',
    611620: 'Sports and Recreation Instruction',
    711211: 'Sports Teams and Clubs',
    332613: 'Spring Manufacturing',
    424120: 'Stationery and Office Supplies Merchant Wholesalers',
    322230: 'Stationery Product Manufacturing',
    221330: 'Steam and Air-Conditioning Supply',
    331513: 'Steel Foundries (except Investment)',
    331512: 'Steel Investment Foundries',
    331222: 'Steel Wire Drawing',
    111333: 'Strawberry Farming',
    238120: 'Structural Steel and Precast Concrete Contractors',
    111991: 'Sugar Beet Farming',
    111930: 'Sugarcane Farming',
    445110: 'Supermarkets and Other Grocery Retailers (except Convenience Retailers)',
    115210: 'Support Activities for Animal Production',
    213113: 'Support Activities for Coal Mining',
    115310: 'Support Activities for Forestry',
    213114: 'Support Activities for Metal Mining',
    213115: 'Support Activities for Nonmetallic Minerals (except Fuels) Mining',
    213112: 'Support Activities for Oil and Gas Operations',
    323120: 'Support Activities for Printing',
    488210: 'Support Activities for Rail Transportation',
    325613: 'Surface Active Agent Manufacturing',
    212114: 'Surface Coal Mining',
    339112: 'Surgical and Medical Instrument Manufacturing',
    339113: 'Surgical Appliance and Supplies Manufacturing',
    541370: 'Surveying and Mapping (except Geophysical) Services',
    335313: 'Switchgear and Switchboard Apparatus Manufacturing',
    325130: 'Synthetic Dye and Pigment Manufacturing',
    325212: 'Synthetic Rubber Manufacturing',
    541213: 'Tax Preparation Services',
    485310: 'Taxi and Ridesharing Services',
    517121: 'Telecommunications Resellers',
    561422: 'Telemarketing Bureaus and Other Contact Centers',
    561421: 'Telephone Answering Services',
    334210: 'Telephone Apparatus Manufacturing',
    512191: 'Teleproduction and Other Postproduction Services',
    516120: 'Television Broadcasting Stations',
    561320: 'Temporary Help Services',
    624221: 'Temporary Shelters',
    541380: 'Testing Laboratories and Services',
    313310: 'Textile and Fabric Finishing Mills',
    314910: 'Textile Bag and Canvas Mills',
    711110: 'Theater Companies and Dinner Theaters',
    238340: 'Tile and Terrazzo Contractors',
    113110: 'Timber Tract Operations',
    423130: 'Tire and Tube Merchant Wholesalers',
    441340: 'Tire Dealers',
    326211: 'Tire Manufacturing (except Retreading)',
    326212: 'Tire Retreading',
    541191: 'Title Abstract and Settlement Offices',
    111910: 'Tobacco Farming',
    312230: 'Tobacco Manufacturing',
    424940: 'Tobacco Product and Electronic Cigarette Merchant Wholesalers',
    459991: 'Tobacco, Electronic Cigarette, and Other Smoking Supplies Retailers',
    325620: 'Toilet Preparation Manufacturing',
    311830: 'Tortilla Manufacturing',
    334514: 'Totalizing Fluid Meter and Counting Device Manufacturing',
    561520: 'Tour Operators',
    423920: 'Toy and Hobby Goods and Supplies Merchant Wholesalers',
    541930: 'Translation and Interpretation Services',
    423860: 'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers',
    561510: 'Travel Agencies',
    336214: 'Travel Trailer and Camper Manufacturing',
    111335: 'Tree Nut Farming',
    336212: 'Truck Trailer Manufacturing',
    532120: 'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing',
    523991: 'Trust, Fiduciary, and Custody Activities',
    525920: 'Trusts, Estates, and Agency Accounts',
    333611: 'Turbine and Turbine Generator Set Units Manufacturing',
    112330: 'Turkey Production',
    212115: 'Underground Coal Mining',
    326113: 'Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing',
    326121: 'Unlaminated Plastics Profile Shape Manufacturing',
    337121: 'Upholstered Household Furniture Manufacturing',
    326150: 'Urethane and Other Foam Product (except Polystyrene) Manufacturing',
    441120: 'Used Car Dealers',
    484210: 'Used Household and Office Goods Moving',
    459510: 'Used Merchandise Retailers',
    445132: 'Vending Machine Operators',
    541940: 'Veterinary Services',
    532282: 'Video Tape and Disc Rental',
    624310: 'Vocational Rehabilitation Services',
    813212: 'Voluntary Health Organizations',
    455211: 'Warehouse Clubs and Supercenters',
    423730: 'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers',
    237110: 'Water and Sewer Line and Related Structures Construction',
    221310: 'Water Supply and Irrigation Systems',
    519290: 'Web Search Portals and All Other Information Services',
    333992: 'Welding and Soldering Equipment Manufacturing',
    311221: 'Wet Corn Milling and Starch Manufacturing',
    111140: 'Wheat Farming',
    425120: 'Wholesale Trade Agents and Brokers',
    221115: 'Wind Electric Power Generation',
    449122: 'Window Treatment Retailers',
    424820: 'Wine and Distilled Alcoholic Beverage Merchant Wholesalers',
    312130: 'Wineries',
    517111: 'Wired Telecommunications Carriers',
    517112: 'Wireless Telecommunications Carriers (except Satellite)',
    321920: 'Wood Container and Pallet Manufacturing',
    337110: 'Wood Kitchen Cabinet and Countertop Manufacturing',
    337211: 'Wood Office Furniture Manufacturing',
    321114: 'Wood Preservation',
    321911: 'Wood Window and Door Manufacturing',
    712130: 'Zoos and Botanical Gardens'
  };
});