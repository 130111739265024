define("shared/components/business-guide-shared/card-stage", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="w-full md:mx-auto">
    <div class="flex flex-col">
      <div class="text-base text-bp-text-icons-main leading-normal md:leading-tight font-bold {{if @alignTitle @alignTitle 'md:text-center'}}">{{@title}}</div>
      <div class="text-sm md:text-base text-bp-text-icons-subdued leading-tight {{if @alignTitle @alignTitle 'md:text-center'}}">{{@subtitle}}</div>
      <div class="flex flex-col gap-3 md:gap-4">
        <div class="flex flex-col md:flex-row md:justify-center stages gap-4 w-full md:px-0 mx-auto mt-6 md:mt-8">
          {{#each-in @answers as |answer answerVal|}}
            <div class="business-guide-stage-container">
              <button type="button"
                      class="stage-card {{if (eq answer @selected) "selected"}} {{if this.isMoreThanThreeAnswers 'more-than-three-answers' 'max-three-answers'}}"
                      data-testing-id={{answerVal.slug}} {{on "click" (fn @changeSelection answer)}}>
                <img class="w-14 h-14 md:w-16 md:h-16" src="/images/business-guide/{{@pageName}}/{{answer}}.svg" alt={{t 'preview'}}>
                <div class="image-text text-center md:font-bold text-base leading-tight">{{t (concat 'business_guide.' @pageName '.' answer)}}</div>
              </button>
            </div>
          {{/each-in}}
        </div>
      </div>
    </div>
  </div>
  
  */
  {
    "id": "mjwZZogY",
    "block": "[[[10,0],[14,0,\"w-full md:mx-auto\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-col\"],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"text-base text-bp-text-icons-main leading-normal md:leading-tight font-bold \",[52,[30,1],[30,1],\"md:text-center\"]]]],[12],[1,[30,2]],[13],[1,\"\\n    \"],[10,0],[15,0,[29,[\"text-sm md:text-base text-bp-text-icons-subdued leading-tight \",[52,[30,1],[30,1],\"md:text-center\"]]]],[12],[1,[30,3]],[13],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-col gap-3 md:gap-4\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"flex flex-col md:flex-row md:justify-center stages gap-4 w-full md:px-0 mx-auto mt-6 md:mt-8\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[30,4]],null],null,[[[1,\"          \"],[10,0],[14,0,\"business-guide-stage-container\"],[12],[1,\"\\n            \"],[11,\"button\"],[16,0,[29,[\"stage-card \",[52,[28,[37,3],[[30,6],[30,7]],null],\"selected\"],\" \",[52,[30,0,[\"isMoreThanThreeAnswers\"]],\"more-than-three-answers\",\"max-three-answers\"]]]],[16,\"data-testing-id\",[30,5,[\"slug\"]]],[24,4,\"button\"],[4,[38,4],[\"click\",[28,[37,5],[[30,8],[30,6]],null]],null],[12],[1,\"\\n              \"],[10,\"img\"],[14,0,\"w-14 h-14 md:w-16 md:h-16\"],[15,\"src\",[29,[\"/images/business-guide/\",[30,9],\"/\",[30,6],\".svg\"]]],[15,\"alt\",[28,[37,6],[\"preview\"],null]],[12],[13],[1,\"\\n              \"],[10,0],[14,0,\"image-text text-center md:font-bold text-base leading-tight\"],[12],[1,[28,[35,6],[[28,[37,7],[\"business_guide.\",[30,9],\".\",[30,6]],null]],null]],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[5,6]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@alignTitle\",\"@title\",\"@subtitle\",\"@answers\",\"answerVal\",\"answer\",\"@selected\",\"@changeSelection\",\"@pageName\"],false,[\"if\",\"each\",\"-each-in\",\"eq\",\"on\",\"fn\",\"t\",\"concat\"]]",
    "moduleName": "shared/components/business-guide-shared/card-stage.hbs",
    "isStrictMode": false
  });
  class BusinessGuideCardStageComponent extends _component2.default {
    get isMoreThanThreeAnswers() {
      return Object.keys(this.args.answers).length > 3;
    }
  }
  _exports.default = BusinessGuideCardStageComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BusinessGuideCardStageComponent);
});