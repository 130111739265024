define("shared/llc/llc-upsell-page-version", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RA_PAGE_VERSION = _exports.EIN_PAGE_VERSION = _exports.EF_PAGE_VERSION = _exports.BRANDING_PAGE_VERSION = _exports.AC_PAGE_VERSION = void 0;
  const RA_PAGE_VERSION = _exports.RA_PAGE_VERSION = {
    v1: 'ra_no_in_physical_address',
    v2: 'ra_no_in_dedicated_person',
    default: 'ra_yes_in_both'
  };
  const EIN_PAGE_VERSION = _exports.EIN_PAGE_VERSION = {
    v1: 'ein_yes_in_employee_status',
    v2: 'ein_yes_in_bank_status',
    v3: 'ein_yes_in_both',
    default: 'ein_no_in_both'
  };
  const AC_PAGE_VERSION = _exports.AC_PAGE_VERSION = {
    v1: 'ac_business_definition_main_income',
    v2: 'ac_business_definition_side_hustle',
    default: 'ac_business_definition_not_sure'
  };
  const BRANDING_PAGE_VERSION = _exports.BRANDING_PAGE_VERSION = {
    v1: 'branding_business_location_online',
    v2: 'branding_business_location_offline',
    default: 'branding_business_location_both'
  };
  const EF_PAGE_VERSION = _exports.EF_PAGE_VERSION = {
    v1: 'ef_business_status_idea',
    v2: 'ef_business_status_in_business',
    default: 'ef_business_status_ready_to_launch'
  };
  var _default = _exports.default = {
    RA_PAGE_VERSION,
    EIN_PAGE_VERSION,
    AC_PAGE_VERSION,
    BRANDING_PAGE_VERSION,
    EF_PAGE_VERSION
  };
});