define("shared/components/form-elements/toggle/toggle", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/utils", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _object, _utils, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label for={{this.elementId}} class="cp-toggle-button relative cursor-pointer {{@className}}">
    {{#if (and @dataTestingIdOn @dataTestingIdOff)}}
      <Input id={{this.elementId}} @type="checkbox" @checked={{@isOn}} class="hidden" {{on 'click' this.toggleSelect}} data-testing-id={{if @isOn @dataTestingIdOn @dataTestingIdOff}}/>
    {{else}}
      <Input id={{this.elementId}} @type="checkbox" @checked={{@isOn}} class="hidden" {{on 'click' this.toggleSelect}}/>
    {{/if}}
    <div class="toggle-line w-10 h-5 rounded-0.9rem transition duration-200 {{this.toggleColor}} hover:{{this.toggleHoverColor}}"></div>
    <div class="toggle-dot absolute bg-white w-3 h-3 rounded-full"></div>
  </label>
  
  */
  {
    "id": "syEFQzyT",
    "block": "[[[10,\"label\"],[15,\"for\",[30,0,[\"elementId\"]]],[15,0,[29,[\"cp-toggle-button relative cursor-pointer \",[30,1]]]],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,2],[30,3]],null],[[[1,\"    \"],[8,[39,2],[[16,1,[30,0,[\"elementId\"]]],[24,0,\"hidden\"],[16,\"data-testing-id\",[52,[30,4],[30,2],[30,3]]],[4,[38,3],[\"click\",[30,0,[\"toggleSelect\"]]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,4]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,2],[[16,1,[30,0,[\"elementId\"]]],[24,0,\"hidden\"],[4,[38,3],[\"click\",[30,0,[\"toggleSelect\"]]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,4]]],null],[1,\"\\n\"]],[]]],[1,\"  \"],[10,0],[15,0,[29,[\"toggle-line w-10 h-5 rounded-0.9rem transition duration-200 \",[30,0,[\"toggleColor\"]],\" hover:\",[30,0,[\"toggleHoverColor\"]]]]],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"toggle-dot absolute bg-white w-3 h-3 rounded-full\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@className\",\"@dataTestingIdOn\",\"@dataTestingIdOff\",\"@isOn\"],false,[\"if\",\"and\",\"input\",\"on\"]]",
    "moduleName": "shared/components/form-elements/toggle/toggle.hbs",
    "isStrictMode": false
  });
  let Toggle = _exports.default = (_class = class Toggle extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "isOn", _descriptor, this);
      _defineProperty(this, "DEFAULT_ELEMENT_ID", 'toggle-button');
      _defineProperty(this, "DEFAULT_COLOR_ON", 'bg-bp-primary-main');
      _defineProperty(this, "DEFAULT_COLOR_OFF", 'bg-bp-primary-disabled');
      _defineProperty(this, "DEFAULT_COLOR_HOVER_ON", 'bg-bp-primary-hovered');
      _defineProperty(this, "DEFAULT_COLOR_HOVER_OFF", 'bg-bp-primary-disabled');
      this.isOn = this.args.isOn;
    }
    get toggleColor() {
      if (!this.isOn) {
        return this.DEFAULT_COLOR_OFF;
      }
      return this.args.color ? this.args.color : this.DEFAULT_COLOR_ON;
    }
    get toggleHoverColor() {
      if (!this.isOn) {
        return this.DEFAULT_COLOR_HOVER_OFF;
      }
      return this.args.hoverColor ? this.args.hoverColor : this.DEFAULT_COLOR_HOVER_ON;
    }
    get elementId() {
      return this.args.id ? this.args.id : this.DEFAULT_ELEMENT_ID;
    }
    toggleSelect() {
      this.isOn = !this.isOn;
      if ((0, _utils.isBlank)(this.args.onClick)) return;
      this.args.onClick();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isOn", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleSelect", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSelect"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Toggle);
});