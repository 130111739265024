define("shared/components/form-elements/basic-radio-input/basic-radio-input", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='{{@wrapperClass}} mr-auto' data-testing-id={{@dataTesting}}>
    <RadioButton @classNames="radio-input-container-global1 flex flex-row flex-center {{@customClass}} {{@dataTesting}}" @checkedClass="active" @value={{@radioVal}} @groupValue={{@radioGroupVal}} @changed={{@onChange}}>
      <div class="input-circle absolute"/>
      <span class="text-input-container mr-4 ml-2">{{@inputLabel}}</span>
    </RadioButton>
  </div>
  
  */
  {
    "id": "RZPJKs9j",
    "block": "[[[10,0],[15,0,[29,[[30,1],\" mr-auto\"]]],[15,\"data-testing-id\",[30,2]],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@classNames\",\"@checkedClass\",\"@value\",\"@groupValue\",\"@changed\"],[[29,[\"radio-input-container-global1 flex flex-row flex-center \",[30,3],\" \",[30,2]]],\"active\",[30,4],[30,5],[30,6]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"input-circle absolute\"],[12],[13],[1,\"\\n    \"],[10,1],[14,0,\"text-input-container mr-4 ml-2\"],[12],[1,[30,7]],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@wrapperClass\",\"@dataTesting\",\"@customClass\",\"@radioVal\",\"@radioGroupVal\",\"@onChange\",\"@inputLabel\"],false,[\"radio-button\"]]",
    "moduleName": "shared/components/form-elements/basic-radio-input/basic-radio-input.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});