define("shared/llc/ein-business-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SOLE_PROP = _exports.LLC = _exports.CORP = void 0;
  const SOLE_PROP = _exports.SOLE_PROP = 'sole_prop';
  const CORP = _exports.CORP = 'corp';
  const LLC = _exports.LLC = 'llc';
  var _default = _exports.default = {
    SOLE_PROP,
    CORP,
    LLC
  };
});