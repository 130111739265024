define("shared/llc/boi-filing-statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.BOI_QUESTIONS_DONE = _exports.BOI_PAYMENT_DONE = _exports.BOI_EXCEPTION = _exports.BOI_COMPLETED = _exports.BOI_AT_STATE = _exports.BOI_APPLICATION_SENT = void 0;
  const BOI_PAYMENT_DONE = _exports.BOI_PAYMENT_DONE = 'payment_done';
  const BOI_QUESTIONS_DONE = _exports.BOI_QUESTIONS_DONE = 'questions_done';
  const BOI_APPLICATION_SENT = _exports.BOI_APPLICATION_SENT = 'application_sent';
  const BOI_AT_STATE = _exports.BOI_AT_STATE = 'at_state';
  const BOI_EXCEPTION = _exports.BOI_EXCEPTION = 'exception';
  const BOI_COMPLETED = _exports.BOI_COMPLETED = 'completed';
  var _default = _exports.default = {
    BOI_PAYMENT_DONE,
    BOI_QUESTIONS_DONE,
    BOI_APPLICATION_SENT,
    BOI_AT_STATE,
    BOI_EXCEPTION,
    BOI_COMPLETED
  };
});