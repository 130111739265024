define("shared/llc/permit-order-statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PERMIT_PENDING_FUNDS = _exports.PERMIT_PENDING_CUSTOMER_REVIEW = _exports.PERMIT_PENDING_CUSTOMER_RESPONSE = _exports.PERMIT_PAYMENT_DONE = _exports.PERMIT_ON_HOLD = _exports.PERMIT_IN_REVIEW = _exports.PERMIT_IN_QUESTIONNAIRE = _exports.PERMIT_IN_PROCESS = _exports.PERMIT_INACTIVE = _exports.PERMIT_DELETED = _exports.PERMIT_CREATED = _exports.PERMIT_COMPLETED_QUESTIONNAIRE = _exports.PERMIT_COMPLETED = _exports.PERMIT_APPLICATION_SUBMITTED = _exports.PERMIT_APPLICATION_SENT = void 0;
  const PERMIT_CREATED = _exports.PERMIT_CREATED = 'created';
  const PERMIT_PAYMENT_DONE = _exports.PERMIT_PAYMENT_DONE = 'payment_done';
  const PERMIT_APPLICATION_SUBMITTED = _exports.PERMIT_APPLICATION_SUBMITTED = 'application_submitted';
  const PERMIT_APPLICATION_SENT = _exports.PERMIT_APPLICATION_SENT = 'application_sent';
  const PERMIT_DELETED = _exports.PERMIT_DELETED = 'deleted';
  const PERMIT_COMPLETED = _exports.PERMIT_COMPLETED = 'completed';
  const PERMIT_IN_QUESTIONNAIRE = _exports.PERMIT_IN_QUESTIONNAIRE = 'in_questionnaire';
  const PERMIT_COMPLETED_QUESTIONNAIRE = _exports.PERMIT_COMPLETED_QUESTIONNAIRE = 'completed_questionnaire';
  const PERMIT_INACTIVE = _exports.PERMIT_INACTIVE = 'inactive';
  const PERMIT_IN_PROCESS = _exports.PERMIT_IN_PROCESS = 'in_process';
  const PERMIT_ON_HOLD = _exports.PERMIT_ON_HOLD = 'on_hold';
  const PERMIT_IN_REVIEW = _exports.PERMIT_IN_REVIEW = 'in_review';
  const PERMIT_PENDING_CUSTOMER_RESPONSE = _exports.PERMIT_PENDING_CUSTOMER_RESPONSE = 'pending_customer_response';
  const PERMIT_PENDING_FUNDS = _exports.PERMIT_PENDING_FUNDS = 'pending_funds';
  const PERMIT_PENDING_CUSTOMER_REVIEW = _exports.PERMIT_PENDING_CUSTOMER_REVIEW = 'pending_customer_review';
  var _default = _exports.default = {
    PERMIT_CREATED,
    PERMIT_PAYMENT_DONE,
    PERMIT_APPLICATION_SUBMITTED,
    PERMIT_APPLICATION_SENT,
    PERMIT_DELETED,
    PERMIT_COMPLETED,
    PERMIT_IN_QUESTIONNAIRE,
    PERMIT_COMPLETED_QUESTIONNAIRE,
    PERMIT_INACTIVE,
    PERMIT_IN_PROCESS,
    PERMIT_ON_HOLD,
    PERMIT_IN_REVIEW,
    PERMIT_PENDING_CUSTOMER_RESPONSE,
    PERMIT_PENDING_FUNDS,
    PERMIT_PENDING_CUSTOMER_REVIEW
  };
});