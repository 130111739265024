define("shared/components/login/reset-password-form", ["exports", "@ember/component", "@ember/test-waiters", "@ember/service", "@glimmer/component", "@glimmer/tracking", "ember-concurrency", "frontend/config/environment", "@ember/template-factory"], function (_exports, _component, _testWaiters, _service, _component2, _tracking, _emberConcurrency, _environment, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
    <form {{on "submit" (perform this.processReset)}} class="tform form-common reset-password-form">
      <label for="email">{{t 'email_address'}}</label>
      <Input @type="email" id="email" @value={{this.email}} data-testing-id="email"/>
      <button data-testing-id="login-button" type="submit" class="tbtn red-gradient rounded submit-btn mt-xs mb-xs relative">
        {{#if this.processReset.isRunning }}
          <Global::BouncingLoader @class="primary white-dots"/>
        {{else}}
          {{t 'reset'}}
        {{/if}}
      </button>
    </form>
  </div>
  
  */
  {
    "id": "/oxnPT8R",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[11,\"form\"],[24,0,\"tform form-common reset-password-form\"],[4,[38,0],[\"submit\",[28,[37,1],[[30,0,[\"processReset\"]]],null]],null],[12],[1,\"\\n    \"],[10,\"label\"],[14,\"for\",\"email\"],[12],[1,[28,[35,2],[\"email_address\"],null]],[13],[1,\"\\n    \"],[8,[39,3],[[24,1,\"email\"],[24,\"data-testing-id\",\"email\"]],[[\"@type\",\"@value\"],[\"email\",[30,0,[\"email\"]]]],null],[1,\"\\n    \"],[10,\"button\"],[14,\"data-testing-id\",\"login-button\"],[14,0,\"tbtn red-gradient rounded submit-btn mt-xs mb-xs relative\"],[14,4,\"submit\"],[12],[1,\"\\n\"],[41,[30,0,[\"processReset\",\"isRunning\"]],[[[1,\"        \"],[8,[39,5],null,[[\"@class\"],[\"primary white-dots\"]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,2],[\"reset\"],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"on\",\"perform\",\"t\",\"input\",\"if\",\"global/bouncing-loader\"]]",
    "moduleName": "shared/components/login/reset-password-form.hbs",
    "isStrictMode": false
  });
  let ResetPasswordFormComponent = _exports.default = (_class = class ResetPasswordFormComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "flashMessages", _descriptor2, this);
      _initializerDefineProperty(this, "ajax", _descriptor3, this);
      _initializerDefineProperty(this, "email", _descriptor4, this);
    }
    *processReset(e) {
      e.preventDefault();
      try {
        yield this.ajax.request(`${_environment.default.hostnames.sauron}/v1/passwords`, {
          method: 'POST',
          data: {
            user: {
              email: this.email
            }
          }
        });
        this.flashMessages.success(this.intl.t('reset_password.sent'));
      } catch (e) {
        this.flashMessages.warning(this.intl.t('reset_password.failed'));
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "email", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "processReset", [_emberConcurrency.dropTask, _testWaiters.waitFor], Object.getOwnPropertyDescriptor(_class.prototype, "processReset"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ResetPasswordFormComponent);
});