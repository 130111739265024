define("shared/components/global/bouncing-loader", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="global-bouncing-loader flex flex-col h-full w-full {{@class}} {{unless @left 'items-center justify-center'}}">
    <div class="dots flex {{unless @left 'items-center justify-center'}}">
      {{#if @smaller}}
        <span class="dot rounded-full {{if @color (concat 'bg-' @color) 'bg-white'}} h-2 w-2 mr-2" />
        <span class="dot rounded-full {{if @color (concat 'bg-' @color) 'bg-white'}} h-2 w-2 mr-2" />
        <span class="dot rounded-full {{if @color (concat 'bg-' @color) 'bg-white'}} h-2 w-2" />
      {{else}}
        <span class="dot rounded-full {{if @color (concat 'bg-' @color) 'bg-white'}} h-3 w-3 mr-3" />
        <span class="dot rounded-full {{if @color (concat 'bg-' @color) 'bg-white'}} h-3 w-3 mr-3" />
        <span class="dot rounded-full {{if @color (concat 'bg-' @color) 'bg-white'}} h-3 w-3" />
      {{/if}}
    </div>
  </div>
  
  */
  {
    "id": "WFnRUaFB",
    "block": "[[[10,0],[15,0,[29,[\"global-bouncing-loader flex flex-col h-full w-full \",[30,1],\" \",[52,[51,[30,2]],\"items-center justify-center\"]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"dots flex \",[52,[51,[30,2]],\"items-center justify-center\"]]]],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"      \"],[10,1],[15,0,[29,[\"dot rounded-full \",[52,[30,4],[28,[37,2],[\"bg-\",[30,4]],null],\"bg-white\"],\" h-2 w-2 mr-2\"]]],[12],[13],[1,\"\\n      \"],[10,1],[15,0,[29,[\"dot rounded-full \",[52,[30,4],[28,[37,2],[\"bg-\",[30,4]],null],\"bg-white\"],\" h-2 w-2 mr-2\"]]],[12],[13],[1,\"\\n      \"],[10,1],[15,0,[29,[\"dot rounded-full \",[52,[30,4],[28,[37,2],[\"bg-\",[30,4]],null],\"bg-white\"],\" h-2 w-2\"]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,1],[15,0,[29,[\"dot rounded-full \",[52,[30,4],[28,[37,2],[\"bg-\",[30,4]],null],\"bg-white\"],\" h-3 w-3 mr-3\"]]],[12],[13],[1,\"\\n      \"],[10,1],[15,0,[29,[\"dot rounded-full \",[52,[30,4],[28,[37,2],[\"bg-\",[30,4]],null],\"bg-white\"],\" h-3 w-3 mr-3\"]]],[12],[13],[1,\"\\n      \"],[10,1],[15,0,[29,[\"dot rounded-full \",[52,[30,4],[28,[37,2],[\"bg-\",[30,4]],null],\"bg-white\"],\" h-3 w-3\"]]],[12],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"@left\",\"@smaller\",\"@color\"],false,[\"unless\",\"if\",\"concat\"]]",
    "moduleName": "shared/components/global/bouncing-loader.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});