define("shared/components/global/timer-badge", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="text-bp-text-icons-positive font-bold md:font-normal md:border-bp-border-positive py-1 px-2 text-xs text-center bg-white md:rounded md:border md:border-solid md:shadow">
    <span class="mr-2">{{@text}}</span>
    <span class="inline-block w-16">{{this.printTime}}</span>
  </div>
  
  */
  {
    "id": "a2UjWBlk",
    "block": "[[[10,0],[14,0,\"text-bp-text-icons-positive font-bold md:font-normal md:border-bp-border-positive py-1 px-2 text-xs text-center bg-white md:rounded md:border md:border-solid md:shadow\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"mr-2\"],[12],[1,[30,1]],[13],[1,\"\\n  \"],[10,1],[14,0,\"inline-block w-16\"],[12],[1,[30,0,[\"printTime\"]]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@text\"],false,[]]",
    "moduleName": "shared/components/global/timer-badge.hbs",
    "isStrictMode": false
  });
  let TimerBadge = _exports.default = (_class = class TimerBadge extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "time", _descriptor, this);
      _defineProperty(this, "timer", null);
      this.setTimer();
    }
    setTimer() {
      let expirationTimestamp = this.args.start * 1000 + this.args.end * (60 * 60) * 1000;
      if (this.args.offset) {
        expirationTimestamp = expirationTimestamp - this.args.offset;
      }
      const expirationTime = new Date(expirationTimestamp);
      this.timer = setInterval(() => {
        this.startTimer(expirationTime);
      }, 1000);
    }
    get printTime() {
      return this.time ? `${this.time.hours}:${this.time.minutes}:${this.time.seconds}` : '00:00:00';
    }
    willDestroy() {
      super.willDestroy(...arguments);
      clearInterval(this.timer);
    }
    startTimer(toDate) {
      const dateEntered = toDate;
      const now = new Date();
      const difference = dateEntered.getTime() - now.getTime();
      if (difference <= 0) {
        clearInterval(this.timer);
      } else {
        let seconds = Math.floor(difference / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);
        hours %= 24;
        minutes %= 60;
        seconds %= 60;
        this.time = {
          days: days.toString().padStart(2, '0'),
          hours: hours.toString().padStart(2, '0'),
          minutes: minutes.toString().padStart(2, '0'),
          seconds: seconds.toString().padStart(2, '0')
        };
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "time", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TimerBadge);
});