define("shared/llc/arf-lists", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FILING_TYPES_ES = _exports.FILING_TYPES = _exports.BUSINESS_TYPES_ES = _exports.BUSINESS_TYPES = void 0;
  const FILING_TYPES = _exports.FILING_TYPES = ['Domestic', 'Domestic + foreign'];
  const FILING_TYPES_ES = _exports.FILING_TYPES_ES = ['Doméstico', 'Doméstico + extranjero'];
  const BUSINESS_TYPES = _exports.BUSINESS_TYPES = ['LLC', 'Corporation'];
  const BUSINESS_TYPES_ES = _exports.BUSINESS_TYPES_ES = ['LLC', 'Corporación'];
});