define("shared/components/global/faq", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul class="global-faq-list flex flex-col w-full">
    {{#each @questions as |question index|}}
      <li data-testing-id={{concat 'frequently-ask-questions-' question}} class="w-full py-6 lg:py-8 border-bp-border-main border-b border-opacity-50">
        <details open={{if index false true}} class="[&_svg]:open:-rotate-180 [&_svg]:open:text-bp-text-icons-action [&_h4]:open:text-bp-text-icons-action w-full [&_summary]:open:mb-4 [&_p]:open:mt-4">
          <summary class="text-left font-bold flex justify-between cursor-pointer items-center" data-testing-id="faq-summary-{{index}}">
            <h4 class="text-md md:text-xl md:h-6 leading-6 mr-5">{{t (concat @text "faq." question '.question')}}</h4>
            <div>
              <svg fill="none" height="20" width="20" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </div>
          </summary>
          <p class="lg:max-w-260 text-md lg:text-base text-bp-text-icons-subdued">{{t (concat @text "faq." question '.answer')}}</p>
        </details>
      </li>
    {{/each}}
  </ul>
  
  */
  {
    "id": "DQPERR3x",
    "block": "[[[10,\"ul\"],[14,0,\"global-faq-list flex flex-col w-full\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"    \"],[10,\"li\"],[15,\"data-testing-id\",[28,[37,2],[\"frequently-ask-questions-\",[30,2]],null]],[14,0,\"w-full py-6 lg:py-8 border-bp-border-main border-b border-opacity-50\"],[12],[1,\"\\n      \"],[10,\"details\"],[15,\"open\",[52,[30,3],false,true]],[14,0,\"[&_svg]:open:-rotate-180 [&_svg]:open:text-bp-text-icons-action [&_h4]:open:text-bp-text-icons-action w-full [&_summary]:open:mb-4 [&_p]:open:mt-4\"],[12],[1,\"\\n        \"],[10,\"summary\"],[14,0,\"text-left font-bold flex justify-between cursor-pointer items-center\"],[15,\"data-testing-id\",[29,[\"faq-summary-\",[30,3]]]],[12],[1,\"\\n          \"],[10,\"h4\"],[14,0,\"text-md md:text-xl md:h-6 leading-6 mr-5\"],[12],[1,[28,[35,4],[[28,[37,2],[[30,4],\"faq.\",[30,2],\".question\"],null]],null]],[13],[1,\"\\n          \"],[10,0],[12],[1,\"\\n            \"],[10,\"svg\"],[14,\"fill\",\"none\"],[14,\"height\",\"20\"],[14,\"width\",\"20\"],[14,\"stroke\",\"currentColor\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"2\"],[14,\"viewBox\",\"0 0 24 24\"],[12],[1,\"\\n              \"],[10,\"polyline\"],[14,\"points\",\"6 9 12 15 18 9\"],[12],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,2],[14,0,\"lg:max-w-260 text-md lg:text-base text-bp-text-icons-subdued\"],[12],[1,[28,[35,4],[[28,[37,2],[[30,4],\"faq.\",[30,2],\".answer\"],null]],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2,3]],null],[13],[1,\"\\n\"]],[\"@questions\",\"question\",\"index\",\"@text\"],false,[\"each\",\"-track-array\",\"concat\",\"if\",\"t\"]]",
    "moduleName": "shared/components/global/faq.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});