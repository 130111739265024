define("shared/components/business-guide-shared/offer-card", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{unless @selected 'border border-transparent'}}>
    <div class="bg-bp-surface-main transition duration-200 shadow-bp-sm rounded hover:shadow-bp-md cursor-pointer {{if @selected 'border-2 border-bp-primary-main hover:border-bp-primary-main' 'border border-transparent hover:border-black'}}"
         data-testing-id={{@dataTestingId}} role="button" {{on 'click' @clickAction}}>
      <div class="flex items-center gap-2 px-2 md:px-6 py-4 md:py-3">
        <img src={{@image}} class="w-16 h-16" alt={{t (concat 'business_guide.offer.' @answer)}}>
        {{#if @includeDescriptions}}
          <div class="flex flex-col gap-1">
            <div class="text-sm md:text-base leading-tight md:leading-normal font-bold">{{t (concat 'business_guide.offer.' @answer)}}</div>
            <div class="text-sm md:text-base leading-tight text-bp-text-icons-subdued">{{t (concat 'business_guide.offer.' @answer '_description')}}</div>
          </div>
        {{else}}
          <div class="text-sm md:text-base leading-tight md:leading-normal font-bold">{{t (concat 'business_guide.offer.' @answer)}}</div>
        {{/if}}
      </div>
    </div>
  </div>
  
  */
  {
    "id": "N60ZvicT",
    "block": "[[[10,0],[15,0,[52,[51,[30,1]],\"border border-transparent\"]],[12],[1,\"\\n  \"],[11,0],[16,0,[29,[\"bg-bp-surface-main transition duration-200 shadow-bp-sm rounded hover:shadow-bp-md cursor-pointer \",[52,[30,1],\"border-2 border-bp-primary-main hover:border-bp-primary-main\",\"border border-transparent hover:border-black\"]]]],[16,\"data-testing-id\",[30,2]],[24,\"role\",\"button\"],[4,[38,2],[\"click\",[30,3]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex items-center gap-2 px-2 md:px-6 py-4 md:py-3\"],[12],[1,\"\\n      \"],[10,\"img\"],[15,\"src\",[30,4]],[14,0,\"w-16 h-16\"],[15,\"alt\",[28,[37,3],[[28,[37,4],[\"business_guide.offer.\",[30,5]],null]],null]],[12],[13],[1,\"\\n\"],[41,[30,6],[[[1,\"        \"],[10,0],[14,0,\"flex flex-col gap-1\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"text-sm md:text-base leading-tight md:leading-normal font-bold\"],[12],[1,[28,[35,3],[[28,[37,4],[\"business_guide.offer.\",[30,5]],null]],null]],[13],[1,\"\\n          \"],[10,0],[14,0,\"text-sm md:text-base leading-tight text-bp-text-icons-subdued\"],[12],[1,[28,[35,3],[[28,[37,4],[\"business_guide.offer.\",[30,5],\"_description\"],null]],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"text-sm md:text-base leading-tight md:leading-normal font-bold\"],[12],[1,[28,[35,3],[[28,[37,4],[\"business_guide.offer.\",[30,5]],null]],null]],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@selected\",\"@dataTestingId\",\"@clickAction\",\"@image\",\"@answer\",\"@includeDescriptions\"],false,[\"unless\",\"if\",\"on\",\"t\",\"concat\"]]",
    "moduleName": "shared/components/business-guide-shared/offer-card.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});