define("shared/components/global/vimeo-iframe", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/utils", "frontend/config/environment", "@datadog/browser-rum", "@ember/template-factory"], function (_exports, _component, _component2, _object, _utils, _environment, _browserRum, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div {{did-insert this.loadAndCreatePlayer}} id={{@videoId}} class={{@customClass}}></div>
  
  */
  {
    "id": "iHK0sisx",
    "block": "[[[11,0],[16,1,[30,1]],[16,0,[30,2]],[4,[38,0],[[30,0,[\"loadAndCreatePlayer\"]]],null],[12],[13],[1,\"\\n\"]],[\"@videoId\",\"@customClass\"],false,[\"did-insert\"]]",
    "moduleName": "shared/components/global/vimeo-iframe.hbs",
    "isStrictMode": false
  });
  let VimeoIframeComponent = _exports.default = (_class = class VimeoIframeComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "VIMEO_API", 'https://player.vimeo.com/api/player.js');
    }
    get playerId() {
      return this.args.videoId;
    }
    loadAndCreatePlayer() {
      if (_environment.default.env === 'test') return;
      try {
        this.loadVimeoScript();
      } catch (error) {
        _browserRum.datadogRum.addError(new Error('VimeoSetupFailure', {
          internal_error: error.message
        }));
      }
    }
    loadVimeoScript() {
      if (window.Vimeo) {
        this.onVimeoScriptLoad();
      } else {
        let vimeoScript = document.createElement('script');
        vimeoScript.src = this.VIMEO_API;
        vimeoScript.onload = () => {
          this.onVimeoScriptLoad();
        };
        document.head.appendChild(vimeoScript);
      }
    }
    onVimeoScriptLoad() {
      const player = new window.Vimeo.Player(this.args.videoId, {
        height: (0, _utils.isBlank)(this.args.height) ? '360' : this.args.height,
        width: (0, _utils.isBlank)(this.args.width) ? '640' : this.args.width,
        autoplay: true,
        url: `https://player.vimeo.com/video/${this.args.videoUrl}`
      });
      if ((0, _utils.isPresent)(this.args.playerReady)) this.args.playerReady();
      player.on('play', () => {
        if ((0, _utils.isPresent)(this.args.playingVideo)) this.args.playingVideo();
      });
      player.on('pause', () => {
        if ((0, _utils.isPresent)(this.args.pausedVideo)) this.args.pausedVideo();
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "loadAndCreatePlayer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadAndCreatePlayer"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, VimeoIframeComponent);
});