define("shared/components/business-guide-shared/card-question", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="business-stage-container flex md-flex-center flex-col md:text-center pb-24 md:pb-16 {{if @llcFlow 'md:pt-6 pt-2 md:h-full'  'h-full my-5 mx-5 md:my-0'}}">
    <div class="text-3xl md:text-4xl text-bp-text-icons-main leading-7 md:leading-8 pt-4 md:pt-0 font-gazpacho">{{@title}}</div>
    <div class="text-sm md:text-xl text-bp-text-icons-subdued leading-5 md:leading-7 mt-2">{{@subtitle}}</div>
    <div class="flex flex-col md:flex-row md:justify-center stages gap-4 w-full md:px-0 md:w-9/10 mx-auto mt-6 md:mt-8">
      {{#each-in @answers as |answer answerVal|}}
        <div class="business-guide-question-container">
          <button type="button"
                  class="question-card {{if (eq answer @selected) "selected"}} {{if this.isMoreThanThreeAnswers 'more-than-three-answers' 'max-three-answers'}}"
                  data-testing-id={{answerVal.slug}} {{on "click" (fn @changeSelection answer)}}>
            <img class="w-16 h-16 md:w-20 md:h-20" src="/images/business-guide/{{@pageName}}/{{answer}}.svg" alt={{t 'preview'}}>
            <div class="image-text text-center md:font-bold text-base leading-tight">{{t (concat 'business_guide.' @pageName '.' answer)}}</div>
          </button>
        </div>
      {{/each-in}}
    </div>
  </div>
  
  */
  {
    "id": "V6xyf/f3",
    "block": "[[[10,0],[15,0,[29,[\"business-stage-container flex md-flex-center flex-col md:text-center pb-24 md:pb-16 \",[52,[30,1],\"md:pt-6 pt-2 md:h-full\",\"h-full my-5 mx-5 md:my-0\"]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"text-3xl md:text-4xl text-bp-text-icons-main leading-7 md:leading-8 pt-4 md:pt-0 font-gazpacho\"],[12],[1,[30,2]],[13],[1,\"\\n  \"],[10,0],[14,0,\"text-sm md:text-xl text-bp-text-icons-subdued leading-5 md:leading-7 mt-2\"],[12],[1,[30,3]],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-col md:flex-row md:justify-center stages gap-4 w-full md:px-0 md:w-9/10 mx-auto mt-6 md:mt-8\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[30,4]],null],null,[[[1,\"      \"],[10,0],[14,0,\"business-guide-question-container\"],[12],[1,\"\\n        \"],[11,\"button\"],[16,0,[29,[\"question-card \",[52,[28,[37,3],[[30,6],[30,7]],null],\"selected\"],\" \",[52,[30,0,[\"isMoreThanThreeAnswers\"]],\"more-than-three-answers\",\"max-three-answers\"]]]],[16,\"data-testing-id\",[30,5,[\"slug\"]]],[24,4,\"button\"],[4,[38,4],[\"click\",[28,[37,5],[[30,8],[30,6]],null]],null],[12],[1,\"\\n          \"],[10,\"img\"],[14,0,\"w-16 h-16 md:w-20 md:h-20\"],[15,\"src\",[29,[\"/images/business-guide/\",[30,9],\"/\",[30,6],\".svg\"]]],[15,\"alt\",[28,[37,6],[\"preview\"],null]],[12],[13],[1,\"\\n          \"],[10,0],[14,0,\"image-text text-center md:font-bold text-base leading-tight\"],[12],[1,[28,[35,6],[[28,[37,7],[\"business_guide.\",[30,9],\".\",[30,6]],null]],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[5,6]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@llcFlow\",\"@title\",\"@subtitle\",\"@answers\",\"answerVal\",\"answer\",\"@selected\",\"@changeSelection\",\"@pageName\"],false,[\"if\",\"each\",\"-each-in\",\"eq\",\"on\",\"fn\",\"t\",\"concat\"]]",
    "moduleName": "shared/components/business-guide-shared/card-question.hbs",
    "isStrictMode": false
  });
  class BusinessGuideCardQuestionComponent extends _component2.default {
    get isMoreThanThreeAnswers() {
      return Object.keys(this.args.answers).length > 3;
    }
  }
  _exports.default = BusinessGuideCardQuestionComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BusinessGuideCardQuestionComponent);
});