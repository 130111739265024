define("ember-power-select/components/power-select/no-matches-message", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @noMatchesMessage}}
    <ul class="ember-power-select-options" role="listbox">
      <li class="ember-power-select-option ember-power-select-option--no-matches-message" role="option">
        {{@noMatchesMessage}}
      </li>
    </ul>
  {{/if}}
  */
  {
    "id": "fr/GNGvq",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,\"ul\"],[14,0,\"ember-power-select-options\"],[14,\"role\",\"listbox\"],[12],[1,\"\\n    \"],[10,\"li\"],[14,0,\"ember-power-select-option ember-power-select-option--no-matches-message\"],[14,\"role\",\"option\"],[12],[1,\"\\n      \"],[1,[30,1]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@noMatchesMessage\"],false,[\"if\"]]",
    "moduleName": "ember-power-select/components/power-select/no-matches-message.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});