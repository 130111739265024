define("shared/components/link-to/secondary", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo::Base @buttonName="secondary"
                @text={{@text}}
                @size={{@size}}
                @fullWidth={{@fullWidth}}
                @iconLeft={{@iconLeft}}
                @iconRight={{@iconRight}}
                @disabled={{@disabled}}
                @route={{@route}}
                @model={{@model}}
                @query={{@query}}
                @active={{@active}}
                @dataTestingId={{@dataTestingId}}
                @loader={{@loader}}
                @loaderColor="bp-text-icons-action"/>
  
  */
  {
    "id": "pp3ApJ2j",
    "block": "[[[8,[39,0],null,[[\"@buttonName\",\"@text\",\"@size\",\"@fullWidth\",\"@iconLeft\",\"@iconRight\",\"@disabled\",\"@route\",\"@model\",\"@query\",\"@active\",\"@dataTestingId\",\"@loader\",\"@loaderColor\"],[\"secondary\",[30,1],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,12],\"bp-text-icons-action\"]],null],[1,\"\\n\"]],[\"@text\",\"@size\",\"@fullWidth\",\"@iconLeft\",\"@iconRight\",\"@disabled\",\"@route\",\"@model\",\"@query\",\"@active\",\"@dataTestingId\",\"@loader\"],false,[\"link-to/base\"]]",
    "moduleName": "shared/components/link-to/secondary.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});