define("shared/components/attribution-survey/attribution-survey-container", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "frontend/config/environment", "@ember/service", "frontend/constants/event", "@ember/template-factory"], function (_exports, _component, _component2, _object, _environment, _service, _event, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="content-container">
    <div class="m-auto w-full">
      <div class="flex flex-col">
        <span class="text-4xl text-center mb-sm font-gazpacho">{{t 'wizard.survey.attribution_question'}}</span>
      </div>
      <AttributionSurvey::AttributionSurveyForm @selectAnswerAction={{this.selectAnswer}} @selectedAnswer={{this.businessSurvey.selectedAttribution}}/>
    </div>
  </div>
  <AttributionSurvey::AttributionFooter @nextAction={{this.next}} @backAction={{this.back}} @skipAction={{this.skip}} @selectedAnswer={{this.businessSurvey.selectedAttribution}}/>
  
  */
  {
    "id": "PQNZ5+Ub",
    "block": "[[[10,0],[14,0,\"content-container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"m-auto w-full\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-col\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"text-4xl text-center mb-sm font-gazpacho\"],[12],[1,[28,[35,0],[\"wizard.survey.attribution_question\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,1],null,[[\"@selectAnswerAction\",\"@selectedAnswer\"],[[30,0,[\"selectAnswer\"]],[30,0,[\"businessSurvey\",\"selectedAttribution\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,2],null,[[\"@nextAction\",\"@backAction\",\"@skipAction\",\"@selectedAnswer\"],[[30,0,[\"next\"]],[30,0,[\"back\"]],[30,0,[\"skip\"]],[30,0,[\"businessSurvey\",\"selectedAttribution\"]]]],null],[1,\"\\n\"]],[],false,[\"t\",\"attribution-survey/attribution-survey-form\",\"attribution-survey/attribution-footer\"]]",
    "moduleName": "shared/components/attribution-survey/attribution-survey-container.hbs",
    "isStrictMode": false
  });
  let AttributionSurveyContainer = _exports.default = (_class = class AttributionSurveyContainer extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "heap", _descriptor, this);
      _initializerDefineProperty(this, "localStorage", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "businessSurvey", _descriptor4, this);
      _initializerDefineProperty(this, "events", _descriptor5, this);
      _initializerDefineProperty(this, "cookies", _descriptor6, this);
      _defineProperty(this, "landingPageSource", '');
    }
    selectAnswer(answer, index) {
      if (this.businessSurvey.selectedAttribution?.name === answer.name) {
        this.businessSurvey.selectedAttribution = null;
      } else {
        this.businessSurvey.selectedAttribution = {
          name: answer.name,
          index: index
        };
      }
    }
    fireStorioEvent(answer) {
      let landing_page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      this.events.addEvent.perform({
        entity_type: _event.EVENT_ENTITY_TYPE.USER,
        name: _event.EVENT.USER_ATTRIBUTION_ANSWER,
        properties: {
          user_attribution_answer: answer,
          landing_page_source: landing_page
        }
      });
    }
    get funnelType() {
      return this.localStorage.getItem('funnel_type') || 'logo';
    }
    next() {
      this.heap.track('funnel_attribution_question_submit', {
        business_id: this.args.business.id,
        attribution_answer: this.businessSurvey.selectedAttribution.name,
        attribution_answer_placement: this.businessSurvey.selectedAttribution.index,
        funnel_type: this.funnelType
      });
      this.localStorage.setItem('funnel_attribution_step_value', this.businessSurvey.selectedAttribution.name);
      this.localStorage.setItem('funnel_attribution_step', true);
      this.landingPageSource = this.cookies.get('landing_page_source');
      this.fireStorioEvent(this.businessSurvey.selectedAttribution.name, this.landingPageSource);
      return this.router.transitionTo('wizard.survey', this.args.business.id);
    }
    back() {
      window.location.href = _environment.default.hostnames.marketing;
    }
    skip() {
      this.localStorage.removeItem('funnel_attribution_step');
      this.localStorage.removeItem('funnel_attribution_step_value');
      return this.router.transitionTo('wizard.survey', this.args.business.id);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "heap", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "localStorage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "businessSurvey", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "events", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "cookies", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectAnswer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectAnswer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "next", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "next"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "back", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "skip", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "skip"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AttributionSurveyContainer);
});