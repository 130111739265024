define("shared/components/buttons/primary", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Buttons::Base @buttonName="primary"
                 @type={{@type}}
                 @text={{@text}}
                 @size={{@size}}
                 @bold={{@bold}}
                 @fullWidth={{@fullWidth}}
                 @iconLeft={{@iconLeft}}
                 @iconRight={{@iconRight}}
                 @disabled={{@disabled}}
                 @action={{@action}}
                 @task={{@task}}
                 @active={{@active}}
                 @dataTestingId={{@dataTestingId}}
                 @loader={{@loader}}
                 @loaderColor="white"
                 @taskArgs={{@taskArgs}}
                 @taskArg={{@taskArg}}
  />
  
  */
  {
    "id": "CHoD0Xqn",
    "block": "[[[8,[39,0],null,[[\"@buttonName\",\"@type\",\"@text\",\"@size\",\"@bold\",\"@fullWidth\",\"@iconLeft\",\"@iconRight\",\"@disabled\",\"@action\",\"@task\",\"@active\",\"@dataTestingId\",\"@loader\",\"@loaderColor\",\"@taskArgs\",\"@taskArg\"],[\"primary\",[30,1],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,12],[30,13],\"white\",[30,14],[30,15]]],null],[1,\"\\n\"]],[\"@type\",\"@text\",\"@size\",\"@bold\",\"@fullWidth\",\"@iconLeft\",\"@iconRight\",\"@disabled\",\"@action\",\"@task\",\"@active\",\"@dataTestingId\",\"@loader\",\"@taskArgs\",\"@taskArg\"],false,[\"buttons/base\"]]",
    "moduleName": "shared/components/buttons/primary.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});