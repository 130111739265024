define("shared/components/global/carousel-images", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/runloop", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _runloop, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
    <div class="carousel-container"
      {{on "touchstart" this.handleTouchStart}}
      {{on "touchmove" this.handleTouchMove}}
      {{on "touchend" this.handleTouchEnd}}>
      <div class="flex justify-center" style={{this.transformStyle}}>
        <img src={{this.image}} class="md:block w-full" alt={{@upsellType}}>
      </div>
    </div>
    <div class="bottom-0 flex justify-center mb-8 md:mb-6 z-20">
      {{#each @images as |item index|}}
        <button type="button"
          {{on 'click' (fn this.handlePageChange index)}} class="{{if (eq this.selectedIndex index) 'bg-bp-base-primary rounded-full w-2.5 h-2.5 mr-3' 'bg-bp-base-primary opacity-30'}} rounded-full w-2.5 h-2.5 mr-3">
        </button>
      {{/each}}
    </div>
  </div>
  
  */
  {
    "id": "Wb0ixE9O",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[11,0],[24,0,\"carousel-container\"],[4,[38,0],[\"touchstart\",[30,0,[\"handleTouchStart\"]]],null],[4,[38,0],[\"touchmove\",[30,0,[\"handleTouchMove\"]]],null],[4,[38,0],[\"touchend\",[30,0,[\"handleTouchEnd\"]]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex justify-center\"],[15,5,[30,0,[\"transformStyle\"]]],[12],[1,\"\\n      \"],[10,\"img\"],[15,\"src\",[30,0,[\"image\"]]],[14,0,\"md:block w-full\"],[15,\"alt\",[30,1]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"bottom-0 flex justify-center mb-8 md:mb-6 z-20\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,2]],null]],null],null,[[[1,\"      \"],[11,\"button\"],[16,0,[29,[[52,[28,[37,4],[[30,0,[\"selectedIndex\"]],[30,4]],null],\"bg-bp-base-primary rounded-full w-2.5 h-2.5 mr-3\",\"bg-bp-base-primary opacity-30\"],\" rounded-full w-2.5 h-2.5 mr-3\"]]],[24,4,\"button\"],[4,[38,0],[\"click\",[28,[37,5],[[30,0,[\"handlePageChange\"]],[30,4]],null]],null],[12],[1,\"\\n      \"],[13],[1,\"\\n\"]],[3,4]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@upsellType\",\"@images\",\"item\",\"index\"],false,[\"on\",\"each\",\"-track-array\",\"if\",\"eq\",\"fn\"]]",
    "moduleName": "shared/components/global/carousel-images.hbs",
    "isStrictMode": false
  });
  let CarouselImages = _exports.default = (_class = class CarouselImages extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "selectedIndex", _descriptor2, this);
      _defineProperty(this, "SWIPE_THRESHOLD", 50);
      _defineProperty(this, "PAGING_MS", 6000);
      _defineProperty(this, "startX", 0);
      _defineProperty(this, "endX", 0);
      this.setAutoCarousel();
    }
    setAutoCarousel() {
      const {
        pagingMs
      } = this.args;
      this.carouselInterval = setInterval(() => {
        this.changeCurrentPage((this.selectedIndex + 1) % this.args.images.length);
      }, pagingMs || this.PAGING_MS);
    }
    handlePageChange(index) {
      clearInterval(this.carouselInterval);
      this.changeCurrentPage(index);
      this.setAutoCarousel();
    }
    changeCurrentPage(index) {
      const carouselElement = document.querySelector('.carousel-container');
      this.handleAnimationEndListener(carouselElement, index);
      carouselElement.classList.remove('fadein');
      carouselElement.classList.remove('fadeout');
      (0, _runloop.next)(this, () => {
        carouselElement.classList.add('fadeout');
      });
    }
    handleAnimationEndListener(carouselElement, index) {
      carouselElement.removeEventListener('animationend', this.animationEndListener);
      this.animationEndListener = () => {
        const isContainFadeOut = carouselElement.classList.contains('fadeout');
        if (isContainFadeOut) {
          this.selectedIndex = index;
          carouselElement.classList.remove('fadeout');
          carouselElement.classList.add('fadein');
        }
      };
      carouselElement.addEventListener('animationend', this.animationEndListener);
    }
    swipeLeft() {
      if (this.selectedIndex < this.args.images.length - 1) {
        this.selectedIndex += 1;
        this.changeCurrentPage(this.selectedIndex);
      }
    }
    swipeRight() {
      if (this.selectedIndex > 0) {
        this.selectedIndex -= 1;
        this.changeCurrentPage(this.selectedIndex);
      }
    }
    handleTouchStart(event) {
      this.startX = event.touches[0].clientX;
    }
    handleTouchMove(event) {
      this.endX = event.touches[0].clientX;
    }
    handleTouchEnd() {
      const deltaX = this.endX - this.startX;
      if (Math.abs(deltaX) > this.SWIPE_THRESHOLD) {
        if (deltaX < 0) {
          this.swipeLeft();
        } else {
          this.swipeRight();
        }
      }
    }
    get image() {
      const {
        screenSizes
      } = this.args;
      return screenSizes.isSmall ? `/images/carousel/${this.args.images[this.selectedIndex]}_mobile.png` : `/images/carousel/${this.args.images[this.selectedIndex]}.png`;
    }
    get transformStyle() {
      return `translateX(-${this.selectedIndex * 100}%)`;
    }
    willDestroy() {
      super.willDestroy();
      clearInterval(this.carouselInterval);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedIndex", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handlePageChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handlePageChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTouchStart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTouchStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTouchMove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTouchMove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTouchEnd", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTouchEnd"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CarouselImages);
});