define("shared/components/marketing/faqs", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex flex-col px-3 mx-auto max-w-200">
    <h3 class="text-2xl md:text-3xl font-bold border-bp-border-main border-b pb-5 mb-8 font-gazpacho">{{t 'sites.experts.plans.faqs_title'}}</h3>
    {{#each this.questions as |question|}}
      <div data-testing-id={{concat 'frequently-ask-questions-' question}} class="flex justify-between border-bp-border-main border-b mb-8 {{if (eq this.questionSelected question) 'active pb-6' 'pb-2'}}">
        <div>
          <button class="text-left text-lg md:text-xl font-bold {{if (eq this.questionSelected question) 'text-bp-text-icons-action mb-4' 'text-bp-text-icons-main mb-sm'}}" {{on "click" (fn this.selectQuestion question)}} type="button">
            {{t (concat 'marketing.faqs.' question '.question')}}
          </button>
          {{#if (eq this.questionSelected question) }}
            <p class="lg:max-w-260 max-w-116 text-sm md:text-base text-bp-text-icons-subdued mb-xs">{{t (concat 'marketing.faqs.' question '.answer')}}</p>
          {{/if}}
        </div>
        <i role="button"
          {{on "click" (fn this.selectQuestion question)}}
           class="{{if (eq this.questionSelected question) 'icon-angle-up text-bp-text-icons-action' 'icon-angle-down text-bp-text-icons-main'}} icon cursor-pointer text-2xl"></i>
      </div>
    {{/each}}
  </div>
  
  */
  {
    "id": "6GZ4rxnW",
    "block": "[[[10,0],[14,0,\"flex flex-col px-3 mx-auto max-w-200\"],[12],[1,\"\\n  \"],[10,\"h3\"],[14,0,\"text-2xl md:text-3xl font-bold border-bp-border-main border-b pb-5 mb-8 font-gazpacho\"],[12],[1,[28,[35,0],[\"sites.experts.plans.faqs_title\"],null]],[13],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"questions\"]]],null]],null],null,[[[1,\"    \"],[10,0],[15,\"data-testing-id\",[28,[37,3],[\"frequently-ask-questions-\",[30,1]],null]],[15,0,[29,[\"flex justify-between border-bp-border-main border-b mb-8 \",[52,[28,[37,5],[[30,0,[\"questionSelected\"]],[30,1]],null],\"active pb-6\",\"pb-2\"]]]],[12],[1,\"\\n      \"],[10,0],[12],[1,\"\\n        \"],[11,\"button\"],[16,0,[29,[\"text-left text-lg md:text-xl font-bold \",[52,[28,[37,5],[[30,0,[\"questionSelected\"]],[30,1]],null],\"text-bp-text-icons-action mb-4\",\"text-bp-text-icons-main mb-sm\"]]]],[24,4,\"button\"],[4,[38,6],[\"click\",[28,[37,7],[[30,0,[\"selectQuestion\"]],[30,1]],null]],null],[12],[1,\"\\n          \"],[1,[28,[35,0],[[28,[37,3],[\"marketing.faqs.\",[30,1],\".question\"],null]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"],[41,[28,[37,5],[[30,0,[\"questionSelected\"]],[30,1]],null],[[[1,\"          \"],[10,2],[14,0,\"lg:max-w-260 max-w-116 text-sm md:text-base text-bp-text-icons-subdued mb-xs\"],[12],[1,[28,[35,0],[[28,[37,3],[\"marketing.faqs.\",[30,1],\".answer\"],null]],null]],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n      \"],[11,\"i\"],[24,\"role\",\"button\"],[16,0,[29,[[52,[28,[37,5],[[30,0,[\"questionSelected\"]],[30,1]],null],\"icon-angle-up text-bp-text-icons-action\",\"icon-angle-down text-bp-text-icons-main\"],\" icon cursor-pointer text-2xl\"]]],[4,[38,6],[\"click\",[28,[37,7],[[30,0,[\"selectQuestion\"]],[30,1]],null]],null],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\"]],[\"question\"],false,[\"t\",\"each\",\"-track-array\",\"concat\",\"if\",\"eq\",\"on\",\"fn\"]]",
    "moduleName": "shared/components/marketing/faqs.hbs",
    "isStrictMode": false
  });
  let FaqsComponent = _exports.default = (_class = class FaqsComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "QUESTIONS", Object.freeze(['1', '2', '3', '4', '5']));
      _initializerDefineProperty(this, "questionSelected", _descriptor, this);
    }
    get questions() {
      return this.QUESTIONS;
    }
    selectQuestion(question) {
      this.questionSelected = question === this.questionSelected ? null : question;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "questionSelected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.QUESTIONS[0];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectQuestion", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectQuestion"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FaqsComponent);
});