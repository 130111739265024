define("shared/components/business-guide-shared/scratch-off-item/scratch-off-item", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div role="button" {{on 'click' (fn this.onCheck @item this.isOn)}} data-testing-id="segmentation-scratch-off-{{@item}}-checked"
       class="{{if this.isOn 'scratched-off-container'}} {{if (or @isLast @isNoneOfTheAboveOption) '' 'dashed-spaced-border'}} flex items-center justify-items-center gap-2.5 py-3 px-4.5">
    <Input class="scratch-off-checkbox cursor-pointer" @type="checkbox" @checked={{this.isOn}}/>
    <div class="line-animation {{if this.isOn 'scratched-off-product' 'text-bp-text-icons-subdued'}} {{if (and (not @isNoneOfTheAboveOption) this.isOn) 'strikethrough'}} leading-tight">
      {{#if @isNoneOfTheAboveOption}}
        {{t (concat 'business_guide.existing_products.none_of_the_above')}}
      {{else}}
        {{t (concat 'business_guide.recommendations.products.' @item)}}
      {{/if}}
    </div>
  </div>
  
  */
  {
    "id": "kOnDzWm+",
    "block": "[[[11,0],[24,\"role\",\"button\"],[16,\"data-testing-id\",[29,[\"segmentation-scratch-off-\",[30,1],\"-checked\"]]],[16,0,[29,[[52,[30,0,[\"isOn\"]],\"scratched-off-container\"],\" \",[52,[28,[37,1],[[30,2],[30,3]],null],\"\",\"dashed-spaced-border\"],\" flex items-center justify-items-center gap-2.5 py-3 px-4.5\"]]],[4,[38,2],[\"click\",[28,[37,3],[[30,0,[\"onCheck\"]],[30,1],[30,0,[\"isOn\"]]],null]],null],[12],[1,\"\\n  \"],[8,[39,4],[[24,0,\"scratch-off-checkbox cursor-pointer\"]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"isOn\"]]]],null],[1,\"\\n  \"],[10,0],[15,0,[29,[\"line-animation \",[52,[30,0,[\"isOn\"]],\"scratched-off-product\",\"text-bp-text-icons-subdued\"],\" \",[52,[28,[37,5],[[28,[37,6],[[30,3]],null],[30,0,[\"isOn\"]]],null],\"strikethrough\"],\" leading-tight\"]]],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"      \"],[1,[28,[35,7],[[28,[37,8],[\"business_guide.existing_products.none_of_the_above\"],null]],null]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,7],[[28,[37,8],[\"business_guide.recommendations.products.\",[30,1]],null]],null]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@item\",\"@isLast\",\"@isNoneOfTheAboveOption\"],false,[\"if\",\"or\",\"on\",\"fn\",\"input\",\"and\",\"not\",\"t\",\"concat\"]]",
    "moduleName": "shared/components/business-guide-shared/scratch-off-item/scratch-off-item.hbs",
    "isStrictMode": false
  });
  let BusinessGuideScratchOffItemComponent = _exports.default = (_class = class BusinessGuideScratchOffItemComponent extends _component2.default {
    get isOn() {
      return this.args.checkedProducts.includes(this.args.item);
    }
    onCheck(item, isOn) {
      this.args.onCheck(item, isOn);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onCheck", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onCheck"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BusinessGuideScratchOffItemComponent);
});