define("shared/components/buttons/secondary", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Buttons::Base @buttonName="secondary"
                 @text={{@text}}
                 @size={{@size}}
                 @fullWidth={{@fullWidth}}
                 @iconLeft={{@iconLeft}}
                 @iconRight={{@iconRight}}
                 @disabled={{@disabled}}
                 @action={{@action}}
                 @task={{@task}}
                 @active={{@active}}
                 @dataTestingId={{@dataTestingId}}
                 @loader={{@loader}}
                 @customClasses={{@customClasses}}
                 @loaderColor="bp-text-icons-action"
                 @taskArgs={{@taskArgs}}
                 @taskArg={{@taskArg}}
  />
  
  */
  {
    "id": "ijvPDsyM",
    "block": "[[[8,[39,0],null,[[\"@buttonName\",\"@text\",\"@size\",\"@fullWidth\",\"@iconLeft\",\"@iconRight\",\"@disabled\",\"@action\",\"@task\",\"@active\",\"@dataTestingId\",\"@loader\",\"@customClasses\",\"@loaderColor\",\"@taskArgs\",\"@taskArg\"],[\"secondary\",[30,1],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,12],\"bp-text-icons-action\",[30,13],[30,14]]],null],[1,\"\\n\"]],[\"@text\",\"@size\",\"@fullWidth\",\"@iconLeft\",\"@iconRight\",\"@disabled\",\"@action\",\"@task\",\"@active\",\"@dataTestingId\",\"@loader\",\"@customClasses\",\"@taskArgs\",\"@taskArg\"],false,[\"buttons/base\"]]",
    "moduleName": "shared/components/buttons/secondary.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});