define("shared/components/global/header-progress-bar", ["exports", "@ember/component", "@ember/test-waiters", "@glimmer/component", "@ember/string", "@ember/object", "@ember/service", "ember-concurrency", "@ember/utils", "@ember/template-factory"], function (_exports, _component, _testWaiters, _component2, _string, _object, _service, _emberConcurrency, _utils, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div style={{this.progressWidthStyle}} class="cp-global-header-progress-bar bg-bp-primary-main h-1" {{did-insert this.updateProgress}} {{did-update this.updateProgress this.progressWidthStyle this.router.currentRouteName}}></div>
  
  */
  {
    "id": "7AC13u5F",
    "block": "[[[11,0],[16,5,[30,0,[\"progressWidthStyle\"]]],[24,0,\"cp-global-header-progress-bar bg-bp-primary-main h-1\"],[4,[38,0],[[30,0,[\"updateProgress\"]]],null],[4,[38,1],[[30,0,[\"updateProgress\"]],[30,0,[\"progressWidthStyle\"]],[30,0,[\"router\",\"currentRouteName\"]]],null],[12],[13],[1,\"\\n\"]],[],false,[\"did-insert\",\"did-update\"]]",
    "moduleName": "shared/components/global/header-progress-bar.hbs",
    "isStrictMode": false
  });
  let ProgressBarComponent = _exports.default = (_class = class ProgressBarComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
    }
    get currentStepIndex() {
      if ((0, _utils.isPresent)(this.args.currentStepIndex)) {
        return this.args.currentStepIndex;
      } else {
        return this.args.steps.indexOf(this.args.currentStep) + 1;
      }
    }
    get stepsCount() {
      if ((0, _utils.isPresent)(this.args.stepsCount)) {
        return this.args.stepsCount;
      } else {
        return this.args.steps.length;
      }
    }
    get progressWidthStyle() {
      const progressWidth = Math.round(this.currentStepIndex / this.stepsCount * 100);
      return (0, _string.htmlSafe)(`width:${progressWidth}%`);
    }
    *updateProgressTask(element) {
      element.classList.add('progress-bar-animation');
      yield (0, _emberConcurrency.timeout)(2000);
      element.classList.remove('progress-bar-animation');
    }
    updateProgress(element) {
      this.updateProgressTask.perform(element);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateProgressTask", [_emberConcurrency.restartableTask, _testWaiters.waitFor], Object.getOwnPropertyDescriptor(_class.prototype, "updateProgressTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateProgress", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateProgress"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ProgressBarComponent);
});