define("shared/llc/principal-activity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PRINCIPAL_ACTIVITY_LIST = _exports.PA_PRINCIPAL_ACTIVITIES_TO_REMOVE = _exports.OTHER_ACTIVITY = void 0;
  const PRINCIPAL_ACTIVITY_LIST = _exports.PRINCIPAL_ACTIVITY_LIST = ['accommodation_and_food_service', 'any_legal_purpose', 'church', 'clubs_and_organizations', 'educational_purpose', 'encourage_amateur_sports_competition', 'finance_and_insurance', 'general_charitable_purpose', 'health_care_and_social_assistance', 'literary_purpose', 'manufacturing', 'other_religious_purpose', 'public_safety_purposes', 'real_estate', 'rental_and_leasing', 'retail', 'scientific_purpose', 'to_create_a_charitable_risk_pool', 'to_provide_child_care_outside_home', 'to_provide_hospital_or_medical_care', 'low_income_elderly_or_handicapped_housing', 'transportation_and_warehousing', 'wholesale_agent_broker', 'wholesale_other', 'other'];
  const OTHER_ACTIVITY = _exports.OTHER_ACTIVITY = 'other';
  const PA_PRINCIPAL_ACTIVITIES_TO_REMOVE = _exports.PA_PRINCIPAL_ACTIVITIES_TO_REMOVE = ['any_legal_purpose', 'finance_and_insurance', 'other_religious_purpose', 'wholesale_other'];
});