define("shared/components/inputs/phone-input", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _service, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="text-input-container {{@customClass}} {{if this.isValid '' 'mb-2'}}">
    {{phone-input id=@data-testing-id separateDialCode=true number=@phoneNumber update=this.update class=this.phoneInputClass}}
    {{#unless (or this.isValid @hideValidationMsg)}}
      <div data-testing-id="phone_input_error_text" class="text-bp-base-brand text-sm absolute mt-0">{{this.validationErrorText}}</div>
    {{/unless}}
  </div>
  
  */
  {
    "id": "jSqWh04A",
    "block": "[[[10,0],[15,0,[29,[\"text-input-container \",[30,1],\" \",[52,[30,0,[\"isValid\"]],\"\",\"mb-2\"]]]],[12],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"id\",\"separateDialCode\",\"number\",\"update\",\"class\"],[[30,2],true,[30,3],[30,0,[\"update\"]],[30,0,[\"phoneInputClass\"]]]]]],[1,\"\\n\"],[41,[51,[28,[37,3],[[30,0,[\"isValid\"]],[30,4]],null]],[[[1,\"    \"],[10,0],[14,\"data-testing-id\",\"phone_input_error_text\"],[14,0,\"text-bp-base-brand text-sm absolute mt-0\"],[12],[1,[30,0,[\"validationErrorText\"]]],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@customClass\",\"@data-testing-id\",\"@phoneNumber\",\"@hideValidationMsg\"],false,[\"if\",\"phone-input\",\"unless\",\"or\"]]",
    "moduleName": "shared/components/inputs/phone-input.hbs",
    "isStrictMode": false
  });
  let PhoneInputComponent = _exports.default = (_class = class PhoneInputComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "isValid", _descriptor2, this);
      _initializerDefineProperty(this, "edited", _descriptor3, this);
      if (this.args.initIsValidFalse) {
        this.isValid = false;
      }
    }
    get validationErrorText() {
      return this.intl.t('llc.inputs.phone_validation');
    }
    get grey() {
      return this.args.grey || false;
    }
    get phoneInputClass() {
      return `
      input-text h-13 border-bp-base-secondary w-full
      ${this.grey ? 'input-phone-subdued' : 'input-phone'}
      ${this.prefilled ? 'bg-bp-base-prefilled' : ''}
    `;
    }
    get prefilled() {
      return this.args.prefilled && !this.edited || false;
    }
    update(number, metaData) {
      if (this.args.phoneNumber !== number) this.edited = true;
      this.args.updatePhoneNumber(number, metaData, this.args.index);
      this.isValid = this.isValid == null ? true : metaData.isValidNumber;
      if (this.args.onKeyup) {
        this.args.onKeyup();
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isValid", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "edited", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PhoneInputComponent);
});