define("shared/llc/name-availability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LLC_FUNNEL_TYPE_NAME = void 0;
  const LLC_FUNNEL_TYPE_NAME = _exports.LLC_FUNNEL_TYPE_NAME = 'name';
  var _default = _exports.default = {
    LLC_FUNNEL_TYPE_NAME
  };
});