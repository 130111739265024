define("shared/llc/status_bar_steps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    INCOMPLETE: -1,
    DELETED: 0,
    SUBMITTED: 1,
    PROCESSED_BY_US: 2,
    PROCESSED_BY_STATE: 3,
    FINAL_REVIEW: 4,
    COMPLETED: 5,
    COMPLETED_BY_PL: 6
  };
});