define("shared/components/form-elements/selection-input-wrapper/selection-input-wrapper", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex flex-col">
    <div class="flex flex-row">
      <div class="text-base font-bold">{{@title}}</div>
      {{#if @tooltipText}}
        <div class="ml-auto mr-1">
          <Order::Tooltip @text={{@tooltipText}}
                          @iconType="icon-info-circle"
                          @iconStyle="text-bp-text-icons-subdued text-sm cursor-pointer"
                          @infoPosition="bottom-left"/>
        </div>
      {{/if}}
    </div>
    <FormElements::SelectionInput @selectAction={{@selectAction}}
                                  @clearText={{@clearText}}
                                  @initialValue={{@initialValue}}
                                  @inputLabel={{@inputLabel}}
                                  @list={{@list}}
                                  @inputDataTestingId={{@inputDataTestingId}}
                                  @wrapper={{true}}
                                  @customClass={{@customClass}}
                                  @disabled={{@disabled}}/>
    <span class="text-bp-text-icons-subdued text-2xs">{{@description}}</span>
  </div>
  
  */
  {
    "id": "6CRZnO+R",
    "block": "[[[10,0],[14,0,\"flex flex-col\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"text-base font-bold\"],[12],[1,[30,1]],[13],[1,\"\\n\"],[41,[30,2],[[[1,\"      \"],[10,0],[14,0,\"ml-auto mr-1\"],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@text\",\"@iconType\",\"@iconStyle\",\"@infoPosition\"],[[30,2],\"icon-info-circle\",\"text-bp-text-icons-subdued text-sm cursor-pointer\",\"bottom-left\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n  \"],[8,[39,2],null,[[\"@selectAction\",\"@clearText\",\"@initialValue\",\"@inputLabel\",\"@list\",\"@inputDataTestingId\",\"@wrapper\",\"@customClass\",\"@disabled\"],[[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],true,[30,9],[30,10]]],null],[1,\"\\n  \"],[10,1],[14,0,\"text-bp-text-icons-subdued text-2xs\"],[12],[1,[30,11]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@title\",\"@tooltipText\",\"@selectAction\",\"@clearText\",\"@initialValue\",\"@inputLabel\",\"@list\",\"@inputDataTestingId\",\"@customClass\",\"@disabled\",\"@description\"],false,[\"if\",\"order/tooltip\",\"form-elements/selection-input\"]]",
    "moduleName": "shared/components/form-elements/selection-input-wrapper/selection-input-wrapper.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});