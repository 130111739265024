define("shared/llc/us-tribes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    'Absentee-Shawnee': 'Absentee-Shawnee Tribe of Indians of Oklahoma',
    'Agdaagux': 'Agdaagux Tribe of King Cove',
    'Agua Caliente': 'Agua Caliente Band of Cahuilla Indians of the Agua Caliente Indian Reservation, California',
    'Ak Chin': 'Ak-Chin Indian Community',
    'Akiachak': 'Akiachak Native Community',
    'Akiak': 'Akiak Native Community',
    'Alabama-Coushatta': 'Alabama-Coushatta Tribe of Texas',
    'Alabama-Quassarte Tribal Town': 'Alabama-Quassarte Tribal Town',
    'Alatna': 'Alatna Village',
    'Algaaciq': 'Algaaciq Native Village (St. Mary\'s)',
    'Allakaket': 'Allakaket Village',
    'Alturas': 'Alturas Indian Rancheria, California',
    'Alutiiq Tribe of Old Harbor': 'Alutiiq Tribe of Old Harbor',
    'Angoon': 'Angoon Community Association',
    'Anvik': 'Anvik Village',
    'Apache': 'Apache Tribe of Oklahoma',
    'Arctic Village': 'Arctic Village (See Native Village of Venetie Tribal Government)',
    'Asa\'carsarmiut': 'Asa\'carsarmiut Tribe',
    'Assiniboine and Sioux': 'Assiniboine & Sioux Tribes of the Fort Peck Indian Reservation, Montana',
    'Augustine': 'Augustine Band of Cahuilla Indians, California',
    'Bad River Band': 'Bad River Band of the Lake Superior Tribe of Chippewa Indians of the Bad River Reservation, Wisconsin',
    'Bay Mills': 'Bay Mills Indian Community, Michigan',
    'Bear River': 'Bear River Band of the Rohnerville Rancheria, California',
    'Beaver': 'Beaver Village',
    'Berry Creek': 'Berry Creek Rancheria of Maidu Indians of California',
    'Big Lagoon': 'Big Lagoon Rancheria, California',
    'Big Pine Reservation': 'Big Pine Paiute Tribe of the Owens Valley',
    'Big Sandy': 'Big Sandy Rancheria of Western Mono Indians of California',
    'Big Valley Rancheria': 'Big Valley Band of Pomo Indians of the Big Valley Rancheria, California',
    'Birch Creek': 'Birch Creek Tribe',
    'Bishop Paiute': 'Bishop Paiute Tribe',
    'Blackfeet': 'Blackfeet Tribe of the Blackfeet Indian Reservation of Montana',
    'Blue Lake': 'Blue Lake Rancheria, California',
    'Bridgeport Indian Colony': 'Bridgeport Indian Colony',
    'Buena Vista Rancheria': 'Buena Vista Rancheria of Me-Wuk Indians of California',
    'Burns Paiute': 'Burns Paiute Tribe',
    'Cabazon': 'Cabazon Band of Mission Indians, California',
    'Cachil DeHe': 'Cachil DeHe Band of Wintun Indians of the Colusa Indian Community of the Colusa Rancheria, California',
    'Caddo': 'Caddo Nation of Oklahoma',
    'Cahto': 'Cahto Tribe of the Laytonville Rancheria',
    'Cahuilla': 'Cahuilla Band of Indians',
    'California Valley': 'California Valley Miwok Tribe, California',
    'Campo': 'Campo Band of Diegueno Mission Indians of the Campo Indian Reservation, California',
    'Capitan Grande': 'Capitan Grande Band of Diegueno Mission Indians of California (Barona Group of Capitan Grande Band of Mission Indians of the Barona Reservation, California)',
    'Capitan Grande Band': 'Capitan Grande Band of Diegueno Mission Indians of California',
    'Viejas': 'Capitan Grande Band of Diegueno Mission Indians of California: Viejas (Baron Long) Group of Capitan Grande Band of Mission Indians of the Viejas Reservation, California',
    'Catawba': 'Catawba Indian Nation (aka Catawba Indian Tribe of South Carolina)',
    'Cayuga Nation of New York': 'Cayuga Nation',
    'Cedarville': 'Cedarville Rancheria, California',
    'Central Council of the Tlingit & Haida': 'Central Council of the Tlingit & Haida Indian Tribes',
    'Chalkyitsik': 'Chalkyitsik Village',
    'Cheesh-Na': 'Cheesh-Na Tribe',
    'Chemehuevi': 'Chemehuevi Indian Tribe of the Chemehuevi Reservation, California',
    'Cher-Ae Heights': 'Cher-Ae Heights Indian Community of the Trinidad Rancheria, California',
    'Cherokee Nation': 'Cherokee Nation',
    'Chevak': 'Chevak Native Village',
    'Cheyenne River': 'Cheyenne and Arapaho Tribes, Oklahoma',
    'Cheyenne River Sioux Tribe': 'Cheyenne River Sioux Tribe of the Cheyenne River Reservation, South Dakota',
    'Chickahominy Indians-Eastern Division': 'Chickahominy Indian Tribe - Eastern Division',
    'Chickahominy Indian Tribe': 'Chickahominy Indian Tribe',
    'Chickaloon': 'Chickaloon Native Village',
    'Chicken Ranch': 'Chicken Ranch Rancheria of Me-Wuk Indians of California',
    'Chignik Bay': 'Chignik Bay Tribal Council',
    'Chignik Lake': 'Chignik Lake Village',
    'Chilkat': 'Chilkat Indian Village (Klukwan)',
    'Chilkoot': 'Chilkoot Indian Association (Haines)',
    'Chinik': 'Chinik Eskimo Community (Golovin)',
    'Chippewa-Cree': 'Chippewa Cree Indians of the Rocky Boy\'s Reservation, Montana',
    'Chitimacha': 'Chitimacha Tribe of Louisiana',
    'Chuloonawick': 'Chuloonawick Native Village',
    'Circle': 'Circle Native Community',
    'Citizen Potawatomi': 'Citizen Potawatomi Nation, Oklahoma',
    'Cloverdale': 'Cloverdale Rancheria of Pomo Indians of California',
    'Cocopah': 'Cocopah Tribe of Arizona',
    'Coeur D\'Alene': 'Coeur D\'Alene Tribe',
    'Cold Springs': 'Cold Springs Rancheria of Mono Indians of California',
    'Colorado River': 'Colorado River Indian Tribes of the Colorado River Indian Reservation, Arizona and California',
    'Comanche': 'Comanche Nation, Oklahoma',
    'Confederated Salish': 'Confederated Salish and Kootenai Tribes of the Flathead Reservation',
    'Confederated Yakama': 'Confederated Tribes and Bands of the Yakama Nation',
    'Confederated Tribes of Siletz Indians of Oregon': 'Confederated Tribes of Siletz Indians of Oregon',
    'Confederated Tribes of the Chehalis Reservation': 'Confederated Tribes of the Chehalis Reservation',
    'Confederated Tribes of the Colville Reservation': 'Confederated Tribes of the Colville Reservation',
    'Confederated Tribes of the Coos': 'Lower Umpqua and Siuslaw Indians, Confederated Tribes of the Coos',
    'Confederated Tribes of the Goshute Reservation': 'Nevada and Utah, Confederated Tribes of the Goshute Reservation',
    'Confederated Tribes of the Grand Ronde Community of Oregon': 'Confederated Tribes of the Grand Ronde Community of Oregon',
    'Confederated Tribes of the Umatilla Indian Reservation': 'Confederated Tribes of the Umatilla Indian Reservation',
    'Confederated Tribes of the Warm Springs Reservation of Oregon': 'Confederated Tribes of the Warm Springs Reservation of Oregon',
    'Coquille': 'Coquille Indian Tribe',
    'Coushatta': 'Coushatta Tribe of Louisiana',
    'Cow Creek': 'Cow Creek Band of Umpqua Tribe of Indians',
    'Cowlitz': 'Cowlitz Indian Tribe',
    'Coyote Valley': 'Coyote Valley Band of Pomo Indians of California',
    'Craig': 'Craig Tribal Association',
    'Crow Creek': 'Crow Creek Sioux Tribe of the Crow Creek Reservation, South Dakota',
    'Crow': 'Crow Tribe of Montana',
    'Curyung': 'Curyung Tribal Council',
    'Delaware Nation': 'Delaware Nation, Oklahoma',
    'Delaware Tribe of Indians': 'Delaware Tribe of Indians',
    'Douglas': 'Douglas Indian Association',
    'Dry Creek': 'Dry Creek Rancheria Band of Pomo Indians, California',
    'Duckwater': 'Duckwater Shoshone Tribe of the Duckwater Reservation, Nevada',
    'Eastern Cherokee': 'Eastern Band of Cherokee Indians',
    'Eastern Shawnee Tribe of Oklahoma': 'Eastern Shawnee Tribe of Oklahoma',
    'Eastern Shoshone': 'Eastern Shoshone Tribe of the Wind River Reservation, Wyoming',
    'Egegik': 'Egegik Village',
    'Eklutna': 'Eklutna Native Village',
    'Elem': 'Elem Indian Colony of Pomo Indians of the Sulphur Bank Rancheria, California',
    'Elk Valley': 'Elk Valley Rancheria, California',
    'Ely Shoshone': 'Ely Shoshone Tribe of Nevada',
    'Emmonak': 'Emmonak Village',
    'Enterprise': 'Enterprise Rancheria of Maidu Indians of California',
    'Evansville': 'Evansville Village (aka Bettles Field)',
    'Ewiiaapaayp': 'Ewiiaapaayp Band of Kumeyaay Indians, California',
    'Federated Indians of Graton': 'Federated Indians of Graton Rancheria, California',
    'Flandreau': 'Flandreau Santee Sioux Tribe of South Dakota',
    'Forest County': 'Forest County Potawatomi Community, Wisconsin',
    'Fort Belknap Indian Community of the Fort Belknap Reservation of Montana': 'Fort Belknap Indian Community of the Fort Belknap Reservation of Montana',
    'Fort Bidwell Indian Community of the Fort Bidwell Reservation of California': 'Fort Bidwell Indian Community of the Fort Bidwell Reservation of California',
    'Fort Independence Indian Community of Paiute Indians of the Fort In': 'Fort Independence Indian Community of Paiute Indians of the Fort In',
    'Lummi': 'Lummi Tribe of the Lummi Reservation',
    'Lytton': 'Lytton Rancheria of California',
    'Makah': 'Makah Indian Tribe of the Makah Indian Reservation',
    'Manchester': 'Manchester Band of Pomo Indians of the Manchester Rancheria, California',
    'Manley Hot Springs': 'Manley Hot Springs Village',
    'Manokotak': 'Manokotak Village',
    'Manzanita': 'Manzanita Band of Diegueno Mission Indians of the Manzanita Reservation, California',
    'Mashantucket Pequot': 'Mashantucket Pequot Indian Tribe',
    'Mashpee': 'Mashpee Wampanoag Tribe',
    'Match-e-be-nash-she-wish Band': 'Match-e-be-nash-she-wish Band of Pottawatomi Indians of Michigan',
    'Mcgrath': 'McGrath Native Village',
    'Mechoopda': 'Mechoopda Indian Tribe of Chico Rancheria, California',
    'Menominee': 'Menominee Indian Tribe of Wisconsin',
    'Mentasta': 'Mentasta Traditional Council',
    'Mesa Grande': 'Mesa Grande Band of Diegueno Mission Indians of the Mesa Grande Reservation, California',
    'Mescalero Apache': 'Mescalero Apache Tribe of the Mescalero Reservation, New Mexico',
    'Metlakatla': 'Metlakatla Indian Community, Annette Island Reserve',
    'Miami Tribe of Oklahoma': 'Miami Tribe of Oklahoma',
    'Miccosukee': 'Miccosukee Tribe of Indians',
    'Middletown': 'Middletown Rancheria of Pomo Indians of California',
    'Mi\'kmaq Nation': 'Mi\'kmaq Nation',
    'Bois Forte': 'Minnesota Chippewa Tribe - Bois Forte Band (Nett Lake)',
    'Fond du Lac': 'Minnesota Chippewa Tribe - Fond du Lac Band',
    'Grand Portage': 'Minnesota Chippewa Tribe - Grand Portage Band',
    'Leech Lake': 'Minnesota Chippewa Tribe - Leech Lake Band',
    'Mille Lacs': 'Minnesota Chippewa Tribe - Mille Lacs Band',
    'White Earth': 'Minnesota Chippewa Tribe - White Earth Band',
    'Minnesota Chippewa': 'Minnesota Chippewa Tribe, Minnesota',
    'Mississippi Choctaw': 'Mississippi Band of Choctaw Indians',
    'Moapa': 'Moapa Band of Paiute Indians of the Moapa River Indian Reservation, Nevada',
    'Modoc Nation': 'Modoc Nation',
    'Mohegan': 'Mohegan Tribe of Indians of Connecticut',
    'Monacan Indian Nation': 'Monacan Indian Nation',
    'Mooretown': 'Mooretown Rancheria of Maidu Indians of California',
    'Morongo': 'Morongo Band of Mission Indians, California',
    'Muckleshoot': 'Muckleshoot Indian Tribe',
    'Naknek': 'Naknek Native Village',
    'Nansemond Indian Tribe': 'Nansemond Indian Nation',
    'Narragansett': 'Narragansett Indian Tribe',
    'Afognak': 'Native Village of Afognak',
    'Akhiok': 'Native Village of Akhiok',
    'Akutan': 'Native Village of Akutan',
    'Aleknagik': 'Native Village of Aleknagik',
    'Ambler': 'Native Village of Ambler',
    'Atka': 'Native Village of Atka',
    'Native Village of Atqasuk': 'Native Village of Atqasuk',
    'Barrow': 'Native Village of Barrow Inupiat Traditional Government',
    'Belkofski': 'Native Village of Belkofski',
    'Brevig Mission': 'Native Village of Brevig Mission',
    'Buckland': 'Native Village of Buckland',
    'Cantwell': 'Native Village of Cantwell',
    'Chenega': 'Native Village of Chenega (aka Chanega)',
    'Chignik Lagoon': 'Native Village of Chignik Lagoon',
    'Chitina': 'Native Village of Chitina',
    'Chuathbaluk': 'Native Village of Chuathbaluk (Russian Mission, Kuskokwim)',
    'Council': 'Native Village of Council',
    'Deering': 'Native Village of Deering',
    'Diomede': 'Native Village of Diomede (aka Inalik)',
    'Eagle': 'Native Village of Eagle',
    'Eek': 'Native Village of Eek',
    'Ekuk': 'Native Village of Ekuk',
    'Ekwok': 'Native Village of Ekwok',
    'Elim IRA': 'Native Village of Elim',
    'Eyak': 'Native Village of Eyak (Cordova)',
    'False Pass': 'Native Village of False Pass',
    'Fort Yukon': 'Native Village of Fort Yukon',
    'Gakona': 'Native Village of Gakona',
    'Gambell': 'Native Village of Gambell',
    'Georgetown': 'Native Village of Georgetown',
    'Goodnews Bay': 'Native Village of Goodnews Bay',
    'Hamilton': 'Native Village of Hamilton',
    'Hooper Bay': 'Native Village of Hooper Bay',
    'Kanatak': 'Native Village of Kanatak',
    'Karluk': 'Native Village of Karluk',
    'Kiana': 'Native Village of Kiana',
    'Kipnuk': 'Native Village of Kipnuk',
    'Kivalina': 'Native Village of Kivalina',
    'Kluti Kaah': 'Native Village of Kluti Kaah (aka Copper Center)',
    'Kobuk': 'Native Village of Kobuk',
    'Kongiganak': 'Native Village of Kongiganak',
    'Kotzebue': 'Native Village of Kotzebue',
    'Koyuk': 'Native Village of Koyuk',
    'Kwigillingok': 'Native Village of Kwigillingok',
    'Kwinhagak': 'Native Village of Kwinhagak (aka Quinhagak)',
    'Larsen Bay': 'Native Village of Larsen Bay',
    'Marshall': 'Native Village of Marshall (aka Fortuna Ledge)',
    'Mary\'s Igloo': 'Native Village of Mary\'s Igloo',
    'Mekoryuk': 'Native Village of Mekoryuk',
    'Minto': 'Native Village of Minto',
    'Nanwalek': 'Native Village of Nanwalek (aka English Bay)',
    'Napaimute': 'Native Village of Napaimute',
    'Napakiak': 'Native Village of Napakiak',
    'Napaskiak': 'Native Village of Napaskiak',
    'Nelson Lagoon': 'Native Village of Nelson Lagoon',
    'Nightmute': 'Native Village of Nightmute',
    'Nikolski': 'Native Village of Nikolski',
    'Noatak': 'Native Village of Noatak',
    'Nuiqsut': 'Native Village of Nuiqsut (aka Nooiksut)',
    'Nunam Iqua': 'Native Village of Nunam Iqua',
    'Nunapitchuk': 'Native Village of Nunapitchuk',
    'Ouzinkie': 'Native Village of Ouzinkie',
    'Paimiut': 'Native Village of Paimiut',
    'Perryville': 'Native Village of Perryville',
    'Pilot Point': 'Native Village of Pilot Point',
    'Point Hope IRA': 'Native Village of Point Hope',
    'Point Lay IRA': 'Native Village of Point Lay',
    'Port Graham': 'Native Village of Port Graham',
    'Port Heiden': 'Native Village of Port Heiden',
    'Port Lions': 'Native Village of Port Lions',
    'Ruby': 'Native Village of Ruby',
    'St. Michael IRA': 'Native Village of Saint Michael',
    'Savoonga': 'Native Village of Savoonga',
    'Scammon Bay': 'Native Village of Scammon Bay',
    'Selawik': 'Native Village of Selawik',
    'Shaktoolik': 'Native Village of Shaktoolik',
    'Shishmaref IRA': 'Native Village of Shishmaref',
    'Shungnak': 'Native Village of Shungnak',
    'Stevens Village': 'Native Village of Stevens',
    'Tanacross': 'Native Village of Tanacross',
    'Tanana': 'Native Village of Tanana',
    'Tatitlek': 'Native Village of Tatitlek',
    'Tazlina': 'Native Village of Tazlina',
    'Teller': 'Native Village of Teller',
    'Tetlin': 'Native Village of Tetlin',
    'Tuntutuliak': 'Native Village of Tuntutuliak',
    'Tununak': 'Native Village of Tununak',
    'Tyonek': 'Native Village of Tyonek',
    'Unalakleet': 'Native Village of Unalakleet',
    'Unga': 'Native Village of Unga',
    'Venetie IRA': 'Native Village of Venetie Tribal Government (Arctic Village and Village of Venetie)',
    'Wales': 'Native Village of Wales',
    'White Mountain AK': 'Native Village of White Mountain',
    'Navajo': 'Navajo Nation, Arizona, New Mexico &amp; Utah',
    'Nenana': 'Nenana Native Association',
    'New Koliganek': 'New Koliganek Village Council',
    'New Stuyahok': 'New Stuyahok Village',
    'Newhalen': 'Newhalen Village',
    'Newtok': 'Newtok Village',
    'Nez Perce': 'Nez Perce Tribe',
    'Nikolai': 'Nikolai Village',
    'Ninilchik': 'Ninilchik Village',
    'Nisqually': 'Nisqually Indian Tribe',
    'Nome': 'Nome Eskimo Community',
    'Nondalton': 'Nondalton Village',
    'Nooksack': 'Nooksack Indian Tribe',
    'Noorvik': 'Noorvik Native Community',
    'Northern Arapaho': 'Northern Arapaho Tribe of the Wind River Reservation, Wyoming',
    'Northern Cheyenne': 'Northern Cheyenne Tribe of the Northern Cheyenne Indian Reservation, Montana',
    'North Fork': 'Northfork Rancheria of Mono Indians of California',
    'Northway': 'Northway Village',
    'Northwestern Shoshone': 'Northwestern Band of the Shoshone Nation',
    'Nottawaseppi Potawatomi': 'Nottawaseppi Huron Band of the Potawatomi, Michigan',
    'Nulato': 'Nulato Village',
    'Nunakauyarmiut': 'Nunakauyarmiut Tribe',
    'Oglala Sioux': 'Oglala Sioux Tribe',
    'Ohkay Owingeh': 'Ohkay Owingeh, New Mexico',
    'Omaha': 'Omaha Tribe of Nebraska',
    'Oneida': 'Oneida Indian Nation',
    'Oneida Nation (Wisconsin)': 'Oneida Nation',
    'Onondaga': 'Onondaga Nation',
    'Grayling': 'Organized Village of Grayling (aka Holikachuk)',
    'Kake': 'Organized Village of Kake',
    'Kasaan': 'Organized Village of Kasaan',
    'Kwethluk': 'Organized Village of Kwethluk',
    'Saxman': 'Organized Village of Saxman',
    'Orutsararmiut': 'Orutsararmiut Traditional Native Council',
    'Oscarville': 'Oscarville Traditional Village',
    'Otoe-Missouria': 'Otoe-Missouria Tribe of Indians, Oklahoma',
    'Ottawa Tribe of Oklahoma': 'Ottawa Tribe of Oklahoma',
    'Paiute of Utah': 'Paiute Indian Tribe of Utah (Cedar Band of Paiutes, Kanosh Band of Paiutes, Koosharem Band of Paiutes, Indian Peaks Band of Paiutes, and Shivwits Band of Paiutes)',
    'Paiute-Shoshone': 'Paiute-Shoshone Tribe of the Fallon Reservation and Colony, Nevada',
    'Pala': 'Pala Band of Mission Indians',
    'Pamunkey Indian Tribe': 'Pamunkey Indian Tribe',
    'Pascua Yaqui': 'Pascua Yaqui Tribe of Arizona',
    'Paskenta': 'Paskenta Band of Nomlaki Indians of California',
    'Passamaquoddy Indian Township': 'Passamaquoddy Tribe - Indian Township',
    'Passamaquoddy Pleasant Point': 'Passamaquoddy Tribe - Pleasant Point',
    'Passamaquoddy Tribe': 'Passamaquoddy Tribe',
    'Pauloff Harbor': 'Pauloff Harbor Village',
    'Pauma': 'Pauma Band of Luiseno Mission Indians of the Pauma & Yuima Reservation, California',
    'Pawnee': 'Pawnee Nation of Oklahoma',
    'Pechanga Band of Indians': 'Pechanga Band of Indians',
    'Pedro Bay': 'Pedro Bay Village',
    'Penobscot': 'Penobscot Nation',
    'Peoria Tribe of Indians of Oklahoma': 'Peoria Tribe of Indians of Oklahoma',
    'Petersburg': 'Petersburg Indian Association',
    'Picayune': 'Picayune Rancheria of Chukchansi Indians of California',
    'Pilot Station': 'Pilot Station Traditional Village',
    'Pinoleville': 'Pinoleville Pomo Nation, California',
    'Pit River': 'Pit River Tribe, California',
    'Pitka\'s Point': 'Pitka\'s Point Traditional Council',
    'Platinum': 'Platinum Traditional Village',
    'Poarch': 'Poarch Band of Creek Indians',
    'Pokagon': 'Pokagon Band of Potawatomi Indians, Michigan and Indiana',
    'Ponca of Oklahoma': 'Ponca Tribe of Indians of Oklahoma',
    'Ponca of Nebraska': 'Ponca Tribe of Nebraska',
    'Port Gamble': 'Port Gamble S\'Klallam Tribe',
    'Portage Creek': 'Portage Creek Village (aka Ohgsenakale)',
    'Potter Valley': 'Potter Valley Tribe, California',
    'Prairie Band': 'Prairie Band Potawatomi Nation',
    'Prairie Island': 'Prairie Island Indian Community in the State of Minnesota',
    'Pribilof Islands Aleut Communities': 'Pribilof Islands Aleut Communities',
    'Pueblo of Acoma': 'Pueblo of Acoma, New Mexico',
    'Pueblo of Cochiti': 'Pueblo of Cochiti, New Mexico',
    'Pueblo of Isleta': 'Pueblo of Isleta, New Mexico',
    'Pueblo of Jemez': 'Pueblo of Jemez, New Mexico',
    'Pueblo of Laguna': 'Pueblo of Laguna, New Mexico',
    'Pueblo of Nambe': 'Pueblo of Nambe, New Mexico',
    'Pueblo of Picuris': 'Pueblo of Picuris, New Mexico',
    'Pueblo of Pojoaque': 'Pueblo of Pojoaque, New Mexico',
    'Pueblo of San Felipe': 'Pueblo of San Felipe, New Mexico',
    'Pueblo of San Ildefonso': 'Pueblo of San Ildefonso, New Mexico',
    'Pueblo of Sandia': 'Pueblo of Sandia, New Mexico',
    'Pueblo of Santa Ana': 'Pueblo of Santa Ana, New Mexico',
    'Pueblo of Santa Clara': 'Pueblo of Santa Clara, New Mexico',
    'Pueblo of Taos': 'Pueblo of Taos, New Mexico',
    'Pueblo of Tesuque': 'Pueblo of Tesuque, New Mexico',
    'Pueblo of Zia': 'Pueblo of Zia, New Mexico',
    'Puyallup': 'Puyallup Tribe of the Puyallup Reservation',
    'Pyramid Lake': 'Pyramid Lake Paiute Tribe of the Pyramid Lake Reservation, Nevada',
    'Qagan Tayagungin': 'Qagan Tayagungin Tribe of Sand Point',
    'Qawalangin': 'Qawalangin Tribe of Unalaska',
    'Quapaw Nation': 'Quapaw Nation',
    'Quartz Valley': 'Quartz Valley Indian Community of the Quartz Valley Reservation of California',
    'Quechan': 'Quechan Tribe of the Fort Yuma Indian Reservation, California & Arizona',
    'Quileute': 'Quileute Tribe of the Quileute Reservation',
    'Quinault': 'Quinault Indian Nation',
    'Ramah Navajo Chapter': 'Ramah Navajo Chapter of the Navajo Nation',
    'Ramona': 'Ramona Band of Cahuilla, California',
    'Rampart': 'Rampart Village',
    'Rappahannock Tribe': 'Inc., Rappahannock Tribe, Inc.',
    'Red Cliff': 'Red Cliff Band of Lake Superior Chippewa Indians of Wisconsin',
    'Red Lake': 'Red Lake Band of Chippewa Indians, Minnesota',
    'Redding': 'Redding Rancheria, California',
    'Redwood Valley': 'Redwood Valley or Little River Band of Pomo Indians of the Redwood Valley Rancheria California',
    'Reno-Sparks': 'Reno-Sparks Indian Colony, Nevada',
    'Resighini': 'Resighini Rancheria, California',
    'Rincon': 'Rincon Band of Luiseno Mission Indians of the Rincon Reservation, California',
    'Robinson': 'Robinson Rancheria',
    'Rosebud': 'Rosebud Sioux Tribe of the Rosebud Indian Reservation, South Dakota',
    'Round Valley': 'Round Valley Indian Tribes, Round Valley Reservation, California',
    'Sac & Fox Nation of Missouri in Kansas and Nebraska': 'Sac & Fox Nation of Missouri in Kansas and Nebraska',
    'Sac & Fox Nation': 'Oklahoma, Sac & Fox Nation, Oklahoma',
    'Sac & Fox of Mississippi': 'Sac & Fox Tribe of the Mississippi in Iowa',
    'Saginaw Chippewa': 'Saginaw Chippewa Indian Tribe of Michigan',
    'St. George': 'Saint George Island (See Pribilof Islands Aleut Communities of St. Paul & St. George Islands)',
    'Saint Paul': 'Saint Paul Island (See Pribilof Islands Aleut Communities of St. Paul & St. George Islands)',
    'Saint Regis': 'Saint Regis Mohawk Tribe',
    'Salamatof': 'Salamatof Tribe',
    'Salt River': 'Salt River Pima-Maricopa Indian Community of the Salt River Reservation, Arizona',
    'Samish': 'Samish Indian Nation',
    'San Carlos': 'San Carlos Apache Tribe of the San Carlos Reservation, Arizona',
    'San Juan': 'San Juan Southern Paiute Tribe of Arizona',
    'San Pasqual': 'San Pasqual Band of Diegueno Mission Indians of California',
    'Santa Rosa of Cahuilla': 'Santa Rosa Band of Cahuilla Indians, California',
    'Santa Rosa': 'Santa Rosa Indian Community of the Santa Rosa Rancheria, California',
    'Santa Ynez': 'Santa Ynez Band of Chumash Mission Indians of the Santa Ynez Reservation, California',
    'Santee Sioux': 'Santee Sioux Nation, Nebraska',
    'Santo Domingo': 'Santo Domingo Pueblo',
    'Sauk-Suiattle': 'Sauk-Suiattle Indian Tribe',
    'Sault Ste. Marie': 'Sault Ste. Marie Tribe of Chippewa Indians, Michigan',
    'Scotts Valley': 'Scotts Valley Band of Pomo Indians of California',
    'Seldovia': 'Seldovia Village Tribe',
    'Seminole': 'Seminole Tribe of Florida',
    'Seneca': 'Seneca Nation of Indians',
    'Seneca-Cayuga Nation': 'Seneca-Cayuga Nation',
    'Shageluk': 'Shageluk Native Village',
    'Shakopee': 'Shakopee Mdewakanton Sioux Community of Minnesota',
    'Shawnee Tribe': 'Shawnee Tribe',
    'Sherwood Valley': 'Sherwood Valley Rancheria of Pomo Indians of California',
    'Shingle Springs': 'Shingle Springs Band of Miwok Indians, Shingle Springs Rancheria (Verona Tract), California',
    'Shinnecock': 'Shinnecock Indian Nation',
    'Shoalwater': 'Shoalwater Bay Indian Tribe of the Shoalwater Bay Indian Reservation',
    'Shoshone-Bannock': 'Shoshone-Bannock Tribes of the Fort Hall Reservation',
    'Shoshone-Paiute': 'Shoshone-Paiute Tribes of the Duck Valley Reservation, Nevada',
    'Sisseton-Wahpeton': 'Sisseton-Wahpeton Oyate of the Lake Traverse Reservation, South Dakota',
    'Sitka': 'Sitka Tribe of Alaska',
    'Skagway': 'Skagway Village',
    'Skokomish': 'Skokomish Indian Tribe',
    'Skull Valley': 'Skull Valley Band of Goshute Indians of Utah',
    'Snoqualmie': 'Snoqualmie Indian Tribe',
    'Soboba': 'Soboba Band of Luiseno Indians, California',
    'Sokaogon': 'Sokaogon Chippewa Community, Wisconsin',
    'South Naknek': 'South Naknek Village',
    'Southern Ute': 'Southern Ute Indian Tribe of the Southern Ute Reservation, Colorado',
    'Spirit Lake': 'Spirit Lake Tribe, North Dakota',
    'Spokane': 'Spokane Tribe of the Spokane Reservation',
    'Squaxin': 'Squaxin Island Tribe of the Squaxin Island Reservation',
    'St. Croix': 'St. Croix Chippewa Indians of Wisconsin',
    'Standing Rock': 'Standing Rock Sioux Tribe of North & South Dakota',
    'Stebbins': 'Stebbins Community Association',
    'Stillaguamish': 'Stillaguamish Tribe of Indians of Washington',
    'Stockbridge': 'Stockbridge Munsee Community, Wisconsin',
    'Summit Lake': 'Summit Lake Paiute Tribe of Nevada',
    'Sun\'aq': 'Sun\'aq Tribe of Kodiak',
    'Suquamish': 'Suquamish Indian Tribe of the Port Madison Reservation',
    'Susanville': 'Susanville Indian Rancheria, California',
    'Swinomish': 'Swinomish Indian Tribal Community',
    'Sycuan': 'Sycuan Band of the Kumeyaay Nation',
    'Table Mountain': 'Table Mountain Rancheria',
    'Takotna': 'Takotna Village',
    'Tangirnaq': 'Tangirnaq Native Village',
    'Tejon': 'Tejon Indian Tribe',
    'Telida': 'Telida Village',
    'Te-Moak': 'Te-Moak Tribe of Western Shoshone Indians of Nevada (Four constituent bands: Battle Mountain Band; Elko Band; South Fork Band and Wells Band)',
    'Chickasaw Nation': 'The Chickasaw Nation',
    'The Choctaw Nation of Oklahoma': 'The Choctaw Nation of Oklahoma',
    'The Muscogee (Creek) Nation': 'The Muscogee (Creek) Nation',
    'The Osage Nation': 'The Osage Nation',
    'The Seminole Nation of Oklahoma': 'The Seminole Nation of Oklahoma',
    'Thlopthlocco Tribal Town': 'Thlopthlocco Tribal Town',
    'Three Affiliated': 'Three Affiliated Tribes of the Fort Berthold Reservation, North Dakota',
    'Timbi-sha Shoshone': 'Timbisha Shoshone Tribe',
    'Tohono O\'odham': 'Tohono O\'odham Nation of Arizona',
    'Tolowa Dee-ni\'': 'Tolowa Dee-ni\' Nation',
    'Tonawanda': 'Tonawanda Band of Seneca',
    'Tonkawa': 'Tonkawa Tribe of Indians of Oklahoma',
    'Tonto Apache': 'Tonto Apache Tribe of Arizona',
    'Torres Martinez': 'Torres Martinez Desert Cahuilla Indians, California',
    'Togiak': 'Traditional Village of Togiak',
    'Tulalip': 'Tulalip Tribes of Washington',
    'Tule River': 'Tule River Indian Tribe of the Tule River Reservation, California',
    'Tuluksak': 'Tuluksak Native Community',
    'Tunica-Biloxi': 'Tunica-Biloxi Indian Tribe',
    'Tuolumne': 'Tuolumne Band of Me-Wuk Indians of the Tuolumne Rancheria of California',
    'Turtle Mountain': 'Turtle Mountain Band of Chippewa Indians of North Dakota',
    'Tuscarora': 'Tuscarora Nation',
    'Twenty-Nine Palms': 'Twenty-Nine Palms Band of Mission Indians of California',
    'Twin Hills': 'Twin Hills Village',
    'Ugashik': 'Ugashik Village',
    'Umkumiut': 'Umkumiut Native Village',
    'United Auburn': 'United Auburn Indian Community of the Auburn Rancheria of California',
    'United Keetoowah Band of Cherokee Indians in Oklahoma': 'United Keetoowah Band of Cherokee Indians in Oklahoma',
    'Upper Mattaponi Tribe': 'Upper Mattaponi Tribe',
    'Upper Sioux': 'Upper Sioux Community, Minnesota',
    'Upper Skagit': 'Upper Skagit Indian Tribe',
    'Ute': 'Ute Indian Tribe of the Uintah & Ouray Reservation, Utah',
    'Ute Mountain Ute': 'Ute Mountain Ute Tribe',
    'Benton': 'Utu Utu Gwaitu Paiute Tribe of the Benton Paiute Reservation, California',
    'Alakanuk': 'Village of Alakanuk',
    'Anaktuvuk Pass': 'Village of Anaktuvuk Pass',
    'Aniak': 'Village of Aniak',
    'Atmautluak': 'Village of Atmautluak',
    'Bill Moore\'s Slough': 'Village of Bill Moore\'s Slough',
    'Chefornak': 'Village of Chefornak',
    'Clark\'s Point': 'Village of Clarks Point',
    'Crooked Creek': 'Village of Crooked Creek',
    'Dot Lake': 'Village of Dot Lake',
    'Iliamna': 'Village of Iliamna',
    'Kalskag': 'Village of Kalskag',
    'Kaltag': 'Village of Kaltag',
    'Kotlik': 'Village of Kotlik',
    'Lower Kalskag': 'Village of Lower Kalskag',
    'Ohogamiut': 'Village of Ohogamiut',
    'Red Devil': 'Village of Red Devil',
    'Sleetmute': 'Village of Sleetmute',
    'Solomon': 'Village of Solomon',
    'Stony River': 'Village of Stony River',
    'Venetie': 'Village of Venetie (See Native Village of Venetie Tribal Government)',
    'Wainwright': 'Village of Wainwright',
    'Walker River': 'Walker River Paiute Tribe of the Walker River Reservation, Nevada',
    'Wampanoag': 'Wampanoag Tribe of Gay Head (Aquinnah)',
    'Washoe': 'Washoe Tribe of Nevada & California (Carson Colony, Dresslerville Colony, Woodfords Community, Stewart Community & Washoe Ranches)',
    'White Mountain': 'White Mountain Apache Tribe of the Fort Apache Reservation, Arizona',
    'Wichita': 'Wichita and Affiliated Tribes (Wichita, Keechi, Waco & Tawakonie), Oklahoma',
    'Wilton': 'Wilton Rancheria, California',
    'Winnebago': 'Winnebago Tribe of Nebraska',
    'Winnemucca': 'Winnemucca Indian Colony of Nevada',
    'Wiyot': 'Wiyot Tribe, California',
    'Wrangell': 'Wrangell Cooperative Association',
    'Wyandotte Nation': 'Wyandotte Nation',
    'Yakutat': 'Yakutat Tlingit Tribe',
    'Yankton': 'Yankton Sioux Tribe of South Dakota',
    'Yavapai-Apache': 'Yavapai-Apache Nation of the Camp Verde Indian Reservation, Arizona',
    'Yavapai-Prescott': 'Yavapai-Prescott Indian Tribe',
    'Yerington': 'Yerington Paiute Tribe of the Yerington Colony and Campbell Ranch, Nevada',
    'Yocha Dehe': 'Yocha Dehe Wintun Nation, California',
    'Yomba Shoshone': 'Yomba Shoshone Tribe of the Yomba Reservation, Nevada',
    'Ysleta Del Sur': 'Ysleta del Sur Pueblo',
    'Yuhaaviatam of San Manuel Nation': 'Yuhaaviatam of San Manuel Nation',
    'Andreafski': 'Yupiit of Andreafski',
    'Yurok': 'Yurok Tribe of the Yurok Reservation, California',
    'Pueblo of Zuni': 'Zuni Tribe of the Zuni Reservation, New Mexico',
    'Other': 'Other'
  };
});