define("shared/llc/tax-business-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    LLC: 'LLC',
    SOLE_PROP: 'None/sole proprietorship',
    CORP: 'C-corp',
    SCORP: 'S-corp',
    PARTNERSHIP: 'Partnership'
  };
});