define("shared/components/form-elements/radio-extended-input/radio-extended-input", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{@wrapperClass}} data-testing-id={{@dataTesting}}>
    <RadioButton @classNames="radio-extended-input-container-global {{@radioCustomClass}}" @checkedClass="active" @value={{@radioVal}} @groupValue={{@radioGroupVal}} @changed={{@onChange}}>
      <div class="flex flex-col md:flex-row">
        <div class="flex flex-col gap-1 flex-1">
          <div class="flex flex-row items-center">
            {{#if @showRadioCircle}}
              <div class="input-circle"></div>
            {{/if}}
            <div class="text-input-container flex-shrink {{@titleCustomClass}}">{{@title}}</div>
          </div>
          <div class="text-input-container text-2xs">{{@description}}</div>
        </div>
        {{#if @showSeparator}}
          <div class="hidden md:flex border-r-2 border-gray-300 pr-8"></div>
          <div class="flex md:hidden border-b-2 border-gray-300 my-4"></div>
        {{/if}}
        {{#if @price}}
          <div class="flex flex-row md:flex-col text-right ml-auto gap-1 pl-4">
            <div class="text-input-container font-bold">{{@price}}</div>
            {{#if @priceDescription}}
              <div class="text-input-container text-bp-text-icons-subdued">{{@priceDescription}}</div>
            {{/if}}
          </div>
        {{/if}}
      </div>
    </RadioButton>
  </div>
  
  */
  {
    "id": "KjCT1Ql4",
    "block": "[[[10,0],[15,0,[30,1]],[15,\"data-testing-id\",[30,2]],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@classNames\",\"@checkedClass\",\"@value\",\"@groupValue\",\"@changed\"],[[29,[\"radio-extended-input-container-global \",[30,3]]],\"active\",[30,4],[30,5],[30,6]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"flex flex-col md:flex-row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"flex flex-col gap-1 flex-1\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"flex flex-row items-center\"],[12],[1,\"\\n\"],[41,[30,7],[[[1,\"            \"],[10,0],[14,0,\"input-circle\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"          \"],[10,0],[15,0,[29,[\"text-input-container flex-shrink \",[30,8]]]],[12],[1,[30,9]],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"text-input-container text-2xs\"],[12],[1,[30,10]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,11],[[[1,\"        \"],[10,0],[14,0,\"hidden md:flex border-r-2 border-gray-300 pr-8\"],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"flex md:hidden border-b-2 border-gray-300 my-4\"],[12],[13],[1,\"\\n\"]],[]],null],[41,[30,12],[[[1,\"        \"],[10,0],[14,0,\"flex flex-row md:flex-col text-right ml-auto gap-1 pl-4\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"text-input-container font-bold\"],[12],[1,[30,12]],[13],[1,\"\\n\"],[41,[30,13],[[[1,\"            \"],[10,0],[14,0,\"text-input-container text-bp-text-icons-subdued\"],[12],[1,[30,13]],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@wrapperClass\",\"@dataTesting\",\"@radioCustomClass\",\"@radioVal\",\"@radioGroupVal\",\"@onChange\",\"@showRadioCircle\",\"@titleCustomClass\",\"@title\",\"@description\",\"@showSeparator\",\"@price\",\"@priceDescription\"],false,[\"radio-button\",\"if\"]]",
    "moduleName": "shared/components/form-elements/radio-extended-input/radio-extended-input.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});