define("shared/print-store/minimal-apparel-color-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const colors = {
    list: {
      white: {
        color: '#ffffff',
        mask: '#ffffff'
      },
      ash: {
        color: '#d3d3d0',
        mask: '#ABA7A7E5'
      },
      black: {
        color: '#282828',
        mask: '#050505'
      },
      yellow: {
        color: '#f7d053',
        mask: '#F1B327FF'
      }
    },
    colorValues: ['#ffffff', '#d3d3d0', '#282828', '#F1B327FF'],
    colorNames: ['white', 'ash', 'black', 'yellow']
  };
  var _default = _exports.default = colors;
});