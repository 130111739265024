define("shared/llc/exception-reasons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SSN_VALIDATION_ERROR = void 0;
  const SSN_VALIDATION_ERROR = _exports.SSN_VALIDATION_ERROR = 'ssn_validation_error';
  var _default = _exports.default = {
    SSN_VALIDATION_ERROR
  };
});