define("ember-basic-dropdown/utils/has-moved", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = hasMoved;
  function hasMoved(endEvent, moveEvent) {
    if (!moveEvent) {
      return false;
    }
    if (!endEvent.changedTouches?.[0] || moveEvent.changedTouches[0].touchType !== 'stylus') {
      return true;
    }

    // Distinguish stylus scroll and tap: if touch "distance" < 5px, we consider it a tap
    let horizontalDistance = Math.abs(moveEvent.changedTouches[0].pageX - endEvent.changedTouches[0].pageX);
    let verticalDistance = Math.abs(moveEvent.changedTouches[0].pageY - endEvent.changedTouches[0].pageY);
    return horizontalDistance >= 5 || verticalDistance >= 5;
  }
});