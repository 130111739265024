define("shared/print-store/local-storage-keys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.apparelDetailsKey = apparelDetailsKey;
  _exports.bundledPrintItemId = bundledPrintItemId;
  _exports.personalDetailsKey = personalDetailsKey;
  _exports.selectedApparelItemId = selectedApparelItemId;
  _exports.selectedDesignKey = selectedDesignKey;
  _exports.selectedPrintItemId = selectedPrintItemId;
  _exports.selectedPrintItemPaperType = selectedPrintItemPaperType;
  _exports.selectedPrintItemQuantity = selectedPrintItemQuantity;
  _exports.selectedStickerItemId = selectedStickerItemId;
  _exports.selectedStickerItemQuantity = selectedStickerItemQuantity;
  _exports.shippingDetailsKey = shippingDetailsKey;
  const BASE = 'PRINT_STORE_';
  const SHIPPING_DETAILS_BASE = `${BASE}SHIPPING_DETAILS_`;
  const SELECTED_DESIGN_BASE = `${BASE}SELECTED_DESIGN_`;
  const PERSONAL_DETAILS_BASE = `${BASE}PERSONAL_DETAILS_`;
  const SELECTED_PRINT_ITEM_ID_BASE = `${BASE}SELECTED_PRINT_ITEM_ID_`;
  const SELECTED_PRINT_ITEM_PAPER_TYPE_BASE = `${BASE}SELECTED_PRINT_ITEM_PAPER_TYPE_`;
  const SELECTED_PRINT_ITEM_QUANTITY_BASE = `${BASE}SELECTED_PRINT_ITEM_QUANTITY_`;
  const APPAREL_DETAILS_BASE = `${BASE}APPAREL_DETAILS_`;
  const SELECTED_APPAREL_ITEM_ID_BASE = `${BASE}SELECTED_APPAREL_ITEM_ID_`;
  const SELECTED_STICKER_ITEM_ID_BASE = `${BASE}SELECTED_STICKER_ITEM_ID_`;
  const SELECTED_STICKER_ITEM_QUANTITY_BASE = `${BASE}SELECTED_STICKER_ITEM_QUANTITY_`;
  const BUNDLED_PRINT_ITEM_ID = `${BASE}BUNDLED_PRINT_ITEM_ID`;
  function shippingDetailsKey(brandVersionId) {
    return `${SHIPPING_DETAILS_BASE}${brandVersionId}`;
  }
  function selectedDesignKey(brandVersionId) {
    return `${SELECTED_DESIGN_BASE}${brandVersionId}`;
  }
  function personalDetailsKey(brandVersionId) {
    return `${PERSONAL_DETAILS_BASE}${brandVersionId}`;
  }
  function apparelDetailsKey(brandVersionId) {
    return `${APPAREL_DETAILS_BASE}${brandVersionId}`;
  }
  function selectedPrintItemId(brandVersionId) {
    return `${SELECTED_PRINT_ITEM_ID_BASE}${brandVersionId}`;
  }
  function bundledPrintItemId() {
    return BUNDLED_PRINT_ITEM_ID;
  }
  function selectedStickerItemId(brandVersionId) {
    return `${SELECTED_STICKER_ITEM_ID_BASE}${brandVersionId}`;
  }
  function selectedStickerItemQuantity(brandVersionId) {
    return `${SELECTED_STICKER_ITEM_QUANTITY_BASE}${brandVersionId}`;
  }
  function selectedApparelItemId(brandVersionId) {
    return `${SELECTED_APPAREL_ITEM_ID_BASE}${brandVersionId}`;
  }
  function selectedPrintItemQuantity(brandVersionId) {
    return `${SELECTED_PRINT_ITEM_QUANTITY_BASE}${brandVersionId}`;
  }
  function selectedPrintItemPaperType(brandVersionId) {
    return `${SELECTED_PRINT_ITEM_PAPER_TYPE_BASE}${brandVersionId}`;
  }
});