define("ember-composable-helpers/helpers/entries", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.keys = keys;
  function keys(_ref) {
    let [object] = _ref;
    if (!object) {
      return object;
    }
    return Object.entries(object);
  }
  var _default = _exports.default = (0, _helper.helper)(keys);
});