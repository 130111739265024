define("shared/print-store/apparel-color-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    white: {
      color: '#ffffff',
      mask: '#ffffff'
    },
    ash: {
      color: '#d3d3d0',
      mask: '#ABA7A7E5'
    },
    grey: {
      color: '#afb0b5',
      mask: '#8b8787'
    },
    black: {
      color: '#282828',
      mask: '#050505'
    },
    blue: {
      color: '#1e4f9e',
      mask: '#0a176d'
    },
    yellow: {
      color: '#f7d053',
      mask: '#F1B327FF'
    },
    green: {
      color: '#27a061',
      mask: '#195c24'
    },
    red: {
      color: '#d92630',
      mask: '#9b0b0b'
    }
  };
});