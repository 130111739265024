define("ember-link-action/initializers/allow-link-action", ["exports", "@ember/routing/link-component", "ember-link-action/mixins/link-action"], function (_exports, _linkComponent, _linkAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    _linkComponent.default.reopen(_linkAction.default);
  }
  var _default = _exports.default = {
    name: 'allow-link-action',
    initialize
  };
});