define("shared/llc/upsell-products", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TRADEMARK = _exports.REGISTERED_AGENT = _exports.PERMITS = _exports.OPERATING_AGREEMENT = _exports.FORMATION_KIT = _exports.EXPEDITED_FILING = _exports.EIN = _exports.BUSINESS_DOCUMENTS = _exports.ANNUAL_COMPLIANCE = _exports.ALG_PERMIT = void 0;
  const REGISTERED_AGENT = _exports.REGISTERED_AGENT = 'registered_agent';
  const ANNUAL_COMPLIANCE = _exports.ANNUAL_COMPLIANCE = 'annual_compliance';
  const OPERATING_AGREEMENT = _exports.OPERATING_AGREEMENT = 'operating_agreement';
  const EXPEDITED_FILING = _exports.EXPEDITED_FILING = 'expedited_filing';
  const EIN = _exports.EIN = 'ein';
  const TRADEMARK = _exports.TRADEMARK = 'trademark';
  const PERMITS = _exports.PERMITS = 'permits';
  const BUSINESS_DOCUMENTS = _exports.BUSINESS_DOCUMENTS = 'business_documents';
  const FORMATION_KIT = _exports.FORMATION_KIT = 'formation_kit';
  const ALG_PERMIT = _exports.ALG_PERMIT = 'alg_permit';
  var _default = _exports.default = {
    REGISTERED_AGENT,
    ANNUAL_COMPLIANCE,
    OPERATING_AGREEMENT,
    EXPEDITED_FILING,
    EIN,
    TRADEMARK,
    PERMITS,
    FORMATION_KIT
  };
});