define("shared/components/print-store/sticker-preview", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cp-sticker-preview items-center justify-center">
    <div class="flex flex-col">
      <svg viewBox="0 0 387 397" height="100%" width="100%">
        <image class="w-full" xlink:href="/images/components/stickers/preview/stickers_bg.svg"/>
        <image class="w-full" xlink:href="/images/components/stickers/preview/stickers_top.svg"/>
        <foreignObject width="242" height="155" x="70" y="120">
          <Global::LogoImage @brandVersion={{@brandVersion}} @embedSvg={{true}}/>
        </foreignObject>
      </svg>
    </div>
  </div>
  
  */
  {
    "id": "m8SZCgDo",
    "block": "[[[10,0],[14,0,\"cp-sticker-preview items-center justify-center\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-col\"],[12],[1,\"\\n    \"],[10,\"svg\"],[14,\"viewBox\",\"0 0 387 397\"],[14,\"height\",\"100%\"],[14,\"width\",\"100%\"],[12],[1,\"\\n      \"],[10,\"image\"],[14,0,\"w-full\"],[14,\"xlink:href\",\"/images/components/stickers/preview/stickers_bg.svg\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n      \"],[10,\"image\"],[14,0,\"w-full\"],[14,\"xlink:href\",\"/images/components/stickers/preview/stickers_top.svg\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n      \"],[10,\"foreignObject\"],[14,\"width\",\"242\"],[14,\"height\",\"155\"],[14,\"x\",\"70\"],[14,\"y\",\"120\"],[12],[1,\"\\n        \"],[8,[39,0],null,[[\"@brandVersion\",\"@embedSvg\"],[[30,1],true]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@brandVersion\"],false,[\"global/logo-image\"]]",
    "moduleName": "shared/components/print-store/sticker-preview.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});