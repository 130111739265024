define("shared/llc/tax_types_LA", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TAX_TYPES = Object.freeze({
    CORPORATION: 'corporation',
    PARTNERSHIP: 'partnership',
    SOLE_PROPRIETORSHIP: 'sole_proprietorship'
  });
  var _default = _exports.default = TAX_TYPES;
});