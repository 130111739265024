define("shared/llc/llc-engagement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PHYSICAL_ADDRESS_ANSWERS = _exports.EMPLOYEE_STATUS_ANSWERS = _exports.DEDICATED_PERSON_ANSWERS = _exports.BUSINESS_STATUS_ANSWERS = _exports.BUSINESS_LOCATION_ANSWERS = _exports.BUSINESS_DEFINITION_ANSWERS = _exports.BANK_ACCOUNT_ANSWERS = void 0;
  const BUSINESS_STATUS_ANSWERS = _exports.BUSINESS_STATUS_ANSWERS = Object.freeze({
    'idea': {
      slug: 'llc_business_status_page_planning_for_future',
      customEvent: 'Early idea'
    },
    'ready_to_launch': {
      slug: 'llc_business_status_page_about_to_launch',
      customEvent: 'Getting ready to launch'
    },
    'in_business': {
      slug: 'llc_business_status_page_making_money',
      customEvent: 'Already in business'
    }
  });
  const BUSINESS_DEFINITION_ANSWERS = _exports.BUSINESS_DEFINITION_ANSWERS = Object.freeze({
    'main_income': {
      slug: 'llc_business_definition_main_income',
      customEvent: 'Main Income'
    },
    'side_hustle': {
      slug: 'llc_business_definition_side_hustle',
      customEvent: 'Side Hustle'
    },
    'not_sure': {
      slug: 'llc_business_definition_not_sure',
      customEvent: 'Not Sure'
    }
  });
  const BUSINESS_LOCATION_ANSWERS = _exports.BUSINESS_LOCATION_ANSWERS = Object.freeze({
    'online': {
      slug: 'llc_business_location_online',
      customEvent: 'Online'
    },
    'offline': {
      slug: 'llc_business_location_offline',
      customEvent: 'Offline'
    },
    'both': {
      slug: 'llc_business_location_both',
      customEvent: 'Both'
    }
  });
  const BANK_ACCOUNT_ANSWERS = _exports.BANK_ACCOUNT_ANSWERS = Object.freeze({
    'yes': {
      slug: 'llc_business_bank_account_yes',
      customEvent: 'Yes'
    },
    'no': {
      slug: 'llc_business_bank_account_no',
      customEvent: 'No'
    },
    'not_sure': {
      slug: 'llc_business_bank_account_not_sure',
      customEvent: 'Not Sure'
    }
  });
  const PHYSICAL_ADDRESS_ANSWERS = _exports.PHYSICAL_ADDRESS_ANSWERS = Object.freeze({
    'yes': {
      slug: 'llc_business_physical_address_yes',
      customEvent: 'Yes'
    },
    'no': {
      slug: 'llc_business_physical_address_no',
      customEvent: 'No'
    },
    'not_sure': {
      slug: 'llc_business_physical_address_not_sure',
      customEvent: 'Not Sure'
    }
  });
  const DEDICATED_PERSON_ANSWERS = _exports.DEDICATED_PERSON_ANSWERS = Object.freeze({
    'yes': {
      slug: 'llc_business_physical_address_dedicated_person_yes',
      customEvent: 'Yes'
    },
    'no': {
      slug: 'llc_business_physical_address_dedicated_person_no',
      customEvent: 'No'
    },
    'not_sure': {
      slug: 'llc_business_physical_address_dedicated_person_not_sure',
      customEvent: 'Not Sure'
    }
  });
  const EMPLOYEE_STATUS_ANSWERS = _exports.EMPLOYEE_STATUS_ANSWERS = Object.freeze({
    'no': {
      slug: 'llc_employees_page_no_employees',
      customEvent: 'No'
    },
    '1_to_9': {
      slug: 'llc_employees_page_has_employees',
      customEvent: '1-9'
    },
    '10_plus': {
      slug: 'llc_business_status_page_employees_not_sure',
      customEvent: '10+'
    }
  });
  var _default = _exports.default = {
    BUSINESS_STATUS_ANSWERS,
    BUSINESS_DEFINITION_ANSWERS,
    BUSINESS_LOCATION_ANSWERS,
    BANK_ACCOUNT_ANSWERS,
    PHYSICAL_ADDRESS_ANSWERS,
    DEDICATED_PERSON_ANSWERS,
    EMPLOYEE_STATUS_ANSWERS
  };
});