define("ember-engines/-private/engine-ext", ["@ember/application", "@ember/engine", "ember-engines/components/link-to-external"], function (_application, _engine, _linkToExternal) {
  "use strict";

  _engine.default.reopen({
    buildRegistry() {
      let registry = this._super(...arguments);
      if (!(this instanceof _application.default)) {
        registry.register('component:link-to-external', _linkToExternal.default);
      }
      return registry;
    }
  });
});