define("shared/components/buttons/base", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/utils", "@ember/template-factory"], function (_exports, _component, _component2, _object, _utils, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button type="button"
          disabled={{@disabled}}
          {{on "click" this.handleClick}}
          data-testing-id={{@dataTestingId}}
          class="tailor-base-btn tailor-{{@buttonName}}-btn
          flex-center h-max {{if @fullWidth 'w-full' 'w-max'}}
          {{@customClasses}}
          size-{{@size}}
            {{if @disabled 'disabled'}}
            {{@type}}
            {{if @active 'active'}}
            {{if @loader 'pointer-events-none'}}
            {{if (and (not @loader) @underline) 'link-underline'}}
            {{if @bold 'font-bold'}}
            {{unless @text 'icon-only'}}">
    {{#if @loader}}
      <Global::BouncingLoader @color={{@loaderColor}}/>
    {{else}}
      {{#if @iconLeft}}
        <i class="{{@iconLeft}} mr-2"/>
      {{/if}}
      {{@text}}
      {{#if @iconRight}}
        <i class="{{@iconRight}} ml-2"/>
      {{/if}}
    {{/if}}
  </button>
  
  */
  {
    "id": "ptG58axx",
    "block": "[[[11,\"button\"],[16,\"disabled\",[30,1]],[16,\"data-testing-id\",[30,2]],[16,0,[29,[\"tailor-base-btn tailor-\",[30,3],\"-btn\\n        flex-center h-max \",[52,[30,4],\"w-full\",\"w-max\"],\"\\n        \",[30,5],\"\\n        size-\",[30,6],\"\\n          \",[52,[30,1],\"disabled\"],\"\\n          \",[30,7],\"\\n          \",[52,[30,8],\"active\"],\"\\n          \",[52,[30,9],\"pointer-events-none\"],\"\\n          \",[52,[28,[37,1],[[28,[37,2],[[30,9]],null],[30,10]],null],\"link-underline\"],\"\\n          \",[52,[30,11],\"font-bold\"],\"\\n          \",[52,[51,[30,12]],\"icon-only\"]]]],[24,4,\"button\"],[4,[38,4],[\"click\",[30,0,[\"handleClick\"]]],null],[12],[1,\"\\n\"],[41,[30,9],[[[1,\"    \"],[8,[39,5],null,[[\"@color\"],[[30,13]]],null],[1,\"\\n\"]],[]],[[[41,[30,14],[[[1,\"      \"],[10,\"i\"],[15,0,[29,[[30,14],\" mr-2\"]]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[1,[30,12]],[1,\"\\n\"],[41,[30,15],[[[1,\"      \"],[10,\"i\"],[15,0,[29,[[30,15],\" ml-2\"]]],[12],[13],[1,\"\\n\"]],[]],null]],[]]],[13],[1,\"\\n\"]],[\"@disabled\",\"@dataTestingId\",\"@buttonName\",\"@fullWidth\",\"@customClasses\",\"@size\",\"@type\",\"@active\",\"@loader\",\"@underline\",\"@bold\",\"@text\",\"@loaderColor\",\"@iconLeft\",\"@iconRight\"],false,[\"if\",\"and\",\"not\",\"unless\",\"on\",\"global/bouncing-loader\"]]",
    "moduleName": "shared/components/buttons/base.hbs",
    "isStrictMode": false
  });
  let BaseButtonComponent = _exports.default = (_class = class BaseButtonComponent extends _component2.default {
    handleClick() {
      if ((0, _utils.isPresent)(this.args.action)) {
        this.args.action();
      } else if ((0, _utils.isPresent)(this.args.task)) {
        if ((0, _utils.isPresent)(this.args.taskArgs)) {
          this.args.task.perform(...this.args.taskArgs);
        } else if ((0, _utils.isPresent)(this.args.taskArg)) {
          this.args.task.perform(this.args.taskArg);
        } else {
          this.args.task.perform();
        }
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "handleClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleClick"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BaseButtonComponent);
});