define("shared/components/holiday/banner", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "@ember/utils", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _utils, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cp-holiday-banner w-full">
    {{#if this.timer.currentHoliday}}
      <Holiday::Promotion @discount={{this.discountValue}}/>
    {{else if (or this.discountValue (gt this.isSpecialDiscountCouponPeriod 0))}}
      <div class="flex items-center justify-center holiday-banner text-white text-center w-full p-4 bg-bp-primary-hovered">
        {{#if (gt this.isSpecialDiscountCouponPeriod 0)}}
          <span class="font-bold mr-xs">{{t 'holiday_banner.text_special_discount' period=this.isSpecialDiscountCouponPeriod}}</span>
        {{else}}
          <span class="font-bold mr-xs">{{t 'holiday_banner.text_mobile' discount=this.discountValue}}</span>
        {{/if}}
      </div>
    {{/if}}
  </div>
  
  */
  {
    "id": "soSa9IDw",
    "block": "[[[10,0],[14,0,\"cp-holiday-banner w-full\"],[12],[1,\"\\n\"],[41,[30,0,[\"timer\",\"currentHoliday\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@discount\"],[[30,0,[\"discountValue\"]]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[30,0,[\"discountValue\"]],[28,[37,3],[[30,0,[\"isSpecialDiscountCouponPeriod\"]],0],null]],null],[[[1,\"    \"],[10,0],[14,0,\"flex items-center justify-center holiday-banner text-white text-center w-full p-4 bg-bp-primary-hovered\"],[12],[1,\"\\n\"],[41,[28,[37,3],[[30,0,[\"isSpecialDiscountCouponPeriod\"]],0],null],[[[1,\"        \"],[10,1],[14,0,\"font-bold mr-xs\"],[12],[1,[28,[35,4],[\"holiday_banner.text_special_discount\"],[[\"period\"],[[30,0,[\"isSpecialDiscountCouponPeriod\"]]]]]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[14,0,\"font-bold mr-xs\"],[12],[1,[28,[35,4],[\"holiday_banner.text_mobile\"],[[\"discount\"],[[30,0,[\"discountValue\"]]]]]],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[]],null]],[]]],[13],[1,\"\\n\"]],[],false,[\"if\",\"holiday/promotion\",\"or\",\"gt\",\"t\"]]",
    "moduleName": "shared/components/holiday/banner.hbs",
    "isStrictMode": false
  });
  let HolidayBanner = _exports.default = (_dec = (0, _object.computed)('localStorage', 'timer', 'uiElements.holidayTimer'), _dec2 = (0, _object.computed)('timer.{ACTIVE_COUPON,currentHoliday.discount}', 'uiElements.holidayTimer'), (_class = class HolidayBanner extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "localStorage", _descriptor, this);
      _initializerDefineProperty(this, "uiElements", _descriptor2, this);
      _initializerDefineProperty(this, "timer", _descriptor3, this);
      _initializerDefineProperty(this, "couponApplied", _descriptor4, this);
      if (!this.couponApplied) {
        this.applyCoupon();
      }
    }
    get isSpecialDiscountCouponPeriod() {
      const marketingCoupon = this.localStorage.getValueFromDataObject('tailorStudioCoupon');
      if ((0, _utils.isBlank)(marketingCoupon)) {
        return 0;
      }
      if (['o-', 'ow-', 'own-', 'ownr-'].some(pref => marketingCoupon.startsWith(pref))) {
        return 6;
      } else if (['ow3-', 'rbc-'].some(pref => marketingCoupon.startsWith(pref))) {
        return 3;
      } else if (marketingCoupon === 'ltf2020') {
        return 1;
      } else {
        return 0;
      }
    }
    get discountValue() {
      const marketingCoupon = this.timer.ACTIVE_COUPON[this.localStorage.getValueFromDataObject('tailorStudioCoupon')];
      return marketingCoupon || this.timer.currentHoliday?.discount;
    }
    applyCoupon() {
      if (this.timer.ACTIVE_COUPON[this.localStorage.getValueFromDataObject('tailorStudioCoupon')]) {
        this.couponApplied = true;
        return;
      }
      const defaultCoupon = this.timer.currentHoliday?.coupon;
      if (defaultCoupon) {
        let props = {
          'value': defaultCoupon,
          'expiration': dayjs().add(5, 'days')
        };
        this.localStorage.setItem('tailorStudioCoupon', JSON.stringify(props));
        this.couponApplied = true;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "localStorage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiElements", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "timer", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "couponApplied", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "isSpecialDiscountCouponPeriod", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isSpecialDiscountCouponPeriod"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "discountValue", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "discountValue"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, HolidayBanner);
});