define("shared/llc/arf-check-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LLC = _exports.DOMESTIC_AND_FOREIGN = _exports.DOMESTIC = _exports.CORPORATION = void 0;
  const DOMESTIC = _exports.DOMESTIC = 'domestic';
  const DOMESTIC_AND_FOREIGN = _exports.DOMESTIC_AND_FOREIGN = 'domestic_and_foreign';
  const LLC = _exports.LLC = 'llc';
  const CORPORATION = _exports.CORPORATION = 'corporation';
  var _default = _exports.default = {
    DOMESTIC,
    DOMESTIC_AND_FOREIGN,
    LLC,
    CORPORATION
  };
});