define("ember-retry/retry", ["exports", "@ember/utils", "@ember/runloop", "rsvp"], function (_exports, _utils, _runloop, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  let retry = function (fncToRetry, delayArg, conditionFnc) {
    let r = {
      retryIt: function () {
        let maxRetries = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 5;
        let retries = arguments.length > 1 ? arguments[1] : undefined;
        if (retries >= maxRetries) {
          return r.asPromise(r.fncToRetry);
        } else {
          return r.retryLater(maxRetries, retries);
        }
      },
      fncToRetry: null,
      conditionFnc: null,
      delayFnc: null,
      delay: 500,
      exponentialDelayFnc: function (retries) {
        return Math.pow(2, retries) * r.delay;
      },
      retryLater: function (maxRetries, retries) {
        return r.asPromise(r.fncToRetry).catch(error => {
          if (r.conditionFnc(error)) {
            // If conditionFunc allows the retry then retry, otherwise reject
            // e.g. In case of a 401 and the person doesn't want to retry
            return r.delayedRetry(maxRetries, retries);
          } else {
            throw error;
          }
        });
      },
      // Function that retries in case of failure
      delayedRetry: function (maxRetries, retries) {
        return new _rsvp.Promise(resolve => {
          (0, _runloop.later)(() => resolve(r.retryIt(maxRetries, retries + 1)), r.delayFnc(retries));
        });
      },
      isPromise: function (obj) {
        return (0, _utils.isPresent)(obj) && (0, _utils.typeOf)(obj.then) === 'function';
      },
      asPromise: function (fnc) {
        return new _rsvp.Promise((resolve, reject) => {
          try {
            let returnVal = fnc(resolve, reject);
            if (r.isPromise(returnVal)) {
              // handle promise returned
              returnVal.then(resolve, reject);
            } else if (!(0, _utils.isNone)(returnVal)) {
              // handle scalar value returned
              resolve(returnVal);
            }
            // handle no value returned, will be handle by resolve, reject being called in fnc
          } catch (error) {
            reject(error);
          }
        });
      },
      setDelayArg: function (delayArg) {
        r.delayFnc = r.exponentialDelayFnc;
        if ((0, _utils.typeOf)(delayArg) === 'number') {
          r.delay = delayArg;
        } else if ((0, _utils.typeOf)(delayArg) === 'function') {
          r.delayFnc = delayArg;
        }
      }
    };
    r.fncToRetry = fncToRetry;
    r.conditionFnc = conditionFnc;
    r.setDelayArg(delayArg);
    return r;
  };
  function _default(fnc) {
    let maxRetries = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 5;
    let delayArg = arguments.length > 2 ? arguments[2] : undefined;
    let conditionFnc = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : () => true;
    if (fnc === null || fnc === undefined || (0, _utils.typeOf)(fnc) !== 'function') {
      return (0, _rsvp.reject)('Function required');
    } else {
      return retry(fnc, delayArg, conditionFnc).retryIt(maxRetries, 0);
    }
  }
});