define("shared/components/select-cards/medium", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
      <div class="cp-select-cards-medium flex flex-row bg-bp-secondary-main p-4 border hover:border-black transition duration-200 rounded-md shadow-bp-md hover:shadow-bp-lg items-center cursor-pointer w-full md:w-72 h-30 {{if @selected 'border-2 border-bp-primary-main hover:border-bp-primary-main' 'border-transparent'}}" data-testing-id={{@dataTestingId}} role="button" {{on 'click' @clickAction}}>
      <img src={{@imageSrc}} alt={{@imageAlt}} class="h-24 w-24">
      <div class="flex flex-row items-center justify-between w-full">
        <div class="flex items-center justify-start pr-2">
          <div class="flex flex-col pr-2 md:pr-0">
            <span class="text-bp-text-icons font-bold mx-1 ml-0">{{@title}}</span>
            <span class="md:hidden leading-5">
              <span class="text-bp-text-icons-subdued text-2xs w-full">{{@description}}</span>
            </span>
          </div>
          <div class="hidden md:block">
            <Global::Tooltip @dataTestingIdOnClick="{{@dataTestingId}}-tooltip" @content={{@description}}
                             @icon="icon-info-circle"
                             @iconStyle="text-sm text-bp-primary-main"
                             @iconFontSize="text-sm" />
          </div>
        </div>
        <i class="icon-check-circle text-sm text-bp-primary-main transition duration-200 {{if @selected 'opacity-1' 'opacity-0'}}"></i>
      </div>
    </div>
  
  */
  {
    "id": "fdf6JjiZ",
    "block": "[[[1,\"  \"],[11,0],[16,0,[29,[\"cp-select-cards-medium flex flex-row bg-bp-secondary-main p-4 border hover:border-black transition duration-200 rounded-md shadow-bp-md hover:shadow-bp-lg items-center cursor-pointer w-full md:w-72 h-30 \",[52,[30,1],\"border-2 border-bp-primary-main hover:border-bp-primary-main\",\"border-transparent\"]]]],[16,\"data-testing-id\",[30,2]],[24,\"role\",\"button\"],[4,[38,1],[\"click\",[30,3]],null],[12],[1,\"\\n    \"],[10,\"img\"],[15,\"src\",[30,4]],[15,\"alt\",[30,5]],[14,0,\"h-24 w-24\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-row items-center justify-between w-full\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"flex items-center justify-start pr-2\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"flex flex-col pr-2 md:pr-0\"],[12],[1,\"\\n          \"],[10,1],[14,0,\"text-bp-text-icons font-bold mx-1 ml-0\"],[12],[1,[30,6]],[13],[1,\"\\n          \"],[10,1],[14,0,\"md:hidden leading-5\"],[12],[1,\"\\n            \"],[10,1],[14,0,\"text-bp-text-icons-subdued text-2xs w-full\"],[12],[1,[30,7]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"hidden md:block\"],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@dataTestingIdOnClick\",\"@content\",\"@icon\",\"@iconStyle\",\"@iconFontSize\"],[[29,[[30,2],\"-tooltip\"]],[30,7],\"icon-info-circle\",\"text-sm text-bp-primary-main\",\"text-sm\"]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"i\"],[15,0,[29,[\"icon-check-circle text-sm text-bp-primary-main transition duration-200 \",[52,[30,1],\"opacity-1\",\"opacity-0\"]]]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@selected\",\"@dataTestingId\",\"@clickAction\",\"@imageSrc\",\"@imageAlt\",\"@title\",\"@description\"],false,[\"if\",\"on\",\"global/tooltip\"]]",
    "moduleName": "shared/components/select-cards/medium.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});