define("shared/components/link-to/base", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo @disabled={{@disabled}}
          @route={{@route}}
          @model={{@model}}
          @query={{@query}}
          data-testing-id={{@dataTestingId}}
          class="tailor-base-btn tailor-{{@buttonName}}-btn
          flex-center h-max {{if @fullWidth 'w-full' 'w-max'}}
          size-{{@size}}
            {{if @disabled 'disabled'}}
            {{@type}}
            {{if @active 'active'}}
            {{if @loader 'pointer-events-none'}}
            {{if (and (not @loader) @underline) 'link-underline'}}
            {{unless @text 'icon-only'}}">
    {{#if @loader}}
      <Global::BouncingLoader @color={{@loaderColor}}/>
    {{else}}
      {{#if @iconLeft}}
        <i class="{{@iconLeft}} mr-2"/>
      {{/if}}
      {{@text}}
      {{#if @iconRight}}
        <i class="{{@iconRight}} ml-2"/>
      {{/if}}
    {{/if}}
  </LinkTo>
  
  
  */
  {
    "id": "Ev3CRKB2",
    "block": "[[[8,[39,0],[[16,\"data-testing-id\",[30,1]],[16,0,[29,[\"tailor-base-btn tailor-\",[30,2],\"-btn\\n        flex-center h-max \",[52,[30,3],\"w-full\",\"w-max\"],\"\\n        size-\",[30,4],\"\\n          \",[52,[30,5],\"disabled\"],\"\\n          \",[30,6],\"\\n          \",[52,[30,7],\"active\"],\"\\n          \",[52,[30,8],\"pointer-events-none\"],\"\\n          \",[52,[28,[37,2],[[28,[37,3],[[30,8]],null],[30,9]],null],\"link-underline\"],\"\\n          \",[52,[51,[30,10]],\"icon-only\"]]]]],[[\"@disabled\",\"@route\",\"@model\",\"@query\"],[[30,5],[30,11],[30,12],[30,13]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,8],[[[1,\"    \"],[8,[39,5],null,[[\"@color\"],[[30,14]]],null],[1,\"\\n\"]],[]],[[[41,[30,15],[[[1,\"      \"],[10,\"i\"],[15,0,[29,[[30,15],\" mr-2\"]]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[1,[30,10]],[1,\"\\n\"],[41,[30,16],[[[1,\"      \"],[10,\"i\"],[15,0,[29,[[30,16],\" ml-2\"]]],[12],[13],[1,\"\\n\"]],[]],null]],[]]]],[]]]]],[1,\"\\n\\n\"]],[\"@dataTestingId\",\"@buttonName\",\"@fullWidth\",\"@size\",\"@disabled\",\"@type\",\"@active\",\"@loader\",\"@underline\",\"@text\",\"@route\",\"@model\",\"@query\",\"@loaderColor\",\"@iconLeft\",\"@iconRight\"],false,[\"link-to\",\"if\",\"and\",\"not\",\"unless\",\"global/bouncing-loader\"]]",
    "moduleName": "shared/components/link-to/base.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});