define("shared/llc/display-statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DISPLAY_STATUS_INCOMPLETE = _exports.DISPLAY_STATUS_DELETED = void 0;
  const DISPLAY_STATUS_INCOMPLETE = _exports.DISPLAY_STATUS_INCOMPLETE = 'incomplete';
  const DISPLAY_STATUS_DELETED = _exports.DISPLAY_STATUS_DELETED = 'deleted';
  var _default = _exports.default = {
    DISPLAY_STATUS_INCOMPLETE,
    DISPLAY_STATUS_DELETED
  };
});